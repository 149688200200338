import { RcFile } from 'antd/es/upload'
import { AxiosError } from 'axios'

import { showError } from 'components/modals/ErrorModal'
import { apiV1 } from 'network/http'
import { PaymentsReport, PaymentsReportItem } from 'types/reports'
import { api } from '../../index'


export const getYandexProPaymentsReportsList = (): Promise<PaymentsReportItem[]> =>
  api.get('v1/yandexpro/payments/reports', { showErrorMessage: true })

export const getYandexProPaymentsReport = (id: string): Promise<PaymentsReport> =>
  api.get(`v1/yandexpro/payments/reports/${id}`, { showErrorMessage: true })

export const uploadAndProcessYandexProPaymentsFile = async (file: RcFile): Promise<PaymentsReport> => {
  const formData = new FormData()

  formData.append('file', file)

  try {
    // TODO: KZ: refactor apiV1 usages to api
    const res = await apiV1.post(
      'v1/yandexpro/payments/reports', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
        },
      }
    )

    return res.data
  } catch (error) {
    showError((error as AxiosError)?.response?.data)
    throw error
  }
}

export const processYandexProPaymentsReport = (id: string): Promise<PaymentsReport> =>
  api.post(`v1/yandexpro/payments/reports/${id}/actions/process`, { showErrorMessage: true })
