import { Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getBillFileUrl } from 'AC/billing/getFileUrl'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types'

import { DownloadModal } from './DownloadModal'
import styles from './styles.module.sass'


type Props = {
  state: APITYPES.BillState | undefined
  onPaid: () => void
  onCancel: () => void
  onNegotiation: () => void
  onPaying: () => void
  isDoer?: boolean
}

const selector = (state: State) => ({ bill: state.billingReducer.bill })

export const Controls = ({ state, onCancel, onNegotiation, onPaid, onPaying, isDoer }: Props) => {
  const [addModal, setAddModal] = useState(false)
  const dispatch = useDispatch()
  const { bill } = useSelector(selector)

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }

  const downloadFiles = () => {
    if (!bill) {
      return
    }

    dispatch(getBillFileUrl(bill.id))
  }

  const onClickDownload = () => {
    downloadFiles()
    toggleAddModal()
  }

  const isDraft = state === APITYPES.BillState.draft
  const isDeclined = state === APITYPES.BillState.declined

  return (
    <div className={styles.controlsBtn}>
      {
        (isDraft) && (
          <Button
            danger
            size='small'
            type='primary'
            className={styles.btn}
            onClick={onCancel}
          >
            Отменить счет
          </Button>
        )
      }
      {
        (isDoer && isDraft) && (
          <Button
            size='small'
            type='primary'
            className={styles.btn}
            onClick={onPaying}
          >
            В оплату
          </Button>
        )
      }
      {
        (!isDoer && (isDraft || isDeclined)) && (
          <Button
            size='small'
            type='primary'
            className={styles.btn}
            onClick={onNegotiation}
          >
            На Согласование
          </Button>
        )
      }

      {
        (state === 'accepted' || state === 'paid' || state === 'paying') && (
          <>
            {
              state !== 'paying' && state !== 'paid' && (
                <Button
                  size='small'
                  type='primary'
                  className={styles.btn}
                  onClick={onPaying}
                >
                  В оплату
                </Button>
              )
            }
            {
              state !== 'paid' && (
                <Button
                  size='small'
                  type='primary'
                  className={styles.btn}
                  onClick={onPaid}
                >
                  Оплачен
                </Button>
              )
            }
            <Button
              type='primary'
              size='small'
              className={styles.btn}
              onClick={onClickDownload}
            >
              Скачать документы
            </Button>
          </>
        )
      }
      <DownloadModal
        isOpen={addModal}
        onClose={toggleAddModal}
      />
    </div>
  )
}