import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { showInfo } from '../../../../components/modals/InfoModal'
import { ROUTES } from '../../../../config/routes'
import { getCityName } from 'utils/enumUtils'


export const getColumns = (
  roles?: APITYPES.Role[],
  functions?: APITYPES.DoerTariffFunction[],
) => {
  return [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Заказчик',
      dataIndex: 'employer',
      key: 'employer',
      align: 'center',
      render: (employer: APITYPES.Employer) => employer?.name || '-',
    },
    {
      title: 'Город',
      dataIndex: 'city_id',
      key: 'city_id',
      render: (city_id: number) => getCityName(city_id),
    },
    {
      title: 'Роль',
      dataIndex: 'role_id',
      key: 'role_id',
      render: (role_id: string) =>
        roles ? roles.find((r) => r.id === role_id)?.name : '-',
    },
    {
      title: 'Метрика',
      dataIndex: 'function_name',
      key: 'function_name',
      render: (fnName: string) =>
        functions ? functions.find((r) => r.name === fnName)?.title : '-',
    },
    {
      title: 'Действует с',
      dataIndex: 'begin',
      key: 'Действует с',
      render: (begin: string) => moment(begin).format('DD.MM.YYYY'),
    },
    {
      title: 'Действует по',
      dataIndex: 'end',
      key: 'Действует по',
      render: (end?: string) => end ? moment(end).format('DD.MM.YYYY') : end,
    },
    {
      title: 'Группа',
      dataIndex: 'group_id',
      key: 'group_id',
    },

  ]
}

export const getRows = (tariffs: APITYPES.DoerTariffNoTasks[] | [], groups: APITYPES.DoerTariffGroups[]) => {
  if (!tariffs.length) { return}

  return tariffs.map((tariff) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'group_id') {
        const group = groups.find((g) => g.id === tariff.group_id)

        row[column.dataIndex] = group?.name

        return
      }
      //@ts-ignore
      row[column.dataIndex] = tariff[column.dataIndex]
    })
    row.key = tariff.id

    return row
  })
}

// eslint-disable-next-line max-len
const text = 'Новый тип смены необходимо добавить в существующие тарифы биллинга исполнителей. Вы будете перенаправлены на страницу тарифов.'

export const showInfoAndNavigateToTariff = (city_id: number, role_id: string) => {
  showInfo(text, () => {
    const today = moment().format('YYYY-MM-DD')

    window.location.href = ROUTES.DOERS.TARIFFS.PARAMS.createPath({
      role_id,
      city_id,
      begin: today,
      end: today,
      function_type: 'shift_type',
    })
  })
}
