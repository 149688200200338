import React from 'react'
import { Tag } from 'antd'

import { APITYPES } from 'types/apitypes'
import { BillStateTranslation } from './Filters/utils'
import { billTypesMap } from '../../../../utils/enumUtils'


export const getColumns = () => (
  [
    {
      title: 'Тип',
      dataIndex: 'bill_type',
      key: 'bill_type',
      fixed: 'left',
      width: '15%',
      render: (value: string) => billTypesMap[value],
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '30%',
    },
    {
      title: 'Контрагент',
      dataIndex: 'legal_name',
      key: 'legal_name',
      width: 220,
    },
    {
      title: 'Период',
      dataIndex: 'period',
      key: 'period',
      align: 'center',
      width: 220,
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      width: 170,
      align: 'center',
      render: (state: APITYPES.Bill['state']) => (<Tag>{ BillStateTranslation[state.name] }</Tag>),
    },
    {
      title: 'Всего',
      dataIndex: 'total_value',
      key: 'total_value',
      align: 'center',
      width: 120,
    },
  ])

export const getRows = (bills: APITYPES.Bill[]) => {
  return bills.map((bill) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state') {
        return
      }
      //@ts-ignore
      row[column.dataIndex] = bill[column.dataIndex]

      return
    })

    row.state = bill.state

    row.key = bill.id

    return row
  })
}