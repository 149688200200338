import { Result } from 'antd'
import moment from 'moment'
import React, { FC } from 'react'

import { dateTimeFormat } from 'utils/dateUtils'


type Props = {
  processedAt?: string
}

export const FinalStepView: FC<Props> = ({ processedAt }) => {
  return (
    <Result
      status='success'
      title='Все платежи успешно обработаны'
      subTitle={`Дата и время обработки: ${moment(processedAt).format(dateTimeFormat)}`}
    />
  )
}
