import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import classNames from 'classnames'
import { useLocation } from 'react-router'

import { ROUTES } from 'config/routes'
import { useBoolean } from 'utils/hooks/useBoolean'

import { getOpenNavBlock, getZeroWidthTriggerStyle } from './utils'
import cls from './styles.module.sass'
import { ItemSettings } from './ItemSettings'


const { Sider } = Layout

export const NavBlock = () => {
  const history = useHistory()
  const {
    value: collapsed,
    toggle,
  } = useBoolean(false)

  const location = useLocation()
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys)
  }

  const setKeys = () => {
    const selectedKeys = getOpenNavBlock(location.pathname, openKeys)

    setSelected(selectedKeys.selectedKeys)
    setOpenKeys(selectedKeys.openKeys)
  }

  const onItemClick = (key: string) => {
    history.push(key)
  }

  const onCollapseSide = (value: boolean) => {
    if (!value) {
      setKeys()
    }
    toggle()
  }

  const goToSettings = () => {
    history.push(ROUTES.DOERS.SHIFTS.SETTINGS.path)
  }

  useEffect(() => {
    setKeys()
  }, [location.pathname])

  return (
    <div>
      <div className={classNames(cls.stick, { [cls.stickExpanded]: collapsed })} />
      <Sider
        collapsible
        collapsed={collapsed}
        className={cls.navBlock}
        width={200}
        collapsedWidth={0}
        zeroWidthTriggerStyle={getZeroWidthTriggerStyle(collapsed)}
        onCollapse={onCollapseSide}
      >
        <Menu
          mode='inline'
          className={cls.menu}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={selected}
          onClick={({ key }) => onItemClick(key)}
          items={
            [
              {
                label: 'Главная',
                key: ROUTES.ROOT.path,
              },
              {
                label: 'Партнеры',
                key: 'partners_group',
                children: [
                  {
                    label: 'Реестр',
                    key: ROUTES.PARTNERS.path,
                  },
                  {
                    label: 'Мойки',
                    key: ROUTES.CARWASHES.path,
                  },
                  {
                    label: 'Помещения',
                    key: ROUTES.PREMISES.path,
                  },
                  {
                    label: 'Рассчет',
                    key: ROUTES.PARTNERS.RENT.path,
                  },
                ],
              },
              {
                label: 'Маркетинг',
                key: 'products_group',
                children: [
                  {
                    label: 'Продукты',
                    key: ROUTES.PRODUCTS.path,
                  },
                  {
                    label: 'Группы',
                    key: ROUTES.PRODUCTS.GROUPS.path,
                  },
                  {
                    label: 'Тарифы',
                    key: ROUTES.PRODUCTS.TARIFFS.path,
                  },
                ],
              },
              {
                label: 'Клиенты B2C',
                key: ROUTES.USERS.path,
              },
              {
                label: 'Исполнители',
                key: 'doers_group',
                children: [
                  {
                    className: cls.menuItemWithSettings,
                    key: ROUTES.DOERS.SHIFTS.path,
                    icon: <ItemSettings
                      title='Смены'
                      onSettingsClick={goToSettings}
                      url={location.pathname}
                      settingsKey={ROUTES.DOERS.SHIFTS.SETTINGS.path}
                    />,
                  },
                  {
                    label: 'Стажировки',
                    key: ROUTES.DOERS.INTERNSHIPS.path,
                  },
                  {
                    label: 'Потребность',
                    key: ROUTES.DOERS.VACANCIES.path,
                  },
                  {
                    label: 'Резерв',
                    key: ROUTES.DOERS.OFFERS.path,
                  },
                  {
                    label: 'Исполнители',
                    key: ROUTES.DOERS.DOERS.path,
                  },
                  {
                    label: 'Операции',
                    key: ROUTES.DOERS.OPERATIONS.path,
                  },
                  {
                    label: 'Мониторинг',
                    key: ROUTES.DOERS.MONITORING.path,
                  },
                  {
                    label: 'Роли',
                    key: ROUTES.DOERS.ROLES.path,
                  },
                  {
                    label: 'Локации',
                    key: ROUTES.DOERS.LOCATIONS.path,
                  },
                  {
                    label: 'Тарифы', //TODO SET_DOER_TARIFFS_FILTER in component lifecycle
                    key: ROUTES.DOERS.TARIFFS.path,
                  },
                  {
                    label: 'Рассылки',
                    key: ROUTES.DOERS.MAILING.path,
                  },
                  {
                    label: 'Штрафы и Бонусы',
                    key: ROUTES.DOERS.MOTIVATIONS.path,
                  },
                  {
                    label: 'Яндекс.ПРО',
                    key: ROUTES.YANDEXPRO.REPORTS.path,
                  },
                  {
                    label: 'Яндекс Лавка',
                    key: ROUTES.YANDEX_LAVKA.REPORTS.path,
                  },
                  {
                    label: 'Urent',
                    key: ROUTES.URENT.REPORTS.path,
                  },
                  {
                    label: 'Magnit',
                    key: ROUTES.MAGNIT.REPORTS.path,
                  },
                ],
              },
              {
                label: 'Биллинг',
                key: 'billing_group',
                children: [
                  {
                    label: 'Счета Партнеры',
                    key: ROUTES.BILLING.BILLS.path,
                  },
                  {
                    label: 'Счета Исполнители',
                    key: ROUTES.BILLING.DOERS.path,
                  },
                  {
                    label: 'Расчеты Исполнители',
                    key: ROUTES.BILLING.DOER_RECORDS.path,
                  },
                  {
                    label: 'Контрагенты',
                    key: ROUTES.BILLING.CONTRACTORS.path,
                  },
                ],
              },
              {
                label: 'Флиты',
                key: 'fleets_group',
                children: [
                  {
                    label: 'Автомобили',
                    key: ROUTES.FLEETS.CARS.path,
                  },
                  {
                    label: 'Тарифы',
                    key: ROUTES.FLEETS.SERVICES.path,
                  },
                ],
              },
              {
                label: 'Справочник',
                key: 'directory_group',
                children: [
                  {
                    label: 'Типы клиентов',
                    key: ROUTES.DIRECTORY.CLIENT_TYPES.path,
                  },
                  {
                    label: 'Классы автомобилей',
                    key: ROUTES.DIRECTORY.CAR_CLASSES.path,
                  },
                  {
                    label: 'Автомобили',
                    key: ROUTES.DIRECTORY.CARS.path,
                  },
                  {
                    label: 'Услуги',
                    key: ROUTES.DIRECTORY.SERVICES.path,
                  },
                ],
              },
            ]
          }
        />
      </Sider>
    </div>
  )
}
