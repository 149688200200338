import { APITYPES } from 'types/apitypes'
import { getFormattedDateWithParseZone, dateTimeFormatWithTimezone } from 'utils/dateUtils'
import { getCityName } from '../../../../../../utils/enumUtils'

import { getColumns } from './getColumns'


const motivationTypes = {
  penalty: 'Штраф',
  compensation: 'Компенсация',
}

export const getRows =
  (motivations: APITYPES.DoerMotivation[]) => {
    const data = motivations.map((motivation) => {
      const row: any = {}

      getColumns().forEach((column) => {
        if (column.dataIndex === 'comment') {
          row[column.dataIndex] = motivation.state.comment
        } else if (column.dataIndex === 'time'){
          row[column.dataIndex] = getFormattedDateWithParseZone(motivation.time, dateTimeFormatWithTimezone)
        } else if (column.dataIndex === 'city_id') {
          row[column.dataIndex] = getCityName(motivation.city_id)
        } else if (column.dataIndex === 'type') {
          row[column.dataIndex] = motivationTypes[motivation.type]
        } else {
          row[column.dataIndex] = motivation[column.dataIndex as keyof APITYPES.Performers.GetDoerMotivations.Resp]
        }
      })

      row.key = motivation.id

      return row
    })

      
    return data
  }