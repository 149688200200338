import { APITYPES } from 'types/apitypes'
import { getCityName } from 'utils/enumUtils'


const { ShiftLocationsTypes } = APITYPES

export const getColumns = () => ([
  {
    title: 'Город',
    dataIndex: 'city_id',
    key: 'city_id',
    fixed: 'left',
  },
  {
    title: 'Тип локации',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
])

export const getRows = (locations: APITYPES.Shifts.ShiftLocations.GET.Resp) => {
  return locations.map((location) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'name') {
        row[column.dataIndex] = location.name
      } else if (column.dataIndex === 'city_id') {
        row[column.dataIndex] = getCityName(location.city_id)
      } else {
        //@ts-ignore
        row[column.dataIndex] = ShiftLocationsTypes[location[column.dataIndex]]
      }
    })
    row.key = location.id
    row.location = location

    return row
  })
}

/** Edit modal */
export type EditModalType = {open: boolean, location: APITYPES.Location | null}
export const defaultEditValue = {
  open: false,
  location: null,
}
