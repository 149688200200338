import { Modal, Skeleton } from 'antd'
import React, { ComponentPropsWithoutRef, FC } from 'react'


type Props = ComponentPropsWithoutRef<'div'> & {
  isLoading: boolean
  isOpen: boolean
  title: string
  extra?: React.ReactNode
  onClose: () => void
}

export const ReportModal: FC<Props> = ({ isOpen, onClose, title, children, isLoading, extra }) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={800}
      footer={extra ?? false}
      bodyStyle={
        {
          display: 'flex',
          flexDirection: 'column',
          minHeight: 400,
          justifyContent: 'center',
          gap: 16,
        }
      }
    >
      {
        isLoading ? (
          <Skeleton active />
        ) : (
          children
        )
      }
    </Modal>
  )
}
