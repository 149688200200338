import {
  MailingFilterType,
  MailingItem,
  MailingType,
} from './mailing-types'


export namespace APITYPES {

  export namespace Dictionary {
    export type Dictionary = {
      id: string
      name: string
    }

    export namespace Signers {
      export const URL = '/v1/signers' as const

      export type Req = {};

      export type Resp = Dictionary[];
    }
  }

  export type SupplierTariffService = {
    supplier_tariff_service_id: string
    service_id: string
    cost: number
    car_class_id: string
    client_type_id: string
  };

  export type ScheduleInterval = {
    id: string
    day_num: number
    work_from?: string
    work_to?: string
  };

  export type ScheduleDto = {
    id: string
    carwash_id: string
    description: string
    active_from: string
    active_to: string
    intervals?: ScheduleInterval[]
  };

  export type Schedule = {
    id: string
    carwash_id: string
    description: string
    active_from: Date
    active_to: Date
    intervals?: ScheduleInterval[]
  };

  export type PartnerInfoByInn = {
    legal_address: string
    legal_name: string
    inn: string
    ogrn: string
    general_manager: string
  };

  export type PartnerDocument = {
    id: string
    type: string
    number: string
    date: string
    link: string
    payment_type: string
    act_frequency: string
    payment_delay_period: string
  };

  export type PartnerBase = {
    legal_address: string
    legal_name: string
    inn: string
    ogrn: string
    checking_account: string
    bank: string
    bik: string
    corr_account: string
    general_manager: string
    diadoc_state: string
    signer_tag: string
    documents: PartnerDocument[]
  };
  export type PartnerState = {
    name: string
    actor_user_id: string
    created: string
    reason?: string
  };

  export type Partner = {
    id: string
    state: PartnerState
    legal_address: string
    legal_name: string
    inn: string
    ogrn: string
    checking_account: string
    bank: string
    bik: string
    corr_account: string
    general_manager: string
    diadoc_state: string
    signer_tag: string
    documents: PartnerDocument[]
  };

  export type CarwashFilter = {
    search?: string
    region?: string[]
    state?: string[]
    partner_id?: string[]
    client_type?: string[]
    object_type_id?: string[]
    domain?: RentalObjectTypeDomain
  };

  export type CarwashState = {
    name: string
    actor_user_id: string
    reason: string
    created: string
  };

  export type CarwashLocationBase = {
    region: string
    area: string
    district: string
    address: string
    subway: string
    directions: string
    longitude: string
    latitude: string
  }

  export type CarwashLocation = CarwashLocationBase & {
    id: string
    carwash_id: string
  };

  export type CarwashEmployee = {
    id: string
    carwash_id: string
    name: string
    role: string
    phones: string[]
    emails: string[]
    comment: string
  };

  export type CarwashEmployeeBase = {
    name: string
    role: string
    phones: string[]
    emails: string[]
    comment: string
  };

  export type Penalty = {
    id: string
    name: string
    description?: string
    value: number
  };

  export type UtilityAccrual = {
    id: string
    object_id: string
    utility_date: string
    utility_type: string
    cost: number
  };

  export type Compensation = {
    id: string
    name: string
    value: number
  };

  export enum MOTIVATION_TYPE {
    PENALTY = 'penalty',
    COMPENSATION = 'compensation'
  }

  export enum PenaltyStateStatus {
    new = 'новый',
    agreed = 'согласен',
    disagreed = 'не согласен',
    canceled = 'отменен',
    applied = 'учтен',
    apply = 'учтен',
    cancel = 'отменен',
    paid = 'оплачен'
  }

  export enum ChargePriorities {
    HIGH = 'Высокий',
    MEDIUM = 'Средний',
    LOW = 'Низкий'
  }

  export type CarwashPenaltyState = {
    name: PenaltyStateStatus
    actor_user_id: string
    comment: string
    created: Date | string
  };

  export type CarwashPenalty = {
    id: string
    carwash_id: string
    penalty_id: string
    name: string
    description: string
    value: number
    created: Date | string
    state: CarwashPenaltyState
  };

  export type CarwashCompensation = {
    id: string
    carwash_id: string
    compensation_id: string
    name: string
    description: string
    value: number
    date: string
    created: Date | string
  };

  export type CarwashHistory = {
    name: string
    actor: string
    reason: string
    created: string
  };

  export type CarwashCredentials = {
    login?: string
    password?: string
  };

  export type DynamicCarwashData = Partial<{
    client_types: string[]
    carwash_penalties: CarwashPenalty[]
    layout: string
    space_quantity: number | null
    tire_service: boolean
    chemical_cleaning: boolean
    client_zone: boolean
    vending_machine: boolean
    cameras: boolean
    cashless_payment: boolean
    fuel_card: boolean
    parking: boolean
    gate_height: number | null
    max_load_time: string
    working_time: string
    questionnare: string
    object_photos: string
    qos: number
  }> & Record<string, unknown>

  export type CarwashBase<LocationType = CarwashLocationBase, EmployeeType = CarwashEmployeeBase> = {
    bq_id: number | null
    partner_id: string
    cooperation_date: string
    cooperation_type: string
    name: string
    object_type_id: number
    own_type: string
    dynamic_data: DynamicCarwashData
    rent_cost?: number | null
    rent_unit?: string | null
    employees?: EmployeeType[]
    location?: LocationType
  }

  export type RentalObjectTypeDomain = 'carwash' | 'space';

  export type RentalObjectTypeItem = {
    id: string
    name: string
    description: string
    has_employees: boolean
    has_location: boolean
    has_schedule: boolean
    domain: RentalObjectTypeDomain
  };

  export type RentalObjectType = RentalObjectTypeItem & {
    fields: RentalObjectFieldType[]
  };

  export type Carwash = CarwashBase<CarwashLocation, CarwashEmployee> & {
    id: string
    credentials: CarwashCredentials
    state: CarwashState
    history?: CarwashHistory[]
    rental_object_type: RentalObjectType
  };

  export type RentalObjectFieldType = {
    name: string
    type: 'string' | 'boolean' | 'integer' | 'float' | 'enum' | 'date'
    label: string
    required: boolean
    values?: string[]
    default?: string | boolean | number
  };

  export type CarwashClientType = {
    id: string
    name: string
    position: number
    code: string
    is_connected: boolean
  };

  export type Doer = {
    [x: string]: any
    id: string
    name: string
    phone_number: string
    state: {
      name: string
      created: string
    }
    roles: {
      created: string
      id: string
      name: string
      role_id: string
    }[]
    delay: string
    telegram_url?: string | null
    email?: string | null
    birth_date?: string | null
    wallet?: any
    last_shift_date: Date | null
    next_shift_date: Date | null
  };

  export type DoerTariffGroups = {
    id: string
    name:string
    doer_tariffs: [
      {
        id:string
        title:string
        begin?: string
        end?: string
      }
    ]
  }

  export type RolesFilter = {
    name?: string
    city_id?: number
    group_id?: string
    department?: string
  };

  export type Role = {
    id: string
    name: string
    created: string
    city_id: number
    department: string
    group_name: string
    group_id: string
    actor_user_id: string
  };

  export type RoleGroupsRoles = {
    id: string
    name: string
  };

  export type RoleGroups = {
    id: string
    name: string
    department: string
    roles: RoleGroupsRoles[]
  };

  export type DoerRole = {
    id: string
    role_id: string
    role_name: string
    name: string
  };

  export type CarClass = {
    id: string
    name: string
    group: string
  };

  export type Employer = {
    id: string
    name: string
  }

  export type DoersFilterType = {
    state?: string
    name?: string
    phone_number?: string
    role_id?: string[]
    last_active_days?: number
    begin?: string
    end?: string
  };

  export type DoerShift = {
    id: string
    doer_user_id: string
    employment_id?: string
    shift_id: string
    offer_reserve_id: string
    shift_type: {
      id: string
      type: string
      city_id: number
    }
    role: {
      id: string
      name: string
    }
    location: {
      id: string
      type: string
      carwash_id: string
      name: string
    }
    state: 'confirmed' | 'canceled' | 'started' | 'ended' | 'redirected'
    created: string
    date: string
    begin: string
    end: string
    shift_type_begin: string
    shift_type_end: string
    operations_count: number
  };

  export type DoerReserve = {
    id: string
    doer_user_id: string
    created: string
    state: 'booked' | 'canceled' | 'confirmed'
    offer_id: string
    date: string
    doer_shift_id: string
    shift_type: {
      id: string
      type: string
      city_id: number
      begin: string
      end: string
    }
    role: {
      id: string
      name: string
    }
    location: {
      id: string
      type: string
      name: string
    }
  };

  export type DoerMotivation = {
    id: string
    doer_user_id: string
    role_penalty_id: string
    title: string
    description: string
    value: number
    time: string
    type: 'penalty' | 'compensation'
    role_name: string
    doer_name: string
    role_id: string
    city_id: number
    initiator_name?: string
    state: {
      name: string
      actor_user_id: string
      actor_user_name?: string
      comment: string
      created: string
    }
  }

  export type DoerPenalty = {
    id: string
    doer_user_id: string
    role_penalty_id?: string
    title?: string
    description?: string
    value: number
    time: string
    role_name: string
    role_group_name?: string
    role_department_name?: string
    doer_name?: string
    role_id: string
    city_id: number
    charge_bill_percent?: number
    charge_priority?: number
    initiator_name?: string
    state: {
      name: 'new' | 'canceled' | 'applied'
      actor_user_id?: string
      comment?: string
      created?: string
    }
    records: DoerPenaltyRecord[]
  }

  export type DoerPenaltyRecord = {
    id: string
    value: number
    role_name?: string
    role_group_name?: string
    role_department_name: string
    role_id?: string
    city_id?: number
    city_name?: string
    bill_state_name?: string
    billing_begin: string
    billing_end: string
    bill_state_created?: string
  }

  export type DoerCompensation = {
    id: string
    doer_user_id: string
    title?: string
    description?: string
    value: number
    time: string
    role_name: string
    role_group_name?: string
    role_department_name?: string
    doer_name?: string
    role_id: string
    city_id: number
    initiator_name?: string
    state: {
      name: 'new' | 'canceled' | 'applied'
      actor_user_id?: string
      comment?: string
      created?: string
    }
  }

  export type DoerMonitoring = {
    ended: string
    started: string
    date: string
    district: string
    carwash_address: string
    city: {
      id: number
      name: string
      timezone: string
    }
    doer: {
      doer_user_id: string
      name: string
    }
    operation_count: number
    is_first_shift: boolean
    shift_type: {
      type: string
      id: string
      end: string
      begin: string
    }
  }

  export type DoerVacancy = {
    id: string
    role: {
      id: string
      name: string
    }
    city: {
      id: number
      name: string
    }
    location?: {
      id?: string | null
      name?: string | null
    }
    state: keyof typeof VacancyStateTrans
    updated: string
    comment: string
    value: number
    priority: 1 | 2 | 3 | 4
    created: string
    ended: string
    target_date: string
    begin: string
    end: string
    doers: DoerVacancyWithDoers[]
  }
  export type DoerVacancyWithDoers = {
    created: string
    name: string
    user_id: string
  }

  export enum VacancyStateTrans {
    open = 'Открыт',
    closed = 'Закрыт',
    canceled = 'Отменен',
  }

  export enum VacancyPriorityTrans {
    CRITICAL = 'Критический',
    HIGH = 'Высокий',
    MEDIUM = 'Средний',
    LOW = 'Низкий'
  }

  export type VacancyWithDoersCount = {
    doers_count: number
    efficiency: number
  } & DoerVacancy

  export namespace Doers {

    export type StateType = 'new' | 'confirmed';

    export type AddDoer = {
      phone_number: number
      name: string
      yandex_executor_id: string
      role_id: string
      state?: StateType
    }

    export namespace Get {
      export const URL = '/v1/doers' as const

      export type Req = {
        offset?: number
        limit?: number
        state?: string
        search?: string
        name?: string
        phone_number?: string
        role_id?: string[]
        last_active_days?: number
      };

      export type Resp = Doer[];
    }

    export namespace POST {
      export const URL = '/v1/doers' as const

      export type Req = AddDoer

      export type Resp = Doer;
    }

    export namespace GetById {
      export const URL = '/v1/doers/:doerId' as const

      export type Req = {
        doer_id: string
      };

      export type Resp = APITYPES.Doer;
    }

    export namespace GetShifts {
      export const URL = '/v1/doers/:doerId/shifts' as const

      export type Req = {
        doer_id: string
      };
      export type Params = {
        date_from?: string
        date_to?: string
        state?: (
          | 'confirmed'
          | 'canceled'
          | 'started'
          | 'ended'
          | 'redirected'
        )[]
        offset?: number
        limit?: number
      };

      export type Resp = APITYPES.DoerShift[];
    }

    export namespace PutContractorsClearWallet {
      export const URL = 'v1/contractors/:contractor_id/actions/clear_wallet'

      export type Req = {}

      export type Resp = any
    }

    export namespace GetReserves {
      export const URL = '/v1/doers/:doerId/reserves' as const

      export type Req = {
        doer_id: string
      };

      export type Params = {
        state?: ('booked' | 'canceled' | 'confirmed')[]
        offset?: number
        limit?: number
        date_from?: string
        date_to?: string
      };

      export type Resp = APITYPES.DoerReserve[];
    }

    export namespace Put {
      export const URL = '/v1/doers/:id' as const

      export type Req = {
        name: string
        delay?: string
      };

      export type Resp = any;
    }

    export namespace Block {
      export const URL = '/v1/doers/:id/block' as const

      export type Req = {};

      export type Resp = any;
    }

    export namespace Confirm {
      export const URL = '/v1/doers/:id/confirm' as const

      export type Req = {};

      export type Resp = any;
    }

    export namespace Roles {
      export namespace GET {
        export const URL = '/v1/roles' as const

        export type Params = {
          id?: string
          name?: string
          city_id?: number
        };

        export type Resp = Role[];
      }
      export namespace POST {
        export const URL = '/v1/roles' as const

        export type Req = {
          group_id: string
          name: string
          city_id?: number
        };

        export type Resp = any;
      }

      export namespace PUT {
        export const URL = '/v1/roles/:role_id' as const

        export type Req = {
          name: string
          city_id: number
        };

        export type Resp = any;
      }
    }

    export namespace RoleGroups {
      export namespace GET {
        export const URL = '/v1/role_groups' as const

        export type Resp = RoleGroups[];
      }
    }

    export namespace DoerRole {
      export namespace GET {
        export const URL = '/v1/doers/:doer_id/roles' as const

        export type Params = {
          doer_id: string
        };

        export type Resp = DoerRole[];
      }
      export namespace POST {
        export const URL = '/v1/doers/:doer_id/roles' as const
        export type Params = {
          doer_id: string
        };
        export type Req = {
          id: string
        };
        export type Resp = any;
      }
      export namespace DELETE {
        export const URL = '/v1/doer_roles/:doer_role_id' as const
        export type Params = {
          doer_role_id: string
        };
        export type Resp = any;
      }
    }
  }

  export namespace DoerMonitoring {
    export namespace GetAllocations {
      export const URL = '/v1/doers/monitoring/allocations'

      export type Req = {
        date: string
        role_id: string
        shift_type_id?: string
        doer_user_id?: string
        location_id?: string
        city_id?: number
        limit?: number
        offset?: number
      }

      export type Resp = DoerMonitoring[]
    }

    export namespace UpdateAllocations {
      export const URL = '/v1/doers/monitoring/allocations/updates'

      export type Req = {
        date: string
        role_id: string
        shift_type_id?: string
        doer_user_id?: string
        location_id?: string
        city_id?: number
        limit?: number
        offset?: number
      }

      export type Resp = DoerMonitoring[]
    }
  }

  export namespace DoerVacancies {
    export namespace Get {
      export const URL = '/v1/vacancies' as const

      export type Query = {
        role_id?: string
        group_id?: string
        department?: string
        city_id?: number
        location_id?: string
        priority?: 1 | 2 | 3 | 4
        state?: keyof typeof VacancyStateTrans
        limit?: number
        offset?: number
      }

      export type Resp = VacancyWithDoersCount[]
    }

    export namespace Post {
      export const URL = '/v1/vacancies' as const

      export type Req =  {
        role_id: string
        city_id: string
        value: number
        priority: number
        begin: string
        end: string
        target_date: string
        location_id?: string
        comment?: string
      }

      export type Resp = any
    }

    export namespace GetVacancy {
      export const URL = '/v1/vacancies/:vacancy_id' as const

      export type Params = {
        vacancy_id: string
      }

      export type Resp = {
        doers_count: {
          date: string
          count: number
        }[]
      } & DoerVacancy
    }

    export namespace PutVacancy {
      export const URL = '/v1/vacancies/:vacancy_id' as const

      export type Params = {
        vacancy_id: string
      }

      export type Req = {
        value: number
      }

      export type Resp = any
    }

    export namespace OpenVacancyById {
      export const URL = '/v1/vacancies/:vacancy_id/actions/open' as const

      export type Params = {
        vacancy_id: string
      }

      export type Req = {
        comment?: string
      }

      export type Resp = any
    }

    export namespace CancelVacancyById {
      export const URL = '/v1/vacancies/:vacancy_id/actions/cancel' as const

      export type Params = {
        vacancy_id: string
      }

      export type Req = {
        comment?: string
      }

      export type Resp = any
    }
  }

  export namespace Mailing {
    export namespace GetList {
      export const URL = '/v1/mailing' as const

      export type Req = MailingFilterType;

      export type Resp = MailingItem[];
    }

    export namespace Get {
      export const URL = '/v1/mailing/:id' as const

      export type Resp = MailingType;
    }

    export namespace Post {
      export const URL = '/v1/mailing' as const

      export type Req = any;

      export type Resp = MailingType;
    }

    export namespace RecipientsExport {
      export const URL = '/v1/mailing/:id/procedures/download_recipients' as const
    }
  }

  export type BillsFilter = {
    contractor_type?: 'partner' | 'doer'
    contractor_id?: string[] | undefined
    state?: string[] | undefined
    start?: string | undefined
    end?: string | undefined
    nonzero?: string | undefined
    bill_type?: string | undefined
  };

  export type BillItem = {
    id: string
    name: string
    state: 'auto' | 'manual' | 'deleted' | 'updated'
    description: string
    value: number
    comment: string
    group: string
  };

  export type BillHistory = {
    name: string
    actor: string
    comment: string
    created: string
  };

  export enum BillState {
    draft = 'draft',
    canceled = 'canceled',
    negotiation = 'negotiation',
    accepted = 'accepted',
    declined = 'declined',
    paid = 'paid',
    paying = 'paying',
  }

  export type Bill = {
    id: string
    name: string
    contractor_id: string
    description: string
    period: string
    pdf_links: {
      act: string
      bill: string
      details: string
    }
    legal_name: string
    state: {
      name: BillState
      actor_id: string
      created: string
    }
    total_value: number
    items?: BillItem[]
    history: BillHistory[]
    bill_type: string
  };

  export enum ContractorType {
    doer = 'doer',
    partner = 'partner',
  }

  export enum ContractorTypeTrans {
    partner = 'Партнер',
    doer = 'Исполнитель',
  }

  export type ContractorFilter = {
    search?: string
    type?: ContractorType
  };

  export type Contractor = {
    id: string
    legal_name: string
    inn: string
    type: ContractorType
    legal_address?: string
    disconnected?: string
    phone?: number
  };

  export type ContractorRecord = {
    id: string
    cost: number
    item_name: string
    item_description: string
    bill_id: string
    bill_name: string
    created: string
  };

  export type DoerRecords = {
    id: string
    contractor_name: string
    reason: string
    type: string
    value: string
    billing_date: string
    bill_calculation_id: string
    state?: 'not_billed'| 'accepted' | 'paid'
    city_name: string
    role_group_name: string
    role_name: string
    limit: number
    offset: number
  }

  export namespace Billing {
    export namespace GetBills {
      export const URL = '/v1/bills' as const

      export type Req = {
        state?: string[]
        contractor_id?: string[]
        start?: string
        end?: string
        offset?: number
        limit?: number
        contractor_type?: 'partner' | 'doer'
      };

      export type Resp = Bill[];
    }

    export namespace GetExportBills {
      // eslint-disable-next-line max-len
      export const URL = '/v1/billing/procedures/export_bills' as const

      export type Req = {
        id: string
      };

      export type Query = {
        contractor_id?: string[] | undefined
        state?: string[] | undefined
        start?: string | undefined
        end?: string | undefined
        nonzero?: string | undefined
        contractor_type?: 'partner' | 'doer'
      };

      export type Resp = any;
    }

    export namespace GetExportBillsPnl {
      // eslint-disable-next-line max-len
      export const URL = '/v1/billing/procedures/export_pnl' as const

      export type Req = {
        id: string
      };

      export type Query = {
        contractor_id?: string[] | undefined
        state?: string[] | undefined
        start?: string | undefined
        end?: string | undefined
        nonzero?: string | undefined
        contractor_type?: 'partner' | 'doer'
      };

      export type Resp = any;
    }

    export namespace GetExportBillsConsole {
      // eslint-disable-next-line max-len
      export const URL = '/v1/billing/procedures/export_console' as const

      export type Req = {
        id: string
      };

      export type Query = {
        contractor_id?: string[] | undefined
        state?: string[] | undefined
        start?: string | undefined
        end?: string | undefined
        nonzero?: string | undefined
        contractor_type?: 'partner' | 'doer'
      };

      export type Resp = any;
    }

    export namespace PostImportBillsItems {
      export const URL = '/v1/billing/procedures/import_bill_items' as const

      export type Req = {};

      export type Resp = string;
    }
    export namespace PostInvoicesForPayment {
      export const URL = '/v1/billing/procedures/paying_bills' as const

      export type Req = BillsFilter;

      export type Resp = string;
    }

    export namespace CreateBill {
      export const URL = '/v1/bills' as const

      export type Req = {
        contractor_ids?: string[]
        start: string
        end: string
        created: string
        contractor_type?: 'partner' | 'doer'
        skip_disconnected: boolean
      };

      export type Resp = Bill;
    }

    export namespace GetBillById {
      export const URL = '/v1/bills/:id' as const

      export type Req = {};

      export type Resp = Bill;
    }

    export namespace UpdateBillById {
      export const URL = '/v1/bills/:id' as const

      export type Req = {
        name: string
        description: string
      };

      export type Resp = Bill;
    }

    export namespace CancelBillById {
      export const URL = '/v1/bills/:id/actions/cancel' as const

      export type Req = {
        comment: string
      };

      export type Resp = Bill;
    }

    export namespace NegotiationBillById {
      export const URL = '/v1/bills/:id/actions/negotiation' as const

      export type Req = {
        comment: string
      };

      export type Resp = Bill;
    }

    export namespace PaidBillById {
      export const URL = '/v1/bills/:id/actions/paid' as const

      export type Req = {
        comment: string
      };

      export type Resp = Bill;
    }

    export namespace PayingBillById {
      export const URL = '/v1/bills/:id/actions/paying' as const

      export type Req = {
        comment: string
      };

      export type Resp = Bill;
    }

    // export namespace DeclineBillById {
    //   export const URL = '/v1/bills/:id/decline' as const
    //
    //   export type Req = {
    //     comment: string
    //   }
    //
    //   export type Resp = Bill
    // }

    // export namespace AcceptBillById {
    //   export const URL = '/v1/bills/:id/accept' as const
    //
    //   export type Req = {
    //     comment: string
    //   }
    //
    //   export type Resp = Bill
    // }

    export namespace AddBillItem {
      export const URL = '/v1/bills/:id/bill_items' as const

      export type Req =
        | {
          name: string
          description: string
          value: number
          group?: string
        }
        | {
          record_id: string
        };

      export type Resp = Bill;
    }

    export namespace UpdateBillItem {
      export const URL = '/v1/bills/:bill_id/bill_items/:bill_item_id' as const

      export type Req = {
        name?: string
        description?: string
        value?: number
        group?: string
      };

      export type Resp = Bill;
    }

    export namespace DeleteBillItem {
      export const URL = '/v1/bills/:bill_id/bill_items/:bill_item_id' as const

      export type Req = {};

      export type Resp = Bill;
    }

    export namespace GetContractors {
      export const URL = 'v1/contractors'

      export type Req = {
        offset?: number
        limit?: number
      } & APITYPES.ContractorFilter;

      export type Resp = Contractor[];
    }

    export namespace GetSingleContractor {
      export const URL = 'v1/contractors/:id'

      export type Req = {
        id: string
      }

      export type Resp = Contractor;
    }

    export namespace GetContractorRecords {
      export const URL = 'v1/contractors/:id/records'

      export type Req = {
        end?: string
        start?: string
        record_id?: string
        bill_id?: string
        is_vacant?: boolean
      };

      export type Resp = ContractorRecord[];
    }

    export namespace GetBillDownloadUrl {
      export const URL = '/v1/bills/:bill_id/pdf' as const

      export type Resp = {
        act?: string
        bill?: string
        details?: string
      };
    }

    export namespace CreateBillDownloadUrl {
      export const URL = '/v1/bills/:bill_id/pdf' as const

      export type Resp = {
        act: string
        bill: string
        details?: string
      };
    }
    export namespace GetDoerRecords {
      export const URL = '/v1/doer_records' as const

      export type Req = {
        billing_begin?: string
        billing_end?: string
        begin?: string
        end?: string
        bill_calculation_id?: string
        contractor_id?: string
        department_name?: string
        role_group_name?: string
        city_id?: string
        offset?: number
        state?: string
        limit?: number
      };

      export type Resp = DoerRecords[];
    }

    export namespace ExportDoerRecords {
      export const URL = '/v1/billing/procedures/export_doer_records' as const

      export type Req = {
        billing_begin?: string
        billing_end?: string
        begin?: string
        end?: string
        contractor_id?: string
        department_name?: string
        role_group_name?: string
        city_id?: string
        state?: string
        aggregated?: string
        console?: string
      };

      export type Resp = any;
    }
    export namespace ImportDoerRecords {
      export const URL = '/v1/billing/procedures/import_doer_records' as const

      export type Req = any

      export type Resp = any;
    }

    export namespace AcceptDoerRecords {
      export const URL = '/v1/billing/procedures/accept_doer_records' as const

      export type Req = {
        billing_begin?: string
        billing_end?: string
        begin?: string
        end?: string
        contractor_id?: string
        department_name?: string
        role_group_name?: string
        city_id?: string
        state?: string
      };

      export type Resp = any;
    }
    export namespace PayDoerRecords {
      export const URL = '/v1/billing/procedures/pay_doer_records' as const

      export type Req = {
        billing_begin?: string
        billing_end?: string
        begin?: string
        end?: string
        contractor_id?: string
        department_name?: string
        role_group_name?: string
        city_id?: string
        state?: string
      };

      export type Resp = any;
    }
    export namespace GetDoerRecordsById {
      export const URL =
        '/v1/doer_records/:doer_record_id' as const
      export type Req = {
        doer_record_id: string
      }
      export type Resp = any
    }

    export namespace DeleteDoerRecord {
      export const URL =
        '/v1/doer_records/:doer_record_id' as const
      export type Req = {
        doer_record_id: string
      }
      export type Resp = any
    }

    export namespace GetDoerRecordsPeriod {
      export const URL = '/v1/doer_record_periods' as const

      export type Params = {
        offset: number
        limit: number
      }

      export type Resp = {
        billing_begin: string
        billing_end: string
      }[];
    }

    export namespace GetDoerRecordTypes {
      export const URL = '/v1/doer_record_types' as const

      export type Resp = string[];
    }
  }

  export namespace CarwashClientTypes {
    export namespace Get {
      export const URL = '/v1/carwashes/:id/client_types' as const

      export type Req = {};

      export type Resp = CarwashClientType[];
    }

    export namespace Connect {
      export const URL =
        '/v1/carwashes/:carwash_id/client_types/:client_type_id/connect' as const

      export type Req = {};

      export type Resp = string;
    }

    export namespace Disconnect {
      export const URL =
        '/v1/carwashes/:carwash_id/client_types/:client_type_id/disconnect' as const

      export type Req = {};

      export type Resp = string;
    }
  }

  export namespace Auth {
    export const URL = '/v1/auth/login' as const

    export type Req = {
      login: string
      password: string
    };

    export type Resp = {
      token: string
    };
  }

  export namespace Schedules {
    export namespace Get {
      export const URL = '/v1/carwashes/:id/schedules' as const

      export type Resp = ScheduleDto[];
    }

    export namespace Post {
      export const URL = '/v1/carwashes/:id/schedules' as const

      export type Req = {
        description: string
        active_from: Date
        active_to: Date
      };

      export type Resp = ScheduleDto;
    }

    export namespace GetItem {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id' as const

      export type Resp = ScheduleDto;
    }

    export namespace Put {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id' as const

      export type Req = {
        description: string
        active_from: string
        active_to: string
      };

      export type Resp = ScheduleDto;
    }

    export namespace Delete {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id' as const

      export type Resp = any;
    }

    export namespace GetIntervals {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id/intervals' as const

      export type Resp = ScheduleInterval[];
    }

    export namespace GetInterval {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id/intervals/:interval_id' as const

      export type Resp = ScheduleInterval;
    }

    export namespace DeleteInterval {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id/intervals/:interval_id' as const

      export type Resp = any;
    }

    export namespace UpdateInterval {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id/intervals/:interval_id' as const

      export type Req = {
        day_num: number
        work_from?: string | null
        work_to?: string | null
      };

      export type Resp = ScheduleInterval;
    }

    export namespace CreateInterval {
      export const URL =
        '/v1/carwashes/:carwash_id/schedules/:schedule_id/intervals' as const

      export type Req = {
        day_num: number | undefined
        work_from: string
        work_to: string
      };

      export type Resp = ScheduleInterval;
    }
  }

  export namespace File {
    export namespace Delete {
      export const URL = '/v1/files/:id' as const

      export type Resp = any;
    }

    export namespace Put {
      export const URL = '/v1/files/:id' as const

      /** application/octet-stream */
      export type Req = string
    }
  }

  export namespace Office {
    export namespace Tariff {
      export namespace Get {
        export const URL = '/v1/supplier_tariff_services' as const

        export type Req = {
          carwash_id: string
          client_type_id?: string
          car_class_id?: string
        };

        export type Resp = SupplierTariffService[];
      }

      export namespace Post {
        export const URL = '/v1/supplier_tariff_services' as const

        export type Req = {
          carwash_id: string
          client_type_id: string
          car_class_id: string
          service_id: string
          cost: number
        };

        export type Resp = SupplierTariffService;
      }

      export namespace Put {
        export const URL = '/v1/supplier_tariff_services/:id' as const

        export type Req = {
          cost: number
        };

        export type Resp = SupplierTariffService;
      }

      export namespace Delete {
        export const URL = '/v1/supplier_tariff_services/:id' as const

        export type Req = {
          id: string
        };

        export type Resp = {};
      }
    }
  }

  export namespace ClientTypes {
    export namespace Get {
      export const URL = '/v1/client_types' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
        position: number
        code: string
      }[];
    }

    export namespace Post {
      export const URL = '/v1/client_types' as const

      export type Req = {
        name: string
        position: number
        code: string
      };

      export type Resp = {
        id: string
        name: string
        position: number
        code: string
      };
    }

    export namespace Put {
      export const URL = '/v1/client_types/:id' as const

      export type Req = {
        name: string
        position: number
        code: string
      };

      export type Resp = {
        id: string
        name: string
        position: number
        code: string
      };
    }

    export namespace Delete {
      export const URL = '/v1/client_types/:id' as const

      export type Req = {};

      export type Resp = string;
    }

    export namespace GetClientType {
      export const URL = '/v1/client_types/:id' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
        position: number
        code: string
      };
    }
  }

  export namespace CarClasses {
    export namespace Get {
      export const URL =
        '/v1/client_types/:client_type_id/car_classes' as const

      export type Req = {
        client_type_id: string
      };

      export type Resp = {
        id: string
        name: string
        position: number
      }[];
    }

    export namespace Put {
      export const URL =
        '/v1/client_types/:client_type_id/car_classes/:car_class_id' as const

      export type Req = {
        position: number
        car_class_name: string
      };

      export type Resp = string;
    }

    export namespace Delete {
      export const URL =
        '/v1/client_types/:client_type_id/car_classes/:car_class_id' as const

      export type Req = {};

      export type Resp = string;
    }

    export namespace Post {
      export const URL =
        '/v1/client_types/:client_type_id/car_classes' as const

      export type Req = {
        car_class_id: string
        car_class_name: string
        position: number
      };

      export type Resp = string;
    }
  }

  export enum ServiceProvider {
    'partner' = 'Партнер',
    'doer' = 'Исполнитель',
  }

  export namespace Services {
    export namespace Get {
      export const URL = '/v1/client_types/:client_type_id/services' as const

      export type Req = {
        client_type_id: string
      };

      export type Resp = {
        id: string
        name: string
        position: number
      }[];
    }

    export namespace Put {
      export const URL =
        '/v1/client_types/:client_type_id/services/:service_id' as const

      export type Req = {
        position: number
      };

      export type Resp = string;
    }

    export namespace Delete {
      export const URL =
        '/v1/client_types/:client_type_id/services/:service_id' as const

      export type Req = {};

      export type Resp = string;
    }

    export namespace Post {
      export const URL = '/v1/client_types/:id/services' as const

      export type Req = {
        service_id: string
        position?: number
      };

      export type Resp = string;
    }
  }

  export namespace AllServices {
    export namespace Get {
      export const URL = '/v1/services' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
        description: string
        provider: ServiceProvider
      }[];
    }

    export namespace Delete {
      export const URL = '/v1/services/:service_id' as const

      export type Req = {
        service_id: string
      };

      export type Resp = string;
    }

    export namespace Put {
      export const URL = '/v1/services/:service_id' as const

      export type Req = {
        name: string
        description: string
        provider: ServiceProvider
      };

      export type Resp = string;
    }

    export namespace GetService {
      export const URL = '/v1/services/:id' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
        description: string
        provider: ServiceProvider
      };
    }

    export namespace Post {
      export const URL = '/v1/services' as const

      export type Req = {
        name: string
        description: string
        provider: ServiceProvider
      };

      export type Resp = {
        id: string
        name: string
        description: string
        provider: ServiceProvider
      };
    }
  }

  export namespace AllCarClasses {
    export namespace Post {
      export const URL = '/v1/car_classes' as const

      export type Req = {
        name: string
        group: string
      };

      export type Resp = {
        id: string
        name: string
      };
    }
    export namespace Get {
      export const URL = '/v1/car_classes' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
        position: number
        group?: string
      }[];
    }

    export namespace GetCars {
      export const URL = '/v1/car_classes/:id/car_models' as const

      export type Req = {};

      export type Resp = {
        car_class_id: string
        car_class_name: string
        car_model_id: string
        car_model_name: string
        car_brand_name: string
      }[];
    }

    export namespace AddCar {
      export const URL =
        '/v1/car_classes/:car_class_id/car_models/:car_model_id' as const

      export type Req = {};

      export type Resp = string;
    }

    export namespace DeleteCar {
      export const URL =
        '/v1/car_classes/:car_class_id/car_models/:car_model_id' as const

      export type Req = {};

      export type Resp = string;
    }
  }

  export namespace Cars {
    export namespace GetBrands {
      export const URL = '/v1/car_brands' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
      }[];
    }

    export namespace GetModels {
      export const URL = '/v1/car_brands/:id/car_models' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
      }[];
    }

    export namespace GetModelInfo {
      export const URL =
        '/v1/car_brands/:car_brand_id/car_models/:car_model_id' as const

      export type Req = {};

      export type Resp = {
        id: string
        name: string
        brand_id: string
        brand_name: string
        car_classes: CarClass[]
      };
    }
  }

  export enum CarClassesGroups {
    'partners' = 'Партнеры',
    'marketing' = 'Маркетинг',
  }

  //users
  export type UserOrder = {
    id: string
    carwash_id: string
    promocode: string
    created: string
    state_name: OrderState
    services: OrderService[]
  };

  export enum OrderState {
    done = 'done',
    inprogress = 'inprogress',
    created = 'created',
    canceled = 'canceled',
  }

  export type OrderService = {
    service_name: string
    service_id: string
    price: number
    price_full: number
  };

  export type UserSubscription = {
    id: string
    subscription_id: string
    period: string
    car_id: string
    created: string
    active_until: string
    products: SubProducts[]
  };

  export type SubProducts = {
    product_id: string
    name: string
    limit: {
      usage: number
    }
  };

  export type UserCar = {
    car_id: string
    grz: string
    confirmed: string
    deleted: string
    car_brand_name: string
    car_model_name: string
    car_model_id: number
    client_type_code: string
    client_type_name: string
  };

  export type User = {
    id: string
    phone: number
    cars: {
      grz: string
      client_type_code: string
    }[]
  };

  export type ExpandedUser = {
    id: string
    phone: number
    cars: UserCar[]
  };

  export namespace Users {
    export namespace Get {
      export const URL = '/v1/users' as const

      export type Req = {
        search?: string
        limit?: number
        offset?: number
      };

      export type Resp = User[];
    }

    export namespace GetUser {
      export const URL = '/v1/users/:id' as const

      export type Req = {};

      export type Resp = ExpandedUser;
    }

    export namespace ChangeCar {
      export const URL = '/v1/users/:user_id/cars/:car_id' as const

      export type Req = {
        grz?: string
        car_model_id?: number
        client_type_code?: string
      };

      export type Resp = ExpandedUser;
    }

    export namespace GetSubscriptions {
      export const URL = '/v1/subscriptions' as const

      export type Req = {
        user_id: string
      };

      export type Query = {
        offset?: number
        limit?: number
      };

      export type Resp = UserSubscription[];
    }

    export namespace GetOrders {
      export const URL = '/v1/orders' as const

      export type Req = {
        user_id: string
      };

      export type Query = {
        offset?: number
        limit?: number
        state_name?: OrderState
      };

      export type Resp = UserOrder[];
    }
  }

  //carwashes
  export namespace Carwashes {
    export namespace ReplacePartner {
      export const URL = '/v1/carwashes/:id/partner_replace' as const

      export type Req = {
        partner_id: string
      };

      export type Resp = Carwash;
    }
    export namespace GetPhotos {
      export const URL = '/v1/carwashes/:carwash_id/photos' as const

      export type Req = {
        carwash_id: string
      };

      export type Resp = {
        name: string
        url: string
      }[];
    }

    export namespace AddPhoto {
      export const URL = '/v1/carwashes/:carwash_id/photos' as const

      export type Req = {
        name: string
        size: number
        mime_type: string
      };

      export type Resp = {
        url: string
      };
    }
    export namespace GetCarwash {
      export const URL = '/v1/carwashes/:id' as const

      export type Req = {
        id: string
      };

      export type Resp = Carwash;
    }
    export namespace GetPenalties {
      export const URL = '/v1/carwashes/:carwash_id/penalties' as const

      export type Params = {
        carwash_id: string
      };

      export type Query = {
        state?: string[]
        begin?: string
        end?: string
      };

      export type Resp = CarwashPenalty[];
    }

    export namespace GetCompensations {
      export const URL = '/v1/carwashes/:carwash_id/compensations' as const

      export type Params = {
        carwash_id: string
      };

      export type Query = {
        state?: string[]
        begin?: string
        end?: string
      };

      export type Resp = CarwashCompensation[];
    }

    export namespace Deactivate {
      export const URL = '/v1/carwashes/:id/deactivate' as const

      export type Req = {
        reason: string
      };

      export type Resp = Carwash;
    }

    export namespace Activate {
      export const URL = '/v1/carwashes/:id/activate' as const

      export type Req = {};

      export type Resp = Carwash;
    }
    export namespace Off {
      export const URL = '/v1/carwashes/:id/disconnect' as const

      export type Req = {
        reason: string
      };

      export type Resp = Carwash;
    }
    export namespace Put {
      export const URL = '/v1/carwashes/:id' as const

      export type Req = Partial<CarwashBase & Pick<Carwash, 'credentials'>>;

      export type Resp = Carwash;
    }
    export namespace Post {
      export const URL = '/v1/carwashes' as const

      export type Req = CarwashBase;

      export type Resp = Carwash;
    }
    export namespace Get {
      export const URL = '/v1/carwashes' as const

      export type Req = CarwashFilter & {
        limit?: number
        offset?: number
      };

      export type Resp = Array<
      Pick<Carwash, 'id'
      | 'bq_id'
      | 'partner_id'
      | 'name'
      | 'state'
      | 'location'
      | 'own_type'
      | 'cooperation_type'>
      & Pick<DynamicCarwashData, 'working_time'
      | 'client_types'>
      & { partner_legal_name: string }
      >
    }

    export namespace UtilityAccruals {
      export namespace GetList {
        export const URL = '/v1/object_utilities' as const

        export type Req = {
          object_id?: string
          limit?: number
          offset?: number
          object_type_id?: string
          utility_date_from?: string
          utility_date_to?: string
        };

        export type Resp = UtilityAccrual[];
      }

      export namespace Create {
        export const URL = '/v1/object_utilities' as const

        export type Req = UtilityAccrual;

        export type Resp = UtilityAccrual;
      }

      export namespace Edit {
        export const URL = '/v1/object_utilities/:id' as const

        export type Req = Partial<UtilityAccrual>;

        export type Resp = UtilityAccrual;
      }

      export namespace Delete {
        export const URL = '/v1/object_utilities/:id' as const
      }
    }
  }

  export namespace RentalObjectTypes {
    export namespace Get {
      export const URL = '/v1/carwash_types' as const

      export type Req = {
        domain?: RentalObjectTypeDomain
      };

      export type Resp = RentalObjectTypeItem[];
    }

    export namespace GetRentalObjectType {
      export const URL = '/v1/carwash_types/:id' as const

      export type Req = {
        id: string
      };

      export type Resp = RentalObjectType;
    }
  }

  export namespace Performers {
    export namespace GetDoerMotivations {
      export const URL = '/v1/performers/doers_motivations' as const

      export type Req = {
        doer_user_id?: string
        role_id?: string
        city_id?: number
        role_penalty_id?: string
        type?: MOTIVATION_TYPE
        state?: string
        begin?: string
        end?: string
        offset?: number
        limit?: number
      }

      export type Resp = DoerMotivation
    }

    export namespace GetPenalties {
      export const URL = '/v1/performers/penalties' as const

      export type Req = {
        role_id?: string
        city_id?: number
      };

      export type Resp = {
        id: string
        title: string
        description: string
        role_id: string
        city_id: number
        charge_bill_percent: number
        charge_priority: 0 | 1 | 2
      };
    }

    export namespace PostPenalty {
      export const URL = '/v1/performers/doers_penalties' as const

      export type Req = {
        doer_user_id: string
        penalty_id: string
        time: string
        quantity: number
        description?: string
        charge_bill_percent: number
        charge_priority: 0 | 1 | 2
        city_id: number
        role_id: string
        title: string
        value: string
        doer_shift_id: string
        employer_id?: string
      };

      export type Resp = string;
    }


    export namespace GetDoerPenalty {
      export const URL = '/v1/performers/doers_penalties/:penalty_id' as const

      export type Resp = DoerPenalty
    }

    export namespace GetDoerCompensation {
      export const URL = '/v1/performers/doers_compensations/:compensation_id' as const

      export type Resp = DoerCompensation
    }

    export namespace UpdatePenaltyStatus {
      export const URL =
        '/v1/performers/doers_penalties/:penalty_id/actions/:new_status'

      export type Params = {
        penalty_id: string
        new_status: string
      };

      export type Req = {
        comment?: string
      };

      export type Resp = {
        id: string
        doer_user_id: string
        role_penalty_id: string
        title: string
        description: string
        value: number
        time: string
        type: 'penalty' | 'compensation'
        role_name: string
        doer_name: string
        role_id: string
        city_id: number
        state: {
          name: string
          actor_user_id: string
          comment: string
          created: string
        }
      };
    }

    export namespace PostCompensation {
      export const URL = '/v1/performers/doers_compensations' as const

      export type Req = {
        doer_user_id: string
        time: string
        title: string
        city_id: number
        role_id: string
        value: string
        description?: string
        employer_id?: string
        doer_shift_id?: string
      };

      export type Resp = {
        id: string
        doer_user_id: string
        role_penalty_id: string
        title: string
        description: string
        value: number
        time: string
        type: 'penalty' | 'compensation'
        role_name: string
        doer_name: string
        role_id: string
        city_id: number
        state: {
          name: string
          actor_user_id: string
          comment: string
          created: string
        }
      };
    }

    export namespace UpdateCompensationStatus {
      export const URL =
        '/v1/performers/doers_compensations/:compensation_id/actions/:new_status'

      export type Params = {
        compensation_id: string
        new_status: string
      };

      export type Req = {
        comment?: string
      };

      export type Resp = {
        id: string
        doer_user_id: string
        role_penalty_id: string
        title: string
        description: string
        value: number
        time: string
        type: 'penalty' | 'compensation'
        role_name: string
        doer_name: string
        role_id: string
        city_id: number
        state: {
          name: string
          actor_user_id: string
          comment: string
          created: string
        }
      };
    }
  }

  export namespace Penalty {
    export namespace Get {
      export const URL = '/v1/penalties' as const

      export type Resp = Penalty[];
    }

    export namespace Post {
      export const URL = '/v1/carwashes/:carwash_id/penalties' as const

      export type Params = {
        carwash_id: string
        state?: CarwashPenaltyState
      };

      export type Req = {
        penalty_id: string
        comment: string
      };

      export type Resp = CarwashPenalty;
    }

    export namespace UpdateStatus {
      export const URL =
        '/v1/carwashes/:carwash_id/penalties/:carwash_penalty_id/actions/:new_status' as const

      export type Params = {
        carwash_id: string
        carwash_penalty_id: string
        new_status: string
      };

      export type Req = {
        comment: string
      };

      export type Resp = CarwashPenalty;
    }
  }

  export namespace Compensation {
    export namespace Get {
      export const URL = '/v1/compensations' as const

      export type Resp = Compensation[];
    }

    export namespace Post {
      export const URL = '/v1/carwashes/:carwash_id/compensations' as const

      export type Params = {
        carwash_id: string
      };

      export type Req = {
        compensation_id: string
        value: string
        date: string
        description: string
      };

      export type Resp = CarwashCompensation;
    }
  }

  export namespace Documents {
    export namespace Partners {
      export namespace Get {
        export const URL = '/v1/partners/:id/documents' as const

        export type Req = {};

        export type Resp = PartnerDocument[];
      }

      export namespace Post {
        export const URL = '/v1/partners/:id/documents' as const

        export type Req = {
          type: string
          number: string
          date: string
          link: string
          payment_type: string
          act_frequency: string
          payment_delay_period: string
        };

        export type Resp = PartnerDocument;
      }

      export namespace GetDocument {
        export const URL =
          '/v1/partners/:partner_id/documents/:document_id' as const

        export type Req = {};

        export type Resp = PartnerDocument;
      }

      export namespace UpdateDocument {
        export const URL =
          '/v1/partners/:partner_id/documents/:document_id' as const

        export type Req = {
          type: string
          number: string
          date: string
          link: string
          payment_type: string
          act_frequency: string
          payment_delay_period: string
        };

        export type Resp = PartnerDocument;
      }

      export namespace DeleteDocument {
        export const URL =
          '/v1/partners/:partner_id/documents/:document_id' as const

        export type Req = {};

        export type Resp = string;
      }
    }
  }

  export namespace CopyTariff {
    export const URL = '/v1/copy_supplier_tariff' as const

    export type Req = {
      source_carwash_id: string
      target_carwash_id: string
    };

    export type Resp = string;
  }

  export namespace Statistics {
    export namespace Carwashes {
      export const URL = '/v1/reports/carwashes' as const

      export type Req = {};

      export type Resp = {
        [key: string]: number
      };
    }

    export namespace Partners {
      export const URL = '/v1/reports/partners' as const

      export type Req = {};

      export type Resp = {
        [key: string]: number
      };
    }

    export namespace Operations {
      export namespace POST {
        export const URL = '/v1/doers/procedures/get_doer_shifts_statistic' as const

        export type Req = {
          begin: string
          end: string
          role_ids: string[]
        };
        export type Resp = OperationsStatistics[]
      }
    }
  }

  export namespace Dadata {
    export namespace Party {
      export const URL = '/v1/dadata/party' as const

      export type Req = {
        inn: string
      };

      export type Resp = PartnerInfoByInn;
    }
  }

  export namespace Partners {
    export namespace PartnersSync {
      export const URL = '/v1/partners_sync' as const

      export type Req = {};

      export type Resp = string;
    }
    export namespace GetPartner {
      export const URL = '/v1/partners/:id' as const

      export type Req = {
        id: string
      };

      export type Resp = Partner;
    }
    export namespace Get {
      export const URL = '/v1/partners' as const

      export type Req = {
        state?: string
        search?: string
        limit?: number
        offset?: number
        domain?: RentalObjectTypeDomain
      };

      export type Resp = Partner[];
    }
    export namespace Put {
      export const URL = '/v1/partners/:id' as const

      export type Req = PartnerBase;

      export type Resp = Partner;
    }

    export namespace Post {
      export const URL = '/v1/partners' as const

      export type Req = PartnerBase;

      export type Resp = Partner;
    }

    export namespace Off {
      export const URL = '/v1/partners/:partner_id/disconnect' as const

      export type Req = {
        reason: string
      };

      export type Resp = Partner;
    }

    export namespace Activate {
      export const URL = '/v1/partners/:partner_id/activate' as const

      export type Req = {};

      export type Resp = Partner;
    }

    export namespace PartnersExport {
      export const URL = '/v1/export_partners' as const

      export type Query = {
        partner_id?: string
        state?: string
        search?: string
      };

      export type Resp = any;
    }

    export namespace CarwashesExport {
      export const URL = '/v1/export_carwashes' as const

      export type Query = APITYPES.CarwashFilter;

      export type Resp = any;
    }
  }

  export namespace Employees {
    export namespace Put {
      export const URL =
        '/v1/carwashes/:carwash_id/employees/:employee_id' as const

      export type Req = CarwashEmployeeBase;

      export type Resp = Carwash;
    }

    export namespace Delete {
      export const URL =
        '/v1/carwashes/:carwash_id/employees/:employee_id' as const

      export type Req = {
        carwash_id: string
        employee_id: string
      };

      export type Resp = Carwash;
    }

    export namespace Post {
      export const URL = '/v1/carwashes/:carwash_id/employees' as const

      export type Req = CarwashEmployeeBase;

      export type Resp = Carwash;
    }
  }

  export enum Cities {
    'Москва' = 1,
    'Санкт-Петербург' = 2,
    'Сочи' = 3,
    'Краснодар' = 4,
    'Тула' = 5,
    'Екатеринбург' = 6,
    'Казань' = 7,
    'Lod' = 8,
    'Tel Aviv' = 9,
    'Зеленоград' = 10,
    'Нижний Новгород' = 11,
    'Калуга' = 12,
    'Тверь' = 13,
    'Новосибирск' = 14,
    'Ростов-на-Дону' = 15,
    'Уфа' = 16,
    'Томск' = 17,
    'Пермь' = 18,
    'Самара' = 19,
  }

  // shifts
  export type ShiftTypeRole = {
    id: string
    name: string
    city_id: Cities
  };

  export type ShiftType = {
    id: string
    name: string
    city_id: Cities
    begin: number
    end: number
    roles: ShiftTypeRole[]
  };

  export type ShiftDoerLocation = {
    type: string | null
    name: string | null
    carwash_id: string | null
  };

  export type ShiftDoerStateOptions =
    | 'booked'
    | 'canceled'
    | 'started'
    | 'ended'
    | 'failed'
    | 'confirmed'
    | 'redirected';

  export enum ShiftDoerStateTranslations {
    booked = 'Зарезервирована',
    canceled = 'Отменена',
    started = 'Начата',
    ended = 'Закончена',
    failed = 'Провалена',
    confirmed = 'Подтверждена',
    redirected = 'Перенаправлен',
  }

  export enum ShiftDoerStateColors {
    booked = 'blue',
    canceled = 'gray',
    started = 'cyan',
    ended = 'orange',
    failed = 'gray',
    confirmed = 'green',
    redirected = 'purple',
  }

  export enum ShiftDoerStateEnum {
    booked = 'booked',
    canceled = 'canceled',
    started = 'started',
    ended = 'ended',
    failed = 'failed',
    confirmed = 'confirmed',
    redirected = 'redirected',
  }

  export type ShiftDoerState = {
    name: ShiftDoerStateOptions
    created: string
  };

  export type ShiftDoersFilter = {
    state_name?: ShiftDoerStateOptions
  };

  export type ShiftDoer = {
    id: string
    doer_shift_id: string
    doer_shift_state: ShiftDoerState
    name: string
    phone_number: string | number | null
    role: { id: string, name: string }
    location: ShiftDoerLocation
  };

  export type ShiftRole = {
    id: string
    name: string
    places: number
    booked_places: number
  };

  export type Shift = {
    id: string
    shift_type_id: string
    shift_type: string
    state_name: string
    started_doer_shift_count: number
    date: string
    begin: string
    end: string
    roles: ShiftRole[]
    location: {
      id: string
      name: string
      type: string
      city_id: number
    }
    employer_id?: string
    time?: string
  };

  export type ShiftFilter = {
    date_from?: string
    date_to?: string
    city_id?: number
    shift_type_id?: string
    location_id?: string
    role_id?: string
    employer_id?: string
  };

  export type ShiftOffer = {
    offer_id: string
    role_id: string
  };

  export type ShiftTypesByRoles = {
    role_name: string
    role_id: string
    roleShiftTypes: Shifts.ShiftTypesByRole.GET.Resp
  };

  export type ShiftTypesByRolesId = {
    id: string
    name: string
    begin: string
    end: string
  };


  export type Location = {
    id: string
    type: string
    name: string
    carwash_id: string
    city_id: number
    latitude?: number
    longitude?: number
    description?: string
  };

  type FileType = 'photo' | 'video' | 'file'

  export type LocationFile = {
    name: string
    url: string
    owner_id?: string
  }

  export namespace Shifts {
    export namespace ShiftTypes {
      export namespace GET {
        export const URL = '/v1/shift_types' as const

        export type Resp = ShiftType[];
      }

      export namespace POST {
        export const URL = '/v1/shift_types' as const

        export type Req = {
          name: string
          city_id: number
          begin: string
          end: string
          roles: {id: string}[]
        };

        export type Resp = any;
      }
    }

    export namespace Shift {
      export namespace GET {
        export const URL = '/v1/shifts' as const

        export type Query = {
          date_from: string
          date_to: string
          city_id?: number
          role_id?: string
          location_id?: string
          shift_type_id?: string
          employer_id?: string
        };

        export type Resp = Shift[];
      }

      export namespace POST {
        export const URL = '/v1/shifts' as const

        export type ReqCreate = {
          date?: string
          date_from?: string
          date_to?: string
          shift_type_id: string
          location_id: string
          roles: { id: string, places: number }[]
          method: 'create'
          employer_id: string
          time?: string
        };

        export type ReqImport = {
          date_from: string
          date_to: string
          method: 'import'
          is_intership: boolean
          spreadsheet_id: string
          time?: string
        };

        export type Resp = any;
      }

      export namespace GetDoers {
        export const URL = '/v1/shifts/:shift_id/doers'

        export type Params = {
          shift_id: string
        };

        export type Resp = APITYPES.ShiftDoer[];
      }
    }

    export namespace ShiftById {
      export namespace GET {
        export const URL = '/v1/shifts/:shift_id' as const

        export type Params = {
          shift_id: string
        };

        export type Resp = Shift[];
      }

      export namespace PUT {
        export const URL = '/v1/shifts/:shift_id' as const

        export type Params = {
          shift_id: string
        };

        export type Req = {
          roles: {
            id: string
            places: number
          }[]
        };

        export type Resp = any;
      }

      export namespace DELETE {
        export const URL = '/v1/shifts/:shift_id' as const

        export type Params = {
          shift_id: string
        };

        export type Resp = any;
      }

      export namespace CANCEL {
        export const URL = '/v1/shifts/:shift_id/actions/cancel' as const

        export type Req = {
          comment: string
        };

        export type Params = {
          shift_id: string
        };

        export type Resp = any;
      }
    }

    export namespace CommitShifts {
      export namespace POST {
        export const URL = '/v1/doers/procedures/commit_doer_shifts' as const

        export type Req = {
          begin: string
          end: string
          role_ids: string[]
        }

        export type Resp = any
      }
    }

    export namespace ShiftTypesByRole {
      export namespace GET {
        export const URL = '/v1/shift_types/:shift_type_id/roles' as const

        export type Params = {
          role_id: string
        };

        export type Resp = {
          id: string
          name: string
        }[];
      }

      export namespace POST {
        export const URL = '/v1/shift_types/:shift_type_id/roles' as const

        export type Params = {
          shift_type_id: string
        };

        export type Req = {
          role_id: string
        };

        export type Resp = any;
      }
    }

    export namespace ShiftTypesByRoleId {
      export namespace GET {
        export const URL = '/v1/roles/:role_id/shift_types' as const

        export type Params = {
          role_id: string
        };

        export type Req = {
          shift_type_id: string
        }

        export type Resp = any;
      }
      export namespace POST {
        export const URL = '/v1/roles/:role_id/shift_types' as const

        export type Params = {
          role_id: string
        };

        export type Req = {
          shift_type_id: string
        }

        export type Resp = any;
      }
    }

    export namespace DoerShiftLocations {
      export namespace PUT {
        export const URL = '/v1/doer_shifts/:doer_shift_id/location' as const

        export type Params = {
          doer_shift_id: string
        };

        export type Req = {
          id: string
        };

        export type Resp = any;
      }

      export namespace DELETE {
        export const URL = '/v1/doer_shifts/:doer_shift_id/location' as const

        export type Params = {
          doer_shift_id: string
        };

        export type Resp = any;
      }
    }

    export namespace Employer {
      export namespace GET {
        export const URL = '/v1/employers' as const

        export type Query = {
          id?: string
          name?: string
        }

        export type Resp = Employer[];
      }
    }

    export namespace ShiftLocations {
      export namespace GET {
        export const URL = '/v1/locations' as const

        export type Params = {
          search?: string
          city_id?: number
          type?: ShiftLocationsTypesOptions
        };

        export type Resp = Location[];
      }

      export namespace POST {
        export const URL = '/v1/locations' as const
        export type Req = {
          type: string
          name: string
          carwash_id: string
          city_id: number
          latitude: number
          longitude: number
        };
        export type Resp = any;
      }

      export namespace PUT {
        export const URL = '/v1/locations/:location_id' as const

        export type Req = {
          name: string
          carwash_id: string
          latitude: number
          longitude: number
        }

        export type Resp = Location
      }

      export namespace GetChildren {
        export const URL = '/v1/locations/:location_id/children' as const
        export type Req = {
          location_id: string
        };
        export type Resp = APITYPES.Location[];
      }

      export namespace PutChildren {
        export const URL = '/v1/locations/:location_id/children' as const
        export type Query = {
          location_id: string
        };
        export type Req = {
          children_id: string
        };
        export type Resp = any;
      }

      export namespace DeleteChildren {
        export const URL = '/v1/locations/:location_id/children' as const
        export type Query = {
          location_id: string
        };
        export type Req = {
          children_id: string
        };
        export type Resp = any;
      }

      export namespace GetFiles {
        export const URL = '/v1/locations/:location_id/files' as const

        export type Query = {
          file_type: FileType
        }

        export type Resp = LocationFile[]
      }

      export namespace PostFile {
        export const URL = '/v1/locations/:location_id/files' as const

        export type Req =  {
          name: string
          size: number
          mime_type: string
          file_type?: FileType
        }

        export type Resp = {
          url: string
        }
      }
    }

    export namespace ChangeDoerShiftStatus {
      export const URL =
        '/v1/doer_shifts/:doer_shift_id/actions/:new_status' as const

      export type Req = {
        comment?: string
      };

      export type Resp = any;
    }

    export namespace ChangeDoerShift {
      export const URL =
        '/v1/doer_shifts/:doer_shift_id/actions/redirect' as const

      export type Req = {
        shift_id: string
        comment: string
      };

      export type Resp = any;
    }

    export namespace AddDoerToShift {
      export const URL = '/v1/doer_shifts' as const

      export type Req = {
        shift_id: string
        doer_id: string
        role_id: string
      };

      export type Resp = any;
    }
  }

  // shift locations
  export type ShiftLocationsTypesOptions =
    | 'carwash'
    | 'city'
    | 'darkstore'
    | 'district'
    | 'box'
    | 'selfwash'
    | 'tire-service';

  export enum ShiftLocationsTypes {
    carwash = 'Мойка',
    city = 'Город',
    darkstore = 'Даркстор',
    district = 'Район',
    box = 'Бокс',
    selfwash = 'Самомой',
    'tire-service' = 'Шиномонтаж',
  }

  // offers
  export enum OfferDoerStatusesTrans {
    booked = 'Зарезервирована',
    confirmed = 'Подтверждена',
    canceled = 'Отменена',
    overbooked = 'Подписан',
  }

  export enum OfferDoerStatusesColors {
    booked = 'blue',
    confirmed = 'green',
    canceled = 'gray',
    overbooked = 'gray',
  }

  export type Offer = {
    id: string
    places: number
    doers_counter: number
    date: string
    location: {
      id: string
      name: string
      city_id: number
    }
    role: {
      id: string
      name: string
    }
    shifts: {
      id: string
      location_name: string
    }[]
    shift_type_name: string
    shift_type: {
      id: string
      overbooking: boolean
      type: string
    }

  };

  export type OfferReserve = {
    id: string
    doer_id: string
    doer_name: string
    created: string
    state_name: string
    updated: string
    doer_shift_id: string
    shift_id: string
    shift_location_name: string
  };

  export namespace Offers {
    export namespace GET {
      export const URL = '/v1/offers' as const

      export type Query = {
        role_id?: string
        shift_type_id?: string
        location_id?: string
        date?: string
        limit?: number
        offset?: number
        understaffed?: string
        date_from?: string
        date_to?: string
        doer_id?: string
      };

      export type Resp = Offer[];
    }

    export namespace GetOfferById {
      export const URL = '/v1/offers/:offerId' as const

      export type Resp = Offer;
    }

    export namespace GetReserves {
      export const URL = '/v1/offers/:offer_id/reserves' as const

      export type Query = {
        offer_id: string
        limit?: string
        offset?: string
        state?: string
      };

      export type Resp = OfferReserve[];
    }

    export namespace SetShiftDoer {
      export const URL = '/v1/doer_shifts' as const

      export type Req = {
        shift_id: string
        offer_reserve_id: string
      };

      export type Resp = any;
    }

    export namespace ChangeReserveOffer {
      export const URL = '/v1/reserves/:reserveId' as const

      export type Req = {
        offer_id: string
      };

      export type Resp = any;
    }

    export namespace CancelReserve {
      export const URL = '/v1/reserves/:reserveId/actions/cancel' as const

      export type Req = {
        reserve_id: string
      };

      export type Resp = any;
    }

    export namespace GetShiftOffers {
      export const URL = '/v1/shifts/:shift_id/offers' as const

      export type Resp = {
        offer_id: string
        role_id: string
      }[];
    }
  }

  // operations
  export enum OperationStateNoTrans {
    inprogress = 'inprogress',
    done = 'done',
    canceled = 'canceled',
    failed = 'failed',
  }

  export enum OperationStateOptions {
    inprogress = 'В процессе',
    done = 'Готова',
    canceled = 'Отменена',
    failed = 'Провалена',
  }

  export type OperationState = {
    name: OperationStateOptions
    description: string
    actor_user_id: string
    created: string
  };

  export type OperationReport = {
    task_element_name: string
    name: string
    values: string[]
    type: 'photo' | 'gallery' | 'text' | 'video' | 'selector'
    options?: string[] | null
    validator?: string | null
    report_element_id?: string
  };

  export type OperationReports = OperationReport[] | null;

  export type OperationBase = {
    grz: string
    doer_name: string
    parent_operation_id: string
    title: string
    reports: OperationReports
    time?: string
    location: {
      id: string
      city_id: number
      type: string
      name: string
    }
    shift: {
      id: string
      begin: string
      end: string
      shift_type_name: string
    }
    operations: Operation[]
  };

  export type Operation = {
    id: string
    state: OperationState
    timezone?: string
  } & OperationBase;

  export type TOperations = {
    id: string
    grz: string
    doer_shift_id: string
    doer_user_id: string
    name: string
    title: string
    state: {
      name: OperationStateOptions
      created: string
    }
    time: string
    timezone?: string
  }[];

  export type OperationsFilter = {
    shift_date_from?: string
    shift_date_to?: string
    state?: string
    doer_name?: string
    grz?: string
    begin?: string
    end?: string
    task_id?: string
    location_id?: string
  };

  export type OperationTask = {
    id: string
    title: string
  };

  export type OperationFile = {
    task_element_name: string
    file_id: string
  };

  export type OperationsStatistics = {
    doer_shift_id: string
    role_name: string
    shift_date: string
    city_name: string
    location_name: string
    doer_name: string
    operations_count: number
    doer_shift_state_name:  'canceled' | 'started' | 'ended' | 'failed'  | 'redirected'
  }

  export type Task = {
    id: string
    action: string
    title: string
    description: string
    min_optional_elements: number
    required: boolean
    serial: number
    grz_required?: boolean | null
    parent_task_id?: string | null
    role_ids?: string | null
  };
  export type BillSyncState = {
    start?: string
    stop?: string
    period?: string
    state?: string
  };

  export namespace Tasks {
    export namespace GET {
      export const URL = '/v1/tasks' as const

      export type Resp = Task[];
    }
  }

  export namespace ReportElement {
    export namespace PUT {
      export const URL = '/v1/doer_reports/:report_id' as const

      export type Req = {
        values: string[]
      };

      export type Res = any;
    }
  }

  export namespace Operations {
    export namespace GET {
      export const URL = '/v1/operations' as const

      export type Query = {
        state?: OperationStateOptions
        search?: string
        start?: string
        end?: string
        shift_date?: string
        offset: number
        limit: number
      };

      export type Resp = TOperations;
    }

    export namespace GetOperation {
      export const URL = '/v1/operations/:operationId' as const

      export type Req = {
        operationId: string
      };

      export type Resp = Operation;
    }

    export namespace EditOperation {
      export const URL = '/v1/operations/:operationId' as const

      export type Params = {
        operationId: string
      };

      export type Req = {
        grz?: string
        location_id?: string
      };

      export type Resp = {
        grz: string
        id: string
        location: { id: string, name: string }
        parent_operation_id: string | null
        state: { name: string, created: string }
      };
    }

    export namespace DeleteOperation {
      export const URL = '/v1/operations/:operationId' as const

      export type Params = {
        operationId: string
      };

      export type Resp = any;
    }

    export namespace ChangeState {
      export const URL = '/v1/operations/:operationId/actions/:action' as const
      export type Resp = {
        grz: string
        id: string
        parent_operation_id: string
        state: { name: OperationStateNoTrans, created: string }
      };
    }

    export namespace GetOperationTasks {
      export const URL = '/v1/operations/:operationId/subtasks' as const

      export type Params = {
        operationId: string
      };

      export type Resp = OperationTask[];
    }

    export namespace CreateSuboperation {
      export const URL = '/v1/operations/:operationId/suboperations' as const

      export type Params = {
        operationId: string
      };

      export type Req = {
        task_id: string
        state: OperationStateNoTrans
      };

      export type Resp = any;
    }

    export namespace CreateOperationOrders {
      export const URL = '/v1/doers/procedures/bill_operations' as const

      export type Req = {
        begin: string
        end: string
      };

      export type Resp = any;
    }

    export namespace CloseOperationOrders {
      export const URL = '/v1/doers/procedures/bill_calculation' as const

      export type Req = {
        begin: string
        end: string
      };

      export type Resp = any;
    }

    export namespace GetOperationsSyncState {
      export const URL = '/v1/doers/procedures/bill_sync_state' as const
      export type Req = any;
      export type Resp = BillSyncState;
    }
    export namespace GetOperationFiles {
      export const URL = '/v1/operations/:operationId/files' as const

      export type Req = { operation_id: string };
      export type Resp = OperationFile[];
    }
  }

  // news
  export type NewsRole = {
    id: string
    role_id: string
    name: string
  };

  export type News = {
    id: string
    image: string
    body: string
    title: string
    publication_date: string
    created: string
    roles: NewsRole[]
  };

  export namespace News {
    export namespace GET {
      export const URL = '/v1/news' as const
      export type Query = {
        offset?: number
        limit?: number
      };
      export type Resp = News[];
    }
    export namespace POST {
      export const URL = '/v1/news' as const
      export type Req = {
        image?: string
        body: string
        title: string
        publication_date: string
        role_ids: string[]
      };
      export type Resp = any;
    }

    export namespace SingleNews {
      export namespace GET {
        export const URL = '/v1/news/:news_id' as const
        export type Params = {
          news_id: string
        };
        export type Resp = News;
      }
      export namespace PUT {
        export const URL = '/v1/news/:news_id' as const
        export type Params = {
          news_id: string
        };
        export type Req = {
          image: string
          body: string
          title: string
          publication_date: string
        };
        export type Resp = any;
      }
      export namespace DELETE {
        export const URL = '/v1/news/:news_id' as const
        export type Params = {
          news_id: string
        };
        export type Resp = any;
      }
    }

    export namespace DeleteNewsRole {
      export const URL = '/v1/news_roles/:news_role_id' as const
      export type Params = {
        news_role_id: string
      };
      export type Resp = any;
    }
  }

  // rent
  export type Rent = {
    carwash_id: string
    region: string
    address: string
    rent_count: number
    rent_amount: number
  };

  export namespace Rent {
    export namespace POST {
      export const URL = '/v1/actions/update_rents' as const

      export type Req = {
        begin: string
        end: string
        object_type_id?: string
      };

      export type Resp = Rent[];
    }

    export namespace SendRentsToBilling {
      export const URL = '/v1/actions/bill_rents' as const

      export type Req = {
        begin: string
        end: string
        object_type_id?: string
      };

      export type Resp = any;
    }

    export namespace SendUtilityAccrualsToBilling {
      export const URL = '/v1/actions/bill_utilities' as const

      export type Req = {
        begin_date: string
        end_date: string
        object_type_id?: string
      };

      export type Resp = any;
    }
  }

  // products
  export type ProductType = 'general' | 'option' | 'subscription';

  export enum ProductTypeEnum {
    general = 'general',
    option = 'option',
    subscription = 'subscription',
  }

  export enum ProductTypeTrans {
    general = 'Общий',
    option = 'Опция',
    subscription = 'Подписка',
  }

  export type ProductService = {
    id: string
    service_name: string
  };

  export type ProductOption = {
    option_product_id: string
    name: string
  };

  export type ProductStyles = {
    background_color: string
    color: string
  };

  export type ProductGroup = {
    id: string | null
    name: string | null
  };

  export type Product = {
    id: string
    type: ProductType
    name: string
    summary: string
    description: string
    sequence: number
    active: boolean
    duration: string | null
    group: ProductGroup
    style: ProductStyles | null
    services: ProductService[] | null
    options: ProductOption[] | null
  };

  export type ProductsFilter = {
    type?: ProductType
  };

  export namespace Products {
    export namespace GET {
      export const URL = '/v1/products' as const

      export type Req = ProductsFilter;

      export type Resp = Product[];
    }

    export namespace GetById {
      export const URL = '/v1/products/:productId' as const

      export type Resp = Product;
    }

    export namespace POST {
      export const URL = '/v1/products' as const

      export type Req = {
        name: string
        summary: string
        description: string
        active: boolean
        type?: ProductType
        sequence?: number
        group_id?: string
        style?: ProductStyles
      };

      export type Resp = Product;
    }

    export namespace PUT {
      export const URL = '/v1/products/:productId' as const

      export type Req = {
        name?: string
        summary?: string
        description?: string
        color?: string
        sequence?: number
        group_id?: string | null
        active?: boolean
        style?: {
          background_color?: string
          color?: string
        }
      };

      export type Resp = Product;
    }

    export namespace SERVICE {
      export namespace POST {
        export const URL = '/v1/products/:productId/services' as const

        export type Req = {
          service_id: string
        };

        export type Resp = any;
      }

      export namespace DELETE {
        export const URL =
          '/v1/products/:productId/services/:serviceId' as const

        export type Resp = any;
      }
    }

    export namespace OPTION {
      export namespace POST {
        export const URL = '/v1/products/:productId/options' as const

        export type Req = {
          option_product_id: string
        };

        export type Resp = any;
      }

      export namespace DELETE {
        export const URL =
          '/v1/products/:productId/options/:optionProductId' as const

        export type Resp = any;
      }
    }

    export namespace GROUP {
      export namespace GET {
        export const URL = '/v1/product_groups' as const

        export type Resp = ProductGroup[];
      }

      export namespace POST {
        export const URL = '/v1/product_groups' as const

        export type Req = {
          name: string
        };

        export type Resp = ProductGroup;
      }

      export namespace PUT {
        export const URL = '/v1/product_groups/:groupId' as const

        export type Req = {
          name: string
        };

        export type Resp = ProductGroup;
      }

      export namespace DELETE {
        export const URL = '/v1/product_groups/:groupId' as const

        export type Resp = any;
      }
    }
  }

  // customer tariffs
  export type CustomerTariffLocation = {
    // Локации тарифа
    id: string
    city_id: number
    carwash_id: string
    carwash_region?: string
    carwash_address?: string
  };

  export type CustomerTariffProduct = {
    // Продукты Тарифа
    product_id: string
    name: string
    price: number
    created: string
  };

  export type CustomerTariff = {
    // Тариф
    id: string
    name: string
    examples: string | null
    client_type: {
      id: string
      name: string
    }
    car_class: {
      id: string
      name: string
    }
    created: string
  };

  export type CustomerTariffFilter = {
    client_type_id?: string
    car_class_id?: string
  };

  export namespace Tariffs {
    export namespace GET {
      export const URL = '/v1/customer_tariffs' as const
      export type Req = CustomerTariffFilter;
      export type Resp = CustomerTariff[];
    }
    export namespace GetById {
      export const URL = '/v1/customer_tariffs/:tariffId' as const
      export type Resp = CustomerTariff;
    }
    export namespace POST {
      export const URL = '/v1/customer_tariffs' as const
      export type Req = {
        name: string
        car_class_id: string
        client_type_id: string
        examples?: string
      };
      export type Resp = CustomerTariff;
    }
    export namespace PUT {
      export const URL = '/v1/customer_tariffs/:customerTariffId' as const
      export type Req = {
        name?: string
        examples?: string
      };
      export type Resp = CustomerTariff;
    }

    export namespace Product {
      export namespace GET {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/products' as const
        export type Resp = CustomerTariffProduct[];
      }
      export namespace POST {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/products' as const
        export type Req = {
          product_id?: string
          price?: number
        };
        export type Resp = CustomerTariffProduct;
      }
      export namespace PUT {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/products/:productId' as const
        export type Req = {
          price?: number
        };
        export type Resp = CustomerTariffProduct;
      }
      export namespace DELETE {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/products/:productId' as const
        export type Resp = any;
      }
    }

    export namespace Location {
      export namespace GET {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/locations' as const
        export type Resp = CustomerTariffLocation[];
      }
      export namespace POST {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/locations' as const
        export type Req = {
          city_id?: number
          carwash_id?: string
        };
        export type Resp = CustomerTariffLocation;
      }
      export namespace DELETE {
        export const URL =
          '/v1/customer_tariffs/:customerTariffId/locations/:locationId' as const
        export type Resp = any;
      }
    }
  }

  // doer tariffs
  export type DoerTariffNoTasks = {
    id: string
    role_id: string
    city_id: number
    title: string
    function_name: string
    condition_1?: number
    condition_2?: number
    condition_3?: number
    condition_4?: number
    condition_5?: number
    begin: string
    end?: string | undefined
    group_id?: string | null
    employment_id?: string
    employer?: Employer
  };

  export type DoerTariffTask = {
    id: string
    task_id: string
    doer_tariff_id: string
    task_name: string
    car_class_id?: string
    cost?: number
    cost_1?: number
    cost_2?: number
    cost_3?: number
    cost_4?: number
    cost_5?: number
  };
  export type DoerTariffShiftType = {
    id: string
    shift_type_id: string
    shift_type_name: string
    doer_tariff_id: string
    cost?: number
    cost_1?: number
    cost_2?: number
    cost_3?: number
    cost_4?: number
    cost_5?: number
  };

  export type DoerTariffCost = {
    id: string
    cost?: number
    cost_1?: number
    cost_2?: number
    cost_3?: number
    cost_4?: number
    cost_5?: number
  }

  export type DoerTariff = {
    id: string
    role_id: string
    city_id: number
    title: string
    function_name: string
    function_type: 'shift_type' | 'task' | 'shift'
    condition_1: number
    condition_2: number
    condition_3: number
    condition_4: number
    condition_5: number
    tasks: DoerTariffTask[]
    shift_types?: DoerTariffShiftType[]
    shift_prices?: DoerTariffCost[]
    begin: string
    end: string | undefined
    group_id?: string | null
    employment_id?: string
    employer?: Employer
  };

  export type DoerTariffFilter = {
    search?: string
    city_id?: number
    role_id?: string
    begin?: string | undefined
    end?: string | undefined
    function_name?: string
    function_type?: string
    group_id?: string
    employer_id?: string
  };

  export type DoerTariffFunction = {
    description: string
    name: string
    title: string
  };

  export namespace DoerTariff {
    export namespace GET {
      export const URL = '/v1/doer_tariffs' as const
      export type Req = {
        role_id?: string
      };
      export type Resp = DoerTariff[];
    }
    export namespace GetById {
      export const URL = '/v1/doer_tariffs/:doerTariffId' as const
      export type Resp = DoerTariff;
    }
    export namespace POST {
      export const URL = '/v1/doer_tariffs' as const
      export type Req = {
        role_id: string
        city_id: number
        title: string
        function_name: string
        condition_1?: number
        condition_2?: number
        condition_3?: number
        condition_4?: number
        condition_5?: number
        begin: string | null
        end?: string | null
      };
      export type Resp = DoerTariff;
    }
    export namespace PUT {
      export const URL = '/v1/doer_tariffs/:doerTariffId' as const
      export type Req = {
        id: undefined
        tasks: undefined
        shift_types: undefined
        role_id: string
        city_id: number
        title: string
        function_name: string
        condition_1?: number
        condition_2?: number
        condition_3?: number
        condition_4?: number
        condition_5?: number
        begin: string | null
        end?: string | null
        group_id?: string | null
        employer_id?: string
      };
      export type Resp = DoerTariff;
    }
    export namespace DELETE {
      export const URL = '/v1/doer_tariffs/:doerTariffId' as const
      export type Resp = any;
    }

    export namespace Groups {
      export namespace GET {
        export const URL = '/v1/doer_tariff_groups' as const

        export type Req = {
          begin: string
          end: string
        }

        export type Resp = DoerTariffGroups[];
      }
    }

    export namespace MotivationStatistics {
      export const URL = '/v1/doer_motivation_statistics'

      export type Req = {
        begin: string
        end: string
        doer_tariff_group_id: string
      }

      export type Resp = DoerMotivation[]
    }

    export namespace Tasks {
      export namespace POST {
        export const URL = '/v1/doer_tariffs/:doerTariffId/tasks' as const
        export type Req = {
          task_id: string
          cost: number
          cost_1?: number
          cost_2?: number
          cost_3?: number
          cost_4?: number
          cost_5?: number
          car_class_id?: string
        };
        export type Resp = DoerTariffTask[];
      }
      export namespace PUT {
        export const URL = '/v1/doer_tariff_tasks/:taskId' as const
        export type Req = {
          doer_tariff_id?: string
          cost?: number | string
          cost_1?: number | string
          cost_2?: number | string
          cost_3?: number | string
          cost_4?: number | string
          cost_5?: number | string
          car_class_id?: string
        };
        export type Resp = DoerTariffTask[];
      }
      export namespace DELETE {
        export const URL = '/v1/doer_tariff_tasks/:taskId' as const
        export type Resp = any;
      }
    }

    export namespace ShiftPrices {
      export namespace POST {
        export const URL = '/v1/doer_tariffs/:doerTariffId/shift_prices' as const

        export type Req = {
          cost?: number
          cost_1?: number
          cost_2?: number
          cost_3?: number
          cost_4?: number
          cost_5?: number
        }

        export type Resp = DoerTariffCost[]
      }

      export namespace PUT {
        export const URL = '/v1/doer_tariff_shift_prices/:shiftCostId' as const

        export type Req = {
          cost?: number
          cost_1?: number
          cost_2?: number
          cost_3?: number
          cost_4?: number
          cost_5?: number
        }

        export type Resp = DoerTariffCost[]
      }

      export namespace DELETE {
        export const URL = '/v1/doer_tariff_shift_prices/:shiftCostId' as const

        export type Resp = any
      }
    }

    export namespace ShiftTypes {
      export namespace GET {
        export const URL = '/v1/doer_tariffs/:doerTariffId/shift_types' as const
        export type Resp = DoerTariffShiftType[];
      }
      export namespace POST {
        export const URL = '/v1/doer_tariffs/:doerTariffId/shift_types' as const
        export type Req = {
          shift_types_id: string
          cost: number
          cost_1?: number
          cost_2?: number
          cost_3?: number
          cost_4?: number
          cost_5?: number
        };
        export type Resp = DoerTariffShiftType[];
      }
      export namespace PUT {
        export const URL = '/v1/doer_tariff_shift_types/:shiftTypeId' as const
        export type Req = {
          doer_tariff_id?: string
          cost?: number | string
          cost_1?: number | string
          cost_2?: number | string
          cost_3?: number | string
          cost_4?: number | string
          cost_5?: number | string
        };
        export type Resp = DoerTariffShiftType[];
      }
      export namespace DELETE {
        export const URL = '/v1/doer_tariff_shift_types/:shiftTypeId' as const
        export type Resp = any;
      }
    }

    export namespace Functions {
      export namespace GET {
        export const URL = '/v1/doer_tariff_functions'
        export type Resp = DoerTariffFunction[];
      }
    }
    export namespace GetBillCalculations {
      export const URL = '/v1/bill_calculations' as const

      export type Params = {
        offset:number
        limit: number
      };

      export type Bill = {
        id: string
        name: string
        state: {
          id: string
          name: string
          created: string
        }
      };

      export type Resp = Bill[];
    }
    export namespace PostBillCalculations {
      export const URL = '/v1/bill_calculations' as const

      export type Req = {
        doer_tariff_group_id:string
        begin: string
        end: string
        motivation_ids?: string[]
      };

      export type Resp = any;
    }
    export namespace GetTariffSyncStateV2 {
      export const URL = '/v1/doers/procedures/bill_sync_state_v2' as const
      export type Req = any;
      export type Resp = BillSyncState;
    }
  }

  // fleets
  export type Fleet = {
    id: string
    name: string
    client_type_id: string
  };

  export type FleetCar = {
    id: string
    grz: string
    car_model_id: number
    fleet_id: string
    car_brand_id?: number
    car_brand_name?: string
    car_model_name?: string
    fleet_name?: string
  };

  export type FleetCarFilter = {
    fleet_id?: string
    grz?: string
    null_model?: any
  };

  export type FleetService = {
    id:string
    fleet_id: string
    city_id: number
    task_id: string
    task_title?:	string
    car_class_id?:	string
    title:	string
    price:	number
    begin:	string
    end?:	string
  };

  export type FleetServicesFilter = {
    fleet_id?: string
    city_id?: string
    begin?: string
    end?: string
  };

  export namespace Fleets {
    export namespace GET {
      export const URL = '/v1/fleets' as const
      export type Resp = Fleet[];
    }
    export namespace GetById {
      export const URL = '/v1/fleet_cars/:fleet_id' as const
      export type Resp = FleetCar;
    }
    export namespace GetCars {
      export const URL = '/v1/fleet_cars' as const
      export type Req = {
        offset?: number
        limit?: number
      } & FleetCarFilter;
      export type Resp = FleetCar[];
    }
    export namespace CreateFleetCar {
      export const URL = '/v1/fleet_cars' as const
      export type Req = {
        grz: string
        car_model_id: string
        fleet_id: string
      };
      export type Resp = FleetCar;
    }
    export namespace ChangeFleetCar {
      export const URL = '/v1/fleet_cars/:fleet_id' as const
      export type Req = {
        car_model_id?: string
        fleet_id?: string
      };
      export type Resp = FleetCar;
    }
    export namespace DeleteFleetCar {
      export const URL = '/v1/fleet_cars/:fleet_id' as const
      export type Resp = any;
    }
    export namespace GetServices {
      export const URL = '/v1/fleet_services' as const
      export type Req = {
        task_id?:string
      } & FleetServicesFilter
      export type Resp = FleetService[];
    }
    export namespace CreateFleetServices {
      export const URL = '/v1/fleet_services' as const
      export type Req = {
        fleet_id: string
        city_id: number
        task_id: string
        task_title?:	string
        car_class_id?:	string
        title:	string
        price:	number
        begin:	string
        end?:	string
      };
      export type Resp = FleetService;
    }
    export namespace ChangeFleetService {
      export const URL = '/v1/fleet_services/:fleet_service_id' as const
      export type Req = FleetService;
      export type Resp = FleetService;
    }
    export namespace DeleteFleetService {
      export const URL = '/v1/fleet_services/:fleet_service_id' as const
      export type Resp = any;
    }
  }

  //End of fleets

  export namespace YandexPro {
    export type Executor = {
      yandex_executor_id: string
      yandex_executor_name: string
      yandex_city: string
      yandex_vehicle_type: string
      yandex_executor_phone?: string
      doer_user_id?: string
      doer_name?: string
      doer_phone?: string
    };

    export type GetExecutor = {
      yandex_executor_id:	string
      yandex_executor_name:	string
      yandex_executor_phone?:	string
      doer_user_id?:	string
      doer_name?:	string
      doer_phone?:	string
    };

    export type Report = {
      id: string
      report_date: Date | string
      deployed?: string
      created: string
      unmatched_executors?: Executor[]
    };
    export namespace Reports {
      export namespace GET {
        export const URL = '/v1/yandexpro/reports' as const
        export type Resp = Report[];
      }
      export namespace POST {
        export const URL = '/v1/yandexpro/reports' as const
        export type Resp = any;

        export type ReqCreate = {
          // id: string;
          report_date?: Date | string
          deployed_at?: string
          created: string
          unmatched_executors?: Executor[]
          method: 'create'
        };
      }
      export namespace GetReportById {
        export const URL = '/v1/yandexpro/reports/:report_id' as const
        export type Resp = Report;
        export type Req = {}
      }
      export namespace DeployReport {
        export const URL =
          '/v1/yandexpro/reports/:report_id/actions/deploy' as const
        export type Resp = any;
      }
      export namespace Connect {
        export const URL =
          '/v1/yandexpro/executors/:executor_id/doer' as const
        export type Req = {
          doer_user_id: string
        }
        export type Resp = Executor
      }

      export namespace PUT {
        export const URL =
          '/v1/yandexpro/executors/:executor_id/doer' as const
        export type Req = {
          doer_user_id: string
        }
        export type Resp = Executor
      }

      export namespace Delete {
        export const URL =
          '/v1/yandexpro/executors/:executor_id' as const
        export type Req = {
          executor_id: string
        }
        export type Resp = any
      }
    }
    export namespace GETExecutors {
      export const URL =
        '/v1/yandexpro/executors' as const

      export type Params = {
        doer_user_id: string
        phone_number?:number
        name?: string
        offset?: number
        limit?: number
      }
      export type Resp = Executor[]
    }


    export type Placement = {
      yandex_placement_id: string
      yandex_placement_name: string
      location_id?: string
      location_name?: string
      yandex_city?: string
      city_id?: number
    }

    export namespace Placements {
      export namespace GET {
        export const URL = '/v1/yandexpro/placements' as const
        export type Req = {
          placement_id?: string
          query?: string
        }
        export type Resp = Placement[];
      }
    }


    export type PlacementInitial = {
      id: string
      location_id?: string
      name?: string
    }

    export namespace PlacementsInitial {
      export namespace GET {
        export const URL = '/v1/yandexpro/placements' as const
        export type Req = {
          location_id?: string
        }
        export type Resp = PlacementInitial[];
      }

      export namespace POST {
        export const URL = '/v1/yandexpro/placements' as const
        export type Req = {
          location_id?: string
          name?: string
        }
        export type Resp = PlacementInitial;
      }
    }

    export namespace DeleteExecutor {
      export const URL = '/v1/yandexpro/executors/:id' as const
      export type Req = {
        doer_user_id: string
      }
      export type Resp = any;
    }
  }

  export namespace YandexLavka {

    export type Placement = {
      created: string
      id: string
      location_id?: string
      name: string
    }

    export type UnmatchedExecutor = {
      yandex_executor_id: string
      yandex_executor_name: string
      doer_name?: string
      doer_phone?: string
      doer_user_id?: string
      isMatched?: boolean
    }

    export type UnmatchedPlacement = {
      city_id: number
      yandex_city: string
      yandex_placement_id: string
      yandex_placement_name: string
      isMatched?: boolean
      location_id?: string
      location_name?: string
    }

    export type Report = {
      id: string
      report_date: Date | string
      deployed?: string
      created: string
      unmatched_executors?: UnmatchedExecutor[]
      unmatched_placements?: UnmatchedPlacement[]
    };

    export namespace Reports {

      export namespace GET {
        export const URL = '/v1/yandex_lavka/reports' as const
        export type Resp = Report[];
      }

      export namespace GetById {
        export const URL = '/v1/yandex_lavka/reports/:id' as const
        export type Resp = Report;
      }
    }

    export namespace UnmatchedExecutors {
      export namespace PUT {
        export const URL = '/v1/yandex_lavka/executors/:id/doer' as const
        export type Resp = UnmatchedExecutor;
      }
    }


    export namespace UnmatchedPlacements {
      export namespace PUT {
        export const URL = '/v1/yandex_lavka/placements/:id' as const
        export type Resp = UnmatchedPlacement;
      }

      export namespace DELETE {
        export const URL = '/v1/yandex_lavka/placements/:id' as const
        export type Resp = UnmatchedPlacement;
      }
    }

    export namespace DeployReport {
      export const URL =
          '/v1/yandex_lavka/reports/:id/actions/deploy' as const
      export type Resp = any;
    }

    export namespace Placements {
      export namespace GET {
        export const URL = '/v1/yandex_lavka/placements' as const
        export type Req = {
          location_id?: string
        }
        export type Resp = Placement[];
      }

      export namespace POST {
        export const URL = '/v1/yandex_lavka/placements' as const
        export type Req = {
          location_id?: string
          name?: string
        }
        export type Resp = Placement;
      }

      export namespace DELETE {
        export const URL = '/v1/yandex_lavka/placements/:id' as const
        export type Resp = any;
      }
    }
  }

  export namespace Urent {

    export type Placement = {
      created: string
      id: string
      location: string
      location_id: string
      name: string
    }
    export type Executor = {
      id: string
      name: string
      phone?: string
      urent_executor_id: string // @deprecated
      urent_executor_name: string // @deprecated
      urent_city: string // @deprecated
      urent_executor_phone?: string // @deprecated
      doer_name?: string
      doer_phone?: string
      doer_user_id?: string
    }

    export type UnmatchedExecutor = Executor & {
      isMatched?: boolean
    }

    export type UnmatchedPlacement = {
      id: string
      name: string
      city_id: number // @deprecated
      yandex_city: string // @deprecated
      urent_placement_id: string // @deprecated
      urent_placement_name: string // @deprecated
      urent_city?: string // @deprecated
      isMatched?: boolean
      location_id?: string
      location_name?: string
    }

    export type Report = {
      id: string
      report_date: Date | string
      deployed?: string
      created: string
      unmatched_executors?: UnmatchedExecutor[] // @deprecated
      unmatched_placements?: UnmatchedPlacement[] // @deprecated
      unmatched_external_doers?: UnmatchedExecutor[]
      unmatched_external_locations?: UnmatchedPlacement[]
    };

    export namespace Reports {

      export namespace GET {
        export const URL = '/v1/urent/reports' as const
        export type Resp = Report[];
      }

      export namespace GetById {
        export const URL = '/v1/urent/reports/:id' as const
        export type Resp = Report;
      }

      export namespace POST {
        export const URL = '/v1/urent/reports' as const
        export type Resp = Report;
        export type Req = any;
      }
    }

    export namespace DeployReport {
      export const URL =
          '/v1/urent/reports/:id/actions/deploy' as const
      export type Resp = any;
    }

    export namespace UnmatchedExecutors {
      export namespace PUT {
        export const URL = '/v1/urent/executors/:id' as const
        export type Req = {
          doer_user_id: string
        }
        export type Resp = UnmatchedExecutor;
      }
    }

    export namespace Placements {
      export namespace GET {
        export const URL = '/v1/urent/placements' as const
        export type Req = {
          location_id?: string
        }
        export type Resp = Placement[];
      }

      export namespace POST {
        export const URL = '/v1/urent/placements' as const
        export type Req = {
          location_id?: string
          name?: string
        }
        export type Resp = Placement;
      }

      export namespace DELETE {
        export const URL = '/v1/urent/placements/:id' as const
        export type Resp = UnmatchedPlacement;
      }
    }


    export namespace UnmatchedPlacements {
      export namespace PUT {
        export const URL = '/v1/urent/placements/:id' as const
        export type Resp = UnmatchedPlacement;
      }
    }

    export namespace Executors {

      export namespace GET {

        export type Params = {
          doer_user_id: string
          phone_number?:number
          name?: string
          offset?: number
          limit?: number
        }
        export const URL = '/v1/urent/executors' as const
        export type Req = {
          location_id?: string
        }
        export type Resp = Executor[];
      }

      export namespace DELETE {
        export const URL = '/v1/urent/executors/:id' as const
        export type Req = {
          doer_user_id: string
        }
        export type Resp = any;
      }
    }
  }

  export namespace Magnit {
    export namespace Reports {

      export namespace GET {
        export const URL = '/v1/magnit/reports' as const
        export type Resp = APITYPES.Urent.Report[];
      }

      export namespace GetById {
        export const URL = '/v1/magnit/reports/:id' as const
        export type Resp = APITYPES.Urent.Report;
      }

      export namespace POST {
        export const URL = '/v1/magnit/reports' as const
        export type Resp = APITYPES.Urent.Report;
        export type Req = any;
      }
    }

    export namespace DeployReport {
      export const URL =
          '/v1/magnit/reports/:id/actions/deploy' as const
      export type Resp = any;
    }

    export namespace UnmatchedExecutors {
      export namespace PUT {
        export const URL = '/v1/magnit/external_doers/:id' as const
        export type Req = {
          doer_user_id: string
        }
        export type Resp = APITYPES.Urent.UnmatchedExecutor;
      }
    }

    export namespace Placements {
      export namespace GET {
        export const URL = '/v1/magnit/placements' as const
        export type Req = {
          location_id?: string
        }
        export type Resp = APITYPES.Urent.Placement[];
      }

      export namespace POST {
        export const URL = '/v1/magnit/placements' as const
        export type Req = {
          location_id?: string
          name?: string
        }
        export type Resp = APITYPES.Urent.Placement;
      }

      export namespace DELETE {
        export const URL = '/v1/magnit/placements/:id' as const
        export type Resp = APITYPES.Urent.UnmatchedPlacement;
      }
    }


    export namespace UnmatchedPlacements {
      export namespace PUT {
        export const URL = '/v1/magnit/external_locations/:id' as const
        export type Resp = APITYPES.Urent.UnmatchedPlacement;
      }
    }

    export namespace Executors {

      export namespace GET {

        export type Params = {
          doer_user_id: string
          phone_number?:number
          name?: string
          offset?: number
          limit?: number
        }
        export const URL = '/v1/magnit/executors' as const
        export type Req = {
          location_id?: string
        }
        export type Resp = APITYPES.Urent.Executor[];
      }

      export namespace DELETE {
        export const URL = '/v1/magnit/executors/:id' as const
        export type Req = {
          doer_user_id: string
        }
        export type Resp = any;
      }
    }
  }

  export namespace Internships {
    export enum STATE_TYPE {
      CONFIRMED = 'confirmed',
      STARTED = 'started',
      ENDED = 'ended',
      CANCELED = 'canceled',
      FAILED = 'failed',
    }
    export const mapStateToUrlStatus = {
      [STATE_TYPE.CONFIRMED]: 'confirm',
      [STATE_TYPE.STARTED]: 'start',
      [STATE_TYPE.ENDED]: 'end',
      [STATE_TYPE.CANCELED]: 'cancel',
      [STATE_TYPE.FAILED]: 'fail',
    }


    export type ReqCreate = {
      date?: string
      date_from?: string
      date_to?: string
      shift_type_id: string
      location_id: string
      roles: { id: string, places: number }[]
      method: 'create'
      is_intership?: true
      employer_id: string
      time?: string
    };

    export const defaultLimit = 15
    export type InternshipsFilter = {
      understaffed?: string
      search?: string
      role_id?: string
      date_from?: string
      date_to?: string
      city_id?: number
      location_id?: number
      offset?: number
      limit?: number
    }

    export type InternshipsLocation = {
      id: string
      name: string
      city_id: number
      description: string | null
      longitude: number
      latitude: null
    }
    export type InternshipsRole = {
      id: string
      name: string
    }

    export type InternshipsShiftType = {
      id: string
      type: string
      begin: string
      end: string
      overbooking: boolean
    }

    export type InternshipsShiftDoer = {
      doer_user_id: string
      doer_name: string
      phone_number: number
      telegram_tag: string
      state: STATE_TYPE
      doer_shift_id: string | null
      doer_shift_state: STATE_TYPE | null
    }

    export type InternshipsShift = {
      id: string
      location: string
      role_id: string
      places: number
    }
    export interface Internship {
      id: string
      location: InternshipsLocation
      role: InternshipsRole
      shift_type:InternshipsShiftType
      date: string
      is_intership: boolean
      location_id: string
      shift_type_id: string
      role_id: string
      places: number
      doers_counter: number
      load_level: string
      shifts: InternshipsShift[]
      doers: InternshipsShiftDoer[]
    }

    export namespace GET {
      export const URL = 'v1/offers?is_intership=true' as const
      export type Req = InternshipsFilter
      export type Resp = Internship[];
    }

    export namespace POST {
      export const URL = 'v1/shifts' as const
      export type Req = ReqCreate
      export type Resp = void;
    }

    export namespace CANCEL {
      export const URL = '/v1/offers/:offerId/actions/cancel' as const

      export type Req = {
        comment: string
      };

      export type Params = {
        offerId: string
      };

      export type Resp = Response;
    }

    export namespace PUTPlaces  {

      export const URL = '/v1/shifts/:shiftId' as const

      type Role = {
        id: string
        places: number
      }
      export type Req = {
        roles: Role[]
      };

      export type Params = {
        shiftId: string
      };

      export type Resp = Response;
    }


  }

  export namespace Reserves {
    export enum RESERVE_STATE {
      BOOKED = 'booked',
      CANCELED = 'canceled',
      CONFIRMED = 'confirmed',
    }

    export namespace POST {
      export type Req = {
        doer_user_id?: string
        offer_id: string
      }
      export type Resp = unknown
      export const URL = '/v1/reserves' as const
    }

  }
}
