import React, {
  FC,
  useEffect,
  useState,
} from 'react'
import {
  Modal,
  Steps,
} from 'antd'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { StepProps } from 'antd/lib/steps'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { MatchingUnmatchedExecutors } from './steps/MatchingExecutors/MatchingExecutors'
import { MatchingPlacements } from './steps/MatchingPlacements/MatchingPlacements'
import { FinalStep } from './steps/FinalStep/FinalStep'
import { UploadOperationReportFile } from './steps/UploadReportFile/UploadOperationReportFile'
import { getUrentReportById } from 'AC/urent/reports'
import {
  actionEmpty,
  Actions,
} from 'actions'

import styles from './styles.module.sass'
import { ShiftReportsType } from '../../index'
import { getMagnitReportById } from '../../../../../../AC/magnit/reports'


enum STEPS {
  UPLOADING_FILE = 0,
  MATCHING_EXECUTORS = 1,
  MATCHING_LOCATIONS = 2,
  PROCESSING = 3,
}


interface Props {
  type: ShiftReportsType
  reportId: string
  onCancel: () => void
}

interface Step {
  title: string
  component: React.ReactNode
}


const selector = (state: State) => ({
  report: state.urentReducer.uploadedReport,
  isLoading: state.requestsReducer.getUrentReportById === StatusRequest.LOADING,
})


export const MatchingModal: FC<Props> = ({
  type,
  reportId,
  onCancel,
}) => {
  const dispatch = useDispatch()
  const {
    report,
    isLoading,
  } = useSelector(selector)

  const [current, setCurrent] = useState<STEPS>(STEPS.UPLOADING_FILE)
  const changeStep = (step: STEPS) => {
    setCurrent(step)
  }

  const stepsMap: Record<STEPS, Step> = {
    [STEPS.UPLOADING_FILE]: {
      title: 'Загрузка файла',
      component: (
        <UploadOperationReportFile
          type={type}
          onSubmit={() => changeStep(STEPS.MATCHING_EXECUTORS)}
        />
      ),
    },
    [STEPS.MATCHING_EXECUTORS]: {
      title: 'Связка исполнителей',
      component: (
        <MatchingUnmatchedExecutors
          type={type}
          onSubmit={() => changeStep(STEPS.MATCHING_LOCATIONS)}
        />
      ),
    },
    [STEPS.MATCHING_LOCATIONS]: {
      title: 'Связка локаций',
      component: (
        <MatchingPlacements
          type={type}
          onSubmit={() => changeStep(STEPS.PROCESSING)}
        />
      ),
    },
    [STEPS.PROCESSING]: {
      title: 'Обработка',
      component: (
        <FinalStep
          type={type}
          onFinish={onCancel}
          reportId={reportId}
          // eslint-disable-next-line
        />
      ),
    },
  }

  const steps: StepProps[] = Object.values(stepsMap).map(({ title }) => ({ title }))

  useEffect(() => {
    if (!reportId) {
      return
    }
    switch (type) {
      case 'urent':
        dispatch(getUrentReportById(reportId))
        break
      case 'magnit':
        dispatch(getMagnitReportById(reportId))
        break
    }
  }, [reportId])

  useEffect(() => {
    const unmatchedExternalDoers = report?.unmatched_executors
      || report?.unmatched_external_doers
    const unmatchedExternalPlacements = report?.unmatched_placements
      || report?.unmatched_external_locations

    if (!report || !unmatchedExternalDoers || !unmatchedExternalPlacements || isLoading) {
      return
    }

    if (unmatchedExternalDoers.length === 0 && unmatchedExternalPlacements.length === 0) {
      changeStep(STEPS.PROCESSING)
    } else if (unmatchedExternalDoers.length === 0 && unmatchedExternalPlacements.length) {
      changeStep(STEPS.MATCHING_LOCATIONS)
    } else {
      changeStep(STEPS.MATCHING_EXECUTORS)
    }

    return () => {
      dispatch(actionEmpty(Actions.CLEAR_URENT_REPORT))
    }
  }, [isLoading])

  return (
    <Modal
      open
      title='Связка данных'
      onCancel={onCancel}
      destroyOnClose
      width={1200}
      footer={false}
    >

      {
        isLoading
          ? (
            <div className={styles.loaderWrapper}>
              <Loader
                circleIcon
                noPadding
                size='large'
              />
            </div>
          )
          : (
            <>
              <Steps
                current={current}
                items={steps}
              />
              { stepsMap[current].component }
            </>
          )
      }

    </Modal>
  )
}
