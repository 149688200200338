import React, { FC } from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { UploadFiles } from 'shared/ui/UploadFiles'
import { State } from 'stores/rootReducer'
import {
  addUrentReportFile,
  getUrentReportById,
} from 'AC/urent/reports'

import styles from './styles.module.sass'
import { ShiftReportsType } from '../../../../index'
import {
  addMagnitReportFile,
  getMagnitReportById,
} from '../../../../../../../../AC/magnit/reports'


interface Props {
  type: ShiftReportsType
  onSubmit: () => void
  reportStep?: () => void
  reportId?: string
}
const select = (state: State) => state.urentReducer.uploadedReport

export const UploadOperationReportFile: FC<Props> = ({ onSubmit, reportId, reportStep, type }) => {
  const report = useSelector(select)
  const dispatch = useDispatch()


  return (
    <div className={styles.wrapper}>
      <section className={styles.uploading}>
        <UploadFiles
          title='Загрузите файл'
          acceptingFiles='.xlsx'
          onFileUpload={
            (file) => {
              switch (type) {
                case 'urent':
                  dispatch(addUrentReportFile(file))
                  break
                case 'magnit':
                  dispatch(addMagnitReportFile(file))
                  break
              }
            }
          }
        />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!report}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
