import React, { FC } from 'react'
import { Divider, Dropdown, MenuProps, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'

import { APITYPES } from 'types/apitypes'
import { dateFormat, getFormattedDate, getShortTime } from 'utils/dateUtils'
import { getCityName } from 'utils/enumUtils'

import cls from './internships-item-header.module.sass'


const { Text } = Typography

type InternshipsItemHeaderPropsType = {
  internship: APITYPES.Internships.Internship
  onAddClick: () => void
  onCancelClick: () => void
  onEditClick: () => void
}
export const InternshipsItemHeader: FC<InternshipsItemHeaderPropsType> = (props) => {
  const { internship, onAddClick , onCancelClick, onEditClick } = props
  const isMaxPlaces = internship.doers_counter === internship.places

  const items: MenuProps['items'] = [
    {
      label: 'Добавить исполнителя',
      key: '0',
      icon: <PlusOutlined />,
      disabled: isMaxPlaces,
      onClick: onAddClick,
    },
    {
      label: 'Изменить количество мест',
      key: '1',
      icon: <EditOutlined />,
      onClick: onEditClick,
    },
    { type: 'divider' },
    {
      label: 'Удалить слот',
      key: '3',
      danger: true,
      icon: <DeleteOutlined />,
      onClick: onCancelClick,
    },
  ]

  return (
    <div className={cls.wrapper}>
      <div>
        <span>{ getShortTime(internship.shift_type.begin) + ' — ' + getShortTime(internship.shift_type.end) }</span>
        <Divider type='vertical' />
        <span>{ getFormattedDate(internship.date, dateFormat) }</span>
        <Divider type='vertical' />
        <Text
          style={{ width: 200 }}
          ellipsis={{ tooltip: internship.role.name }}
        >
          { internship.role.name }
        </Text>
        <Divider type='vertical' />
        <span>
          { getCityName(internship.location.city_id) }
          ,&nbsp;
        </span>
        <span>{ internship.location.name }</span>
      </div>
      <div className={cls.statusActions}>
        <span className={cls.statusText + ' ' + cls[internship.load_level]}>
          заполнено
          {  ' ' + internship.doers_counter }
          {  '/' + internship.places }
        </span>
        <div onClick={(e) => e.stopPropagation()} >
          <Dropdown
            menu={{ items }}
            trigger={['click']}
          >
            <MoreOutlined
              className={cls.moreIcon}
              width={40}
            />
          </Dropdown>
        </div>

      </div>

    </div>
  )
}