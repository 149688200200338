import { APITYPES } from 'types/apitypes'
import { getCityName } from '../../../../../utils/enumUtils'

import { getColumns } from './getColumns'


const motivationTypes = {
  penalty: 'Штраф',
  compensation: 'Бонус',
}

export const getRows =
  (motivations: APITYPES.Performers.GetDoerMotivations.Resp[]) => {
    const data = motivations.map((motivation) => {
      const row: any = {}

      getColumns().forEach((column) => {
        if (column.dataIndex === 'initiator_name') {
          row[column.dataIndex] = motivation?.initiator_name ?? ''
        } else if (column.dataIndex === 'status') { return } else if (column.dataIndex === 'comment') {
          row[column.dataIndex] = motivation.state.comment
        } else if (column.dataIndex === 'time'){
          row[column.dataIndex] = motivation.time
        } else if (column.dataIndex === 'city_id') {
          row[column.dataIndex] = getCityName(motivation.city_id)
        } else if (column.dataIndex === 'type') {
          row[column.dataIndex] = motivationTypes[motivation.type]
        } else {
          row[column.dataIndex] = motivation[column.dataIndex as keyof APITYPES.Performers.GetDoerMotivations.Resp]
        }
      })
      row.status = motivation
      row.key = motivation.id
      row.id = motivation.id
      row.motivationType = motivation.type

      return row
    })

      
    return data
  }
