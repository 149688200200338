import { Reducer } from 'redux'

import { Action, Actions } from '../actions'
import { APITYPES } from '../types/apitypes'


export type UrentState = {
  uploadedReport: APITYPES.Urent.Report | null
  reports: APITYPES.Urent.Report[] | null
  placements: APITYPES.Urent.Placement[] | null
  executors: APITYPES.Urent.Executor[] | null
}

const initState: UrentState = {
  uploadedReport: null,
  reports: null,
  placements: null,
  executors: null,
}

export const urentReducer: Reducer<UrentState, Action> = (state = initState, action): UrentState => {
  switch (action.type) {
    case Actions.SET_URENT_REPORT: {
      return {
        ...state,
        uploadedReport: action.data,
      }
    }
    case Actions.SET_URENT_REPORTS: {
      return {
        ...state,
        reports: action.data,
      }
    }

    case Actions.CLEAR_URENT_REPORT: {
      return {
        ...state,
        uploadedReport: null,
      }
    }

    case Actions.UPDATE_URENT_UNMATCHED_EXECUTOR: {
      if (!state.uploadedReport) {
        return state
      }

      const unmatched_external_doers = state.uploadedReport.unmatched_executors
        || state.uploadedReport.unmatched_external_doers || []
      const resultDoers = unmatched_external_doers.map((item) => {
        if (item.urent_executor_id === action.data.id || item.id === action.data.id) {
          return {
            ...item,
            isMatched: true,
          }
        }

        return item
      })

      return {
        ...state,
        uploadedReport: {
          ...state.uploadedReport,
          unmatched_executors: state.uploadedReport.unmatched_executors ? resultDoers : undefined,
          unmatched_external_doers: state.uploadedReport.unmatched_external_doers ? resultDoers : undefined,
        },
      }
    }

    case Actions.UPDATE_URENT_PLACEMENTS_MATCHED: {
      if (!state.uploadedReport) {
        return state
      }

      const unmatched_external_locations = state.uploadedReport.unmatched_placements
        || state.uploadedReport.unmatched_external_locations || []
      const resultLocations = unmatched_external_locations.map((item) => {
        if (item.urent_placement_id === action.data.id || item.id === action.data.id) {
          return {
            ...item,
            isMatched: true,
          }
        }

        return item
      })

      return {
        ...state,
        uploadedReport: {
          ...state.uploadedReport,
          unmatched_placements: state.uploadedReport.unmatched_placements ? resultLocations : undefined,
          unmatched_external_locations: state.uploadedReport.unmatched_external_locations ? resultLocations : undefined,
        },
      }
    }

    case Actions.DELETE_URENT_PLACEMENT: {
      return {
        ...state,
        placements: state.placements?.filter((item) => item.id !== action.data.id) || [],
      }
    }

    case Actions.SET_URENT_PLACEMENTS: {
      return {
        ...state,
        placements: action.data,
      }
    }

    case Actions.ADD_URENT_PLACEMENT: {
      return {
        ...state,
        placements: state.placements ? [...state.placements, action.data] : [action.data],
      }
    }

    case Actions.DELETE_URENT_EXECUTOR: {
      return {
        ...state,
        executors: state.executors?.filter((item) => item.urent_executor_id !== action.data.id) || [],
      }
    }

    case Actions.SET_URENT_EXECUTOR: {
      return {
        ...state,
        executors: action.data,
      }
    }

    case Actions.ADD_URENT_EXECUTOR: {
      return {
        ...state,
        executors: state.executors ? [...state.executors, action.data] : [action.data],
      }
    }

    default: {
      return state
    }
  }
}