import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Input, PageHeader, Popconfirm, Row, Select, Space, Tooltip } from 'antd'
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { deleteDoerTariff, editDoerTariff, fetchDoerTariffsGroups } from 'AC/doers/doerTariffs'
import { cityOptions } from 'utils/enumUtils'
import { searchSelect } from 'utils/formUtils'
import { backDateFormat } from '../../../../../../utils/dateUtils'

import { getFunction } from './utils'
import styles from './styles.module.sass'


const dateFormat = 'DD.MM.YYYY'

type Props = {
  tariff: APITYPES.DoerTariff.GetById.Resp
};
type M = moment.Moment;

const selector = (state: State) => ({
  roles: state.doersReducer.roles,
  employers: state.shiftsReducer.employers,
  functions: state.doerTariffsReducer.doerTariffFunctions,
  tariffGroups: state.doerTariffsReducer.doerTariffsGroups,

})

export const TariffHeader = ({ tariff }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { roles, functions , tariffGroups, employers } = useSelector(selector)

  const theFn = getFunction(functions, tariff.function_name)

  const [editMode, setEditMode] = useState(false)

  const [begin, setBegin] = useState<M | null>(tariff.begin ? moment(tariff.begin) : null)
  const [end, setEnd] = useState<M | null>(tariff.end ? moment(tariff.end) : null)

  const onTariffChange = (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => {
    const body = {
      ...tariff,
      ...partial,
      id: undefined,
      tasks: undefined,
      shift_types: undefined,
      employment_id: undefined,
    }

    if ('employer_id' in partial) {
      body.employer_id = partial.employer_id
    } else {
      body.employer_id = tariff.employer?.id
    }
    body.employer = undefined

    dispatch(editDoerTariff(tariff.id, body))
  }

  const onNameBlur = (newName: string) => {
    if (tariff.title !== newName) {
      onTariffChange({ title: newName })
    }
    setEditMode(false)
  }

  const onDeleteTariff = () => {
    dispatch(
      deleteDoerTariff(tariff.id, () => history.push(ROUTES.DOERS.TARIFFS.path))
    )
  }

  const onCalendarChange = (date: M | null, type: 'begin' | 'end') => {
    const formattedDate = date && date.format(backDateFormat)

    switch (type) {
      case 'begin':
        if (formattedDate) {
          setBegin(moment(formattedDate))
          onTariffChange({ begin: formattedDate })
        }
        break

      case 'end':
        setEnd(formattedDate ? moment(formattedDate) : null)
        onTariffChange({ end: formattedDate })
    }
  }

  useEffect(() => {
    if (tariffGroups?.length === 0) {
      dispatch(fetchDoerTariffsGroups())
    }
  }, [dispatch, tariffGroups])

  return (
    <PageHeader
      title={
        editMode ? (
          <Input
            style={{ width: '270px' }}
            size='small'
            defaultValue={tariff.title}
            onBlur={(e) => onNameBlur(e.target.value)}
          />
        ) : (
          tariff.title
        )
      }
      subTitle={
        <EditOutlined
          className={styles.edit}
          onClick={() => setEditMode(!editMode)}
        />
      }
      onBack={history.goBack}
      className={styles.header}
      extra={
        <Popconfirm
          title='Вы уверены, что хотите удалить этот тариф?'
          onConfirm={onDeleteTariff}
          okText='Да'
          cancelText='Нет'
          placement='bottomRight'
        >
          <Button
            danger
            type='primary'
          >
            Удалить
          </Button>
        </Popconfirm>
      }
    >
      <Row className={styles.filters}>
        <Space size={24}>
          <Col>
            <Space size={8}>
              Заказчик:
              <Select
                showSearch
                size='small'
                value={tariff?.employer?.id}
                placeholder='Выберите'
                className={styles.select}
                dropdownMatchSelectWidth={false}
                filterOption={searchSelect}
                onChange={(value) => onTariffChange({ employer_id: value })}
              >
                {
                  employers?.map((employer) => (
                    <Select.Option
                      key={employer.id}
                      value={employer.id}
                    >
                      { employer.name }
                    </Select.Option>
                  ))
                }
              </Select>
            </Space>
          </Col>

          <Col span={6} >
            <Space size={8}>
              Роль:
              <Select
                showSearch
                size='small'
                value={tariff.role_id}
                className={styles.select}
                dropdownMatchSelectWidth={false}
                filterOption={searchSelect}
                onChange={(value) => onTariffChange({ role_id: value })}
              >
                {
                  roles?.map((role) => (
                    <Select.Option
                      key={role.id}
                      value={role.id}
                    >
                      { role.name }
                    </Select.Option>
                  ))
                }
              </Select>
            </Space>
          </Col>

          <Col>
            <Space size={8}>
              Город:
              <Select
                size='small'
                value={tariff.city_id}
                dropdownMatchSelectWidth={false}
                onChange={(value) => onTariffChange({ city_id: value })}
                options={cityOptions}
              />
            </Space>
          </Col>
          <Col>
            <Space size={8}>
              <Space size={4}>Группа:</Space>
              <Select
                size='small'
                className={styles.groupSelect}
                value={tariff.group_id}
                onChange={(value) => onTariffChange({ group_id: value })}
              >
                {
                  tariffGroups?.map((g) => (
                    <Select.Option
                      className={styles.option}
                      key={g.id}
                      value={g.id}
                    >
                      { g.name }
                    </Select.Option>
                  ))
                }
              </Select>
            </Space>
          </Col>
        </Space>
        <Space size={24}>
          <Col>
            <Space size={8}>
              <Space size={4}>Метрика:</Space>
              <Select
                size='small'
                showSearch
                className={styles.select}
                value={tariff.function_name}
                onChange={(value) => onTariffChange({ function_name: value })}
                filterOption={searchSelect}
              >
                {
                  functions.map((fn) => (
                    <Select.Option
                      key={fn.name}
                      value={fn.name}
                    >
                      { fn.title }
                    </Select.Option>
                  ))
                }
              </Select>
              <Tooltip
                placement='bottom'
                title={theFn?.description}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          </Col>

          <Col>
            <Space size={8}>
              <Space size={4}>Период</Space>
              <Space size={4}>c:</Space>
              <DatePicker
                size='small'
                allowClear={false}
                format={dateFormat}
                value={begin}
                onChange={
                  (value) => {
                    onCalendarChange(value, 'begin')
                  }
                }
              />
              <Space size={4}>по:</Space>
              <DatePicker
                size='small'
                allowClear
                format={dateFormat}
                value={end}
                onChange={
                  (value) => {
                    onCalendarChange(value, 'end')
                  }
                }
              />
            </Space>
          </Col>
        </Space>
      </Row>
    </PageHeader>
  )
}
