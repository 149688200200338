import React, { useEffect, useState } from 'react'
import { PageHeader, Table } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { fetchOfferById, fetchOfferReserves } from 'AC/shifts/offers'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import { getCityName } from 'utils/enumUtils'

import { AssignShiftModal } from './AssignShiftModal'
import { getColumns, getRows, ModalType, OpenFcProps } from './utils'
import styles from './styles.module.sass'
import { ChangeReserveModal } from './ChangeReserveModal'


const selector = (state: State) => ({
  offer: state.offersReducer.offer,
  reserves: state.offersReducer.offerReserves,
  isReserveLoading: state.requestsReducer.fetchOfferReserves === StatusRequest.LOADING,
})

export const Offer = () => {
  const { offerId } = useParams<{ offerId: string }>()
  const dispatch = useDispatch()
  const { reserves, isReserveLoading, offer } = useSelector(selector)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchOfferReserves(offerId))
    dispatch(fetchOfferById(offerId))

    return () => {
      dispatch(action(Actions.SET_OFFERS_RESERVES, null))
    }
  }, [])

  const [modal, setModal] = useState<ModalType>({
    change: false,
    assign: false,
    reserve: null,
  })
  const openModal = ({ modal, reserve }: OpenFcProps) => {
    setModal((prev) => ({
      ...prev,
      [`${modal}`]: true,
      reserve,
    }))
  }
  const closeModal = () => setModal({
    change: false,
    assign: false,
    reserve: null,
  })

  return (
    <div>
      <PageHeader
        className={styles.header}
        title={offer?.shift_type_name}
        subTitle={offer ? `${getCityName(offer.location.city_id)}, ${offer.location.name}` : 'Загрузка...'}
        extra={moment(offer?.date).format('DD.MM.YYYY')}
        onBack={history.goBack}
      />
      <Table
        sticky
        className={styles.table}
        loading={isReserveLoading}
        columns={getColumns(openModal) as any}
        dataSource={getRows(reserves || [])}
        pagination={false}
        bordered
        scroll={{ y: getTableYScroll(208) }}
      />

      <AssignShiftModal
        isOpen={modal.assign}
        onClose={closeModal}
        reserve={modal.reserve}
        shifts={offer?.shifts || []}
        offerId={offerId}
      />

      <ChangeReserveModal
        isOpen={modal.change}
        onClose={closeModal}
        reserve={modal.reserve}
      />
    </div>
  )
}
