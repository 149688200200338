import { Modal } from 'antd';
import React, { FC } from 'react';

import { MatchingSteps } from './MatchingSteps';


export type Props = {
  isOpen: boolean
  onClose: () => void
  reportId?: string
  flag?: string
};

export const MatchingModal: FC<Props>   = ({ isOpen, onClose, reportId, flag }) => {
  return (
    <Modal
      title='Загрузка отчета Яндекс.ПРО'
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={1200}
      footer={false}
    >
      <MatchingSteps
        reportId={reportId}
        onFinish={onClose}
        flag={flag}
      />
    </Modal>
  )
}
