import { Table, Typography } from 'antd'
import React, { ComponentPropsWithoutRef, FC } from 'react'

import { PaymentsReport } from 'types/reports'


type Props = ComponentPropsWithoutRef<'div'> & {
  report: PaymentsReport
}

export const UnmatchedPaymentsView: FC<Props> = ({ report }) => {
  const columns = [
    {
      title: 'Имя исполнителя',
      dataIndex: 'executor_name',
      key: 'executor_name',
    },
    {
      title: 'Тип оплаты',
      dataIndex: 'payment_type',
      key: 'payment_type',
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
    },
  ]

  return (
    <>
      <Typography.Title level={5}>
        Платежи, которые не удалось загрузить:
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={report.unmatched_payments}
        rowKey='payment_id'
        pagination={false}
      />
    </>
  )
}
