import React, { FC, useEffect } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { required } from 'utils/formUtils'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { addDoer } from 'AC/doers/addDoer'
import { isOnlyLetters, isValidPhoneNumber } from 'utils/string-utils'


const { Option } = Select

const formLayout = { labelCol: { span: 6 } }

type Props = {
  isOpen: boolean
  onClose: () => void
  onSuccess?: (doer: APITYPES.Doer) => void
  shortForm?: boolean
  search?: string
  defaultState?: APITYPES.Doers.StateType
};

const stateTypes = [
  {
    id: 'new',
    value: 'Новый',
  },
  {
    id: 'confirmed',
    value: 'Подтвержден',
  },
]

const selector = (state: State) => ({ roles: state.doersReducer.roles })

export const AddDoerModal: FC<Props> = (props) => {
  const {
    isOpen,
    shortForm = false,
    search,
    defaultState,
    onSuccess,
    onClose,
  } = props

  const [form] = Form.useForm()
  const { roles } = useSelector(selector)
  const dispatch = useDispatch()

  const onFinish = (values: APITYPES.Doers.AddDoer) => {
    dispatch(addDoer(values, onSuccess))
  }

  useEffect(() => {
    const trimmedSearch = search?.trim() || ''
    const isSearchName = isOnlyLetters(trimmedSearch)
    const isPhoneNumber = isValidPhoneNumber(trimmedSearch)

    const fullName = isSearchName ? trimmedSearch : ''
    const phoneNumber = isPhoneNumber ? (+trimmedSearch) : undefined

    if (fullName) {
      form.setFieldsValue({ name: fullName })
    }
    if (phoneNumber) {
      form.setFieldsValue({ phone_number: phoneNumber })
    }
    if (defaultState) {
      form.setFieldsValue({ state: defaultState })
    }
  }, [])

  return (
    <Modal
      title='Добавить нового исполнителя'
      open={isOpen}
      onCancel={onClose}
      footer={false}
      afterClose={form.resetFields}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          label='ФИО'
          name='name'
          rules={required}
        >
          <Input placeholder='Введите исполнителя' />

        </Form.Item>
        <Form.Item
          label='Телефон'
          name='phone_number'
          rules={required}
        >
          <Input
            type='number'
            placeholder='Введите номер телефона'
          />
        </Form.Item>

        {
          !shortForm && (
            <>
              <Form.Item
                label='Статус'
                name='state'
              >
                <Select
                  showSearch
                  placeholder='Выберите статус'
                >
                  {
                    stateTypes?.map((type) => (
                      <Option
                        key={type.id}
                        value={type.id}
                      >
                        { type.value }
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>

              <Form.Item
                label='Роль'
                name='role_id'
              >
                <Select
                  allowClear
                  showSearch
                  placeholder='Выберите роль'
                  filterOption={
                    (input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    roles?.map((role) => (
                      <Option
                        key={role.id}
                        value={role.id}
                      >
                        { role.name }
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item
                label='AmoCRM ID'
                name='amo_lead_id'
                rules={required}
              >
                <Input />
              </Form.Item>
            </>
          )
        }
        <FormFooter
          okText='Добавить'
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
