import React, { FC, useState } from 'react'
import { SwapRightOutlined } from '@ant-design/icons'
import { Form, Input, Select, TimePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { FormFooter } from 'components/FormFooter'
import { addShiftType } from 'AC/shifts/shifts'
import { hourMinuteFormat } from 'utils/dateUtils'
import { MomentType } from 'types'
import { cityOptions } from 'utils/enumUtils'
import { filterSelectionOptions } from 'utils/filterUtils'
import { required } from 'utils/formUtils'
import { getRolesOptions } from 'utils/select-options'

import cls from './styles.module.sass'


type ShiftTypeRole = APITYPES.ShiftTypeRole

export type ShiftTypeFormProps = {
  onClose: () => void
};

const formItemLayout = {
  labelCol: { sm: { span: 4 } },
  wrapperCol: { sm: { span: 24 } },
}

type FormValues = {
  name: string
  city_id: number
  role_id: string
  from: MomentType
  to: MomentType
}
const selector = (state: State) => ({ roles: state.doersReducer.roles })

export const ShiftTypeForm: FC<ShiftTypeFormProps> = ({ onClose }) => {
  const { roles } = useSelector(selector)

  const dispatch = useDispatch()
  const [form] = Form.useForm<FormValues>()

  const [isSelected, setIsSelected] = useState(true)
  const [rolesByCity, setRolesByCity] = useState<ShiftTypeRole[]| null>(null)

  const handleSelectLocationChange = (id: number) => {
    if (roles?.length && id) {
      const filteredRoles = roles.filter((role) => {
        return role.city_id ? role.city_id === id : true
      })

      setRolesByCity(filteredRoles)
      setIsSelected(false)
    } else {
      setIsSelected(true)
    }
  }

  const onFinish = (values: FormValues) => {
    dispatch(addShiftType({
      name: values.name,
      city_id: +values.city_id,
      begin: moment(values.from).format('LT'),
      end: moment(values.to).format('LT'),
      roles: [{ id: values.role_id }],
    }))
    onClose()
  }

  const onTimeSelect = (time: MomentType, name: string) =>
    form.setFields([
      {
        name,
        value: time,
      },
    ])

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name='name'
        label='Тип'
        rules={required}
      >
        <Input placeholder='Введите тип смены' />
      </Form.Item>
      <Form.Item
        rules={required}
        name='city_id'
        label='Город'
      >

        <Select
          placeholder='Выберите город'
          options={cityOptions}
          allowClear
          filterOption={filterSelectionOptions}
          onChange={(e: number) => handleSelectLocationChange(e)}
        />
      </Form.Item>

      <Form.Item
        name='role_id'
        label='Роль'
        rules={required}
      >
        <Select
          allowClear
          showSearch
          disabled={isSelected}
          placeholder='Выберите роль'
          options={getRolesOptions(rolesByCity)}
          filterOption={filterSelectionOptions}
        />
      </Form.Item>

      <Form.Item
        label='Расписание'
        labelCol={{ span: 6 }}
      >
        <div className={cls.selectSchedule}>
          <Form.Item
            name='from'
            noStyle
            rules={
              [
                {
                  required: true,
                  message: 'Заполните время начала смены',
                },
              ]
            }
          >
            <TimePicker
              minuteStep={5}
              showNow={false}
              format={hourMinuteFormat}
              popupClassName={cls.timePopUp}
              onSelect={(time) => onTimeSelect(time, 'from')}
            />
          </Form.Item>

          <SwapRightOutlined className={cls.nextIcon} />

          <Form.Item
            name='to'
            noStyle
            rules={
              [
                {
                  required: true,
                  message: 'Заполните время конца смены',
                },
              ]
            }
          >
            <TimePicker
              minuteStep={5}
              showNow={false}
              format={hourMinuteFormat}
              popupClassName={cls.timePopUp}
              onSelect={(time) => onTimeSelect(time, 'to')}
            />
          </Form.Item>
        </div>

      </Form.Item>

      <FormFooter onCancel={onClose} />
    </Form>
  )
}
