import moment from 'moment'
import { APITYPES } from 'types/apitypes'
import { getCityName } from 'utils/enumUtils';


const Locations = APITYPES.ShiftLocationsTypes;

const timeFormat = 'HH:mm'
const dateFormat = 'DD.MM.YYYY'

export const getScheduleRange = (shift: APITYPES.Shift) => {
  return `${moment(shift.begin).format(timeFormat)} - ${moment(shift.end).format(timeFormat)}`
}

export const getDate = (shift: APITYPES.Shift) => {
  return moment(shift.date).format(dateFormat)
}

export const getShiftLocation = (shift: APITYPES.Shift) => {
  //@ts-ignore
  return `${getCityName(shift.location.city_id)}, ${Locations[shift.location.type]}, ${shift.location.name}`
}

export const getBookedTagsData = (shift: APITYPES.Shift) => {
  return shift.roles.map(role => ({
    key: shift.id,
    name: role.name,
    data: `${role.booked_places} / ${role.places}`
  }))
}