import React, { useCallback, useRef } from 'react'
import { Button, DatePicker, Drawer, Form, Select } from 'antd'

import { APITYPES } from 'types/apitypes'
import { useShiftsInit } from '../../hooks/useShiftsInit'
import { useFilterDrawerContext, useShiftsFilterContext } from '../../context'
import { cityOptions, getCityName } from 'utils/enumUtils'


const dateFormat = 'DD.MM.YYYY'
//@ts-ignore

export const ShiftsFilter = () => {
  const { locations, roles, shiftTypes, employers } = useShiftsInit()
  const { toggleFilterDrawer, filterDrawer } = useFilterDrawerContext()

  const {
    dates,
    shiftsFilter,
    onCityFilter,
    onShiftTypeFilter,
    onLocationChange,
    onRolesChange,
    dropFilters,
    onDateChange,
    onPredefinedDate,
    onEmployerChange,
  } = useShiftsFilterContext()

  const rangeRef = useRef<any>(null)

  const onDateButton = useCallback((type?: 'today' | 'endOfWeek' | 'endOfMonth') => {
    onPredefinedDate(type)
    if (rangeRef.current) {
      rangeRef.current.blur()
    }
  }, [])

  return (
    <Drawer
      title='Фильтры'
      placement='right'
      visible={filterDrawer}
      onClose={toggleFilterDrawer}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Период'>
          <DatePicker.RangePicker
            ref={rangeRef}
            allowClear={false}
            value={dates}
            format={dateFormat}
            //@ts-ignore
            onChange={onDateChange}
            renderExtraFooter={
              () => (
                <div style={
                  {
                    display: 'flex',
                    justifyContent: 'space-around',
                  }
                }
                >
                  <Button
                    type='link'
                    onClick={() => onDateButton('endOfWeek')}
                  >
                    До конца недели
                  </Button>
                  <Button
                    type='link'
                    onClick={() => onDateButton('today')}
                  >
                    Сегодня
                  </Button>
                  <Button
                    type='link'
                    onClick={() => onDateButton('endOfMonth')}
                  >
                    До конца месяца
                  </Button>
                </div>
              )
            }
          />
        </Form.Item>

        <Form.Item label='Заказчик'>
          <Select
            placeholder='Заказчик'
            allowClear
            showSearch
            onChange={onEmployerChange}
            value={shiftsFilter.employer_id ? shiftsFilter.employer_id : null}
            dropdownMatchSelectWidth={false}
            filterOption={
              (input, option) =>
                //@ts-ignore
                (option!.children.toLowerCase() as unknown as string).includes(input.toLowerCase())
            }
          >
            {
              employers.map((employer) => (
                <Select.Option
                  key={employer.id}
                  value={employer.id}
                >
                  { employer.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Город'>
          <Select
            placeholder='Город'
            allowClear
            onChange={onCityFilter}
            value={shiftsFilter.city_id ? +shiftsFilter.city_id : null}
            options={cityOptions}
          />
        </Form.Item>

        <Form.Item label='Тип смены'>
          <Select
            placeholder='Тип смены'
            allowClear
            showSearch
            onChange={onShiftTypeFilter}
            value={shiftsFilter.shift_type_id ? shiftsFilter.shift_type_id : null}
            dropdownMatchSelectWidth={false}
            filterOption={
              (input, option) =>
                //@ts-ignore
                (option!.children.toLowerCase() as unknown as string).includes(input.toLowerCase())
            }
          >
            {
              shiftTypes.map((type: APITYPES.ShiftType) => (
                <Select.Option
                  key={type.id}
                  value={type.id}
                >
                  { type.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Локации'>
          <Select
            placeholder='Локации'
            allowClear
            showSearch
            onChange={onLocationChange}
            value={shiftsFilter.location_id ? shiftsFilter.location_id : null}
            dropdownMatchSelectWidth={false}
            filterOption={
              (input, option) =>
                //@ts-ignore
                (option!.children.toLowerCase() as unknown as string).includes(input.toLowerCase())
            }
          >
            {
              locations.map((location: APITYPES.Location) => (
                <Select.Option
                  key={location.id}
                  value={location.id}
                >
                  { `${getCityName(location.city_id)}, ${location.name}` }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Роли'>
          <Select
            placeholder='Роли'
            allowClear
            showSearch
            onChange={onRolesChange}
            value={shiftsFilter.role_id ? shiftsFilter.role_id : null}
            dropdownMatchSelectWidth={false}
            filterOption={
              (input, option) =>
                //@ts-ignore
                (option!.children.toLowerCase() as unknown as string).includes(input.toLowerCase())
            }
          >
            {
              roles && roles.map((role: APITYPES.Role) => (
                <Select.Option
                  key={role.id}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={dropFilters}>Сбросить фильтры</Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
