import { Button, PageHeader, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getYandexProReports } from 'AC/yandexpro/reports'
import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import { MatchingModal } from './Components/MatchingModal/MatchingModal'

import styles from './styles.module.sass'
import { getColumns, getRows } from './utils'


const selector = (state: State) => ({
  reports: state.yandexProReducer.reports,
  isLoading:
    state.requestsReducer.getYandexProReports === StatusRequest.LOADING,
})

export const YandexProReports = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [connectReportId, setConnectReportId] = useState('')
  const [reportFlag, setReportFlag] = useState<string>()
  const { reports, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const toggleUploadModal = () => setIsModalOpen((prevState) => !prevState)

  useEffect(() => {
    if (!reports.length) {
      dispatch(getYandexProReports())
    }
  }, [reports])

  const openConnectModal = (id: string) => {
    setConnectReportId(id)
  }

  const closeUploadModal = () => {
    setIsModalOpen(false)
    setReportFlag(undefined)
  }

  const closeConnectModal = () => {
    setConnectReportId('')
    dispatch(getYandexProReports())
  }

  return (
    <>
      <div className={styles.container}>
        <PageHeader
          title='Отчеты об операциях Яндекс Про'
          extra={
            [
              <Button
                key='default'
                type='primary'
                onClick={toggleUploadModal}
              >
                Загрузить отчет
              </Button>,
              <Button
                key='franchise'
                type='primary'
                onClick={
                  () => {
                    setReportFlag('franchise')
                    toggleUploadModal()
                  }
                }
              >
                Загрузить отчет Франшиз
              </Button>,
            ]
          }
        />
        <Table
          sticky
          size='small'
          loading={isLoading}
          columns={getColumns() as any}
          dataSource={getRows(reports)}
          pagination={false}
          bordered
          scroll={{ y: getTableYScroll(162) }}
          style={{ cursor: 'pointer' }}
          onRow={
            (record: any) => ({ onClick: () => openConnectModal(record.id) })
          }
        />
      </div>

      <MatchingModal
        isOpen={isModalOpen}
        onClose={closeUploadModal}
        flag={reportFlag}
      />

      <MatchingModal
        isOpen={!!connectReportId}
        reportId={connectReportId}
        onClose={closeConnectModal}
      />
    </>
  )
}
