import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios'
import qs from 'qs'

import {
  getLocalStorageItem,
  LocalStorageKeys,
  removeLocalStorageItems,
} from 'utils/localStorageUtils'
import { showErrorNotification } from '../utils/notification'

import { ReplaceUrl } from './http'


type AppAxiosRequestConfig = AxiosRequestConfig & {
  replaceUrl?: ReplaceUrl
  needFullAxiosResp?: boolean
  showErrorMessage?: boolean
}

function filterNonNull(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => {
    return !isNaN(v as any) || v && (v as string).length
  }))
}

export class ApiClass {
  headers: Record<string, string> = {}

  // eslint-disable-next-line no-useless-constructor
  constructor(private axios: AxiosInstance) { }

  setAuth(header: string) {
    this.headers.Authorization = header
  }

  request(options: AppAxiosRequestConfig) {
    this.headers.Authorization = `Bearer ${getLocalStorageItem(LocalStorageKeys.TOKEN)}`
    const { needFullAxiosResp, replaceUrl, showErrorMessage } = options
    let { url } = options

    if (url && replaceUrl) {
      const splittedUrl = url.split('/')

      url = splittedUrl
        .map((part) => {
          if (part.startsWith(':')) {
            const key = part.slice(1)

            return replaceUrl[key] ? String(replaceUrl[key]) : part
          }

          return part
        })
        .join('/')
    }

    return this.axios.request({
      ...options,
      url,
      headers: {
        ...this.headers,
        ...options.headers,
      },
      params: filterNonNull({ ...options.params }),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    }).then((resp) => {
      if (needFullAxiosResp) {
        return resp
      }

      return resp.data
    })
      .catch((error) => {
        const aError = error as AxiosError
        const eStatus = aError.response?.status

        const isAuthError = eStatus === 401
        const isAccessError = eStatus === 403

        if (isAuthError) {
          removeLocalStorageItems([LocalStorageKeys.TOKEN])

          location.reload()
        }

        if (isAccessError) {
          showErrorNotification('Доступ запрещен')
        }

        if (showErrorMessage) {
          showErrorNotification(aError.response?.data)
        }

        return Promise.reject(error)
      })
  }

  get(url: string, options?: AppAxiosRequestConfig) {
    return this.request({
      ...options,
      method: 'GET',
      url,
    })
  }

  post(url: string, data: any, options?: AppAxiosRequestConfig) {
    return this.request({
      ...options,
      method: 'POST',
      data: { ...data },
      url,
    })
  }

  put(url: string, data: any, options?: AppAxiosRequestConfig) {
    return this.request({
      ...options,
      method: 'PUT',
      data,
      url,
    })
  }

  delete(url: string, options?: AppAxiosRequestConfig) {
    return this.request({
      ...options,
      method: 'DELETE',
      url,
    })
  }

  options(url: string, options?: AppAxiosRequestConfig) {
    return this.request({
      ...options,
      method: 'OPTIONS',
      url,
    })
  }
}
