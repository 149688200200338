import React from 'react'
import moment from 'moment'
import { Tag } from 'antd'
import { APITYPES } from 'types/apitypes'
import { FilterRangePicker } from 'components/FilterRangePicker';
import { getCityName, getEntries } from 'utils/enumUtils'
import { FilterDropdownProps } from 'antd/es/table/interface';

const { ShiftLocationsTypes, OfferDoerStatusesTrans, OfferDoerStatusesColors } = APITYPES
const offerStateOptions = getEntries(OfferDoerStatusesTrans)

export const getColumns = () => {
  return [
    {
      title: 'Тип смены',
      dataIndex: 'shift_type',
      key: 'shift_type',
    },
    {
      title: 'Локация',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      width: 145,
      filters: offerStateOptions.map(option => ({text: option[1], value: option[0]})),
      //@ts-ignore
      render: (state: string) => <Tag color={OfferDoerStatusesColors[state]}>{OfferDoerStatusesTrans[state]}</Tag>
    },
    {
      title: 'Расписание',
      dataIndex: 'schedule',
      key: 'schedule',
      align: 'center',
      filterDropdown: (props: FilterDropdownProps) => <FilterRangePicker dropdownProps={props} />
    },
  ]
}

export const getRows = (reserves: APITYPES.DoerReserve[]) => {
  return reserves.map((reserve) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'shift_type') {
        row[column.dataIndex] = reserve.shift_type.type
      } else if (column.dataIndex === 'location') {
        row[column.dataIndex] = //@ts-ignore
          `${getCityName(reserve.shift_type.city_id)}, ${reserve.location.name} (${ShiftLocationsTypes[reserve.location.type]})`
      } else if (column.dataIndex === 'role') {
        row[column.dataIndex] = reserve.role.name
      } else if (column.dataIndex === 'schedule') {
        const date = moment(reserve.date).format('L')
        const begin = reserve.shift_type.begin.slice(0, -3)
        const end = reserve.shift_type.end.slice(0, -3)
        row[column.dataIndex] = `${date}, ${begin} - ${end}`
      } else {
        //@ts-ignore
        row[column.dataIndex] = reserve[column.dataIndex]
      }
    })
    row.key = reserve.id
    row.offer_id = reserve.offer_id

    return row
  })
}
