import { APITYPES } from 'types/apitypes'

import { getColumns } from './getColumns'


export const getRows = (monitorings: APITYPES.DoerMonitoring[]) => {
  return monitorings.map((item) => {
    const row: any = {}

    getColumns().forEach((column) => {
      if (column.map) {
        row[column.dataIndex] = column.map(item)

        return
      }

      row[column.dataIndex] = item[column.dataIndex as keyof APITYPES.DoerMonitoring]
    })
    row.key = item.doer.doer_user_id

    return row
  })
}
