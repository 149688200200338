import React, { useState } from 'react'
import {
  Button,
  Table,
} from 'antd'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

import styles from '../YandexProReports/styles.module.sass'
import { UploadReportFileModal } from './components/UploadReportFileModal/UploadReportFileModal'
import { getYandexEdaReports } from '../../../../AC/yandexEda/reports'
import { getTableYScroll } from '../../../../utils/scrollUtils'
import { dateFormat } from '../../../../utils/dateUtils'


export const YandexEda = () => {
  const [isUploadReportFileModalOpen, setIsUploadReportFileModalOpen] = useState(false)
  const { isLoading, data } = useQuery(
    [getYandexEdaReports.name],
    () => getYandexEdaReports(),
  )

  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={() => setIsUploadReportFileModalOpen(true)}
          >
            Загрузить отчет
          </Button>
        </div>
      </div>
      <Table
        bordered
        sticky
        size='small'
        pagination={false}
        loading={isLoading}
        columns={
          [
            {
              title: 'Загружен',
              dataIndex: 'upload_date',
              key: 'upload_date',
              render: (date: string) => moment(date).format(dateFormat),
            },
            {
              title: 'Количество записей',
              dataIndex: 'records_count',
              key: 'records_count',
            },
          ]
        }
        scroll={{ y: getTableYScroll(162) }}
        dataSource={data} // TODO: KZ: add common mapper for table row keys
      />

      <UploadReportFileModal
        isOpen={isUploadReportFileModalOpen}
        onClose={() => setIsUploadReportFileModalOpen(false)}
      />
    </>
  )
}
