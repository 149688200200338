import { RcFile } from 'antd/lib/upload'
import React, { ComponentPropsWithoutRef, FC } from 'react'

import { UploadFiles } from 'shared/ui/UploadFiles'

import styles from './styles.module.sass'


type Props = ComponentPropsWithoutRef<'div'> & {
  isUploading: boolean
  onUpload: (file: RcFile) => unknown
}

export const UploadingView: FC<Props> = ({ isUploading, onUpload }) => {
  return (
    <section className={styles.uploading}>
      <UploadFiles
        isLoading={isUploading}
        title='Выберите или перетащите файл'
        acceptingFiles='.xlsx'
        fileList={[]}
        onFileUpload={onUpload}
      />
    </section>
  )
}
