import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from 'antd'
import {
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import { required } from 'utils/formUtils'
import {
  connectAmoCrmLeadAndDoer,
  getConnectionAmoCrmLeadAndDoer,
} from 'AC/yandexpro/reports'
import { APITYPES } from 'types/apitypes'
import { showSuccessNotification } from '../../../../../../utils/notification'

import styles from './styles.module.sass'
import { queryClient } from '../../../../../../index'


type Props = {
  doer: APITYPES.Doer
};

type FormData = APITYPES.Doers.AmoCrmConnection.ConnectAmoCrmExecutorAndDoer.Params;

const YandexPro = ({ doer }: Props) => {
  const [form] = Form.useForm<FormData>()
  const {
    isLoading,
    data: amoLeadData,
  } = useQuery(
    [getConnectionAmoCrmLeadAndDoer.name, doer.id],
    () => getConnectionAmoCrmLeadAndDoer(doer.id),
  )
  const {
    isLoading: isSaving,
    mutate: connect,
  } = useMutation((data: FormData) =>
    connectAmoCrmLeadAndDoer(doer.id, data),
  {
    onSuccess: () => {
      showSuccessNotification()
      form.resetFields()
      queryClient.refetchQueries([getConnectionAmoCrmLeadAndDoer.name])
    },
  })

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={12}
        xl={6}
      >
        <Card
          title='Привязка к ID исполнителя в AmoCRM '
          size='small'
          loading={isLoading}
          className={styles.card}
        >
          <Form
            form={form}
            onFinish={(data) => connect(data)}
            className={styles.form}
          >
            <Form.Item
              label='Задать ID'
              name='amo_lead_id'
              rules={required}
              className={styles.formItem}
            >
              <Input />
            </Form.Item>
            <Button
              loading={isSaving}
              type='primary'
              htmlType='submit'
            >
              Связать
            </Button>
          </Form>
        </Card>
      </Col>
      <Col
        span={12}
        xl={6}
      >
        <Card
          title='Текущий AmoCRM ID'
          size='small'
          loading={isLoading}
          className={styles.card}
        >
          <Typography.Text>
            { amoLeadData?.amo_crm_lead_id || 'Не установлен' }
          </Typography.Text>
        </Card>
      </Col>
    </Row>
  )
}

export default YandexPro
