import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FC } from 'react'


type Props = {
  onClick: (e: any) => void
  className?: string
  size?: 'large' | 'middle' | 'small'
  icon?: boolean
  disabled?: boolean
}

export const DeleteButton: FC<Props> = (props) => {
  const { onClick, className, size, icon = false , children, disabled } = props

  return (
    <Button
      type='primary'
      className={className}
      icon={icon && <DeleteOutlined />}
      onClick={onClick}
      disabled={disabled}
      size={size ?? 'middle'}
      style={
        {
          background: '#FF6347',
          border: 'none',
        }
      }
    >
      {
        children ? children : 'Удалить'
      }
    </Button>
  )
}