import React from 'react'
import {
  Form,
  Input,
  Select,
} from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { updateCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import {
  AREAS,
  DISTRICTS,
  SUBWAYS,
} from '../../config'
import { internalCitiesSelectOptions } from '../../../../../../utils/enumUtils'

import styles from './styles.module.sass'


type Props = {
  carwash: APITYPES.Carwashes.GetCarwash.Resp
}

export const Location = (props: Props) => {
  const dispatch = useDispatch()

  const [form] = useForm()

  const { carwash } = props

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const onFinish = async (values: any) => {
    dispatch(updateCarwash({
      location: {
        region: values.region || null,
        area: values.area || null,
        district: values.district || null,
        address: values.address || null,
        subway: values.subway || null,
        directions: values.directions || null,
        longitude: String(values.longitude),
        latitude: String(values.latitude),
      },
    }, carwash.id))
  }

  const onBlur = () => {
    onFinish(form.getFieldsValue())
  }

  return (
    <Form
      className={styles.form}
      form={form}
      name='location'
      {...formItemLayout}
      onFinish={onFinish}
      onBlur={onBlur}
    >
      <Form.Item
        className={styles.filed}
        name='region'
        label='Регион'
        initialValue={carwash.location?.region}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Select
          showSearch
          options={internalCitiesSelectOptions}
        >
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='area'
        label='Округ'
        initialValue={carwash.location?.area}
      >
        <Select showSearch>
          {
            AREAS.map((area, i)=>{
              return (
                <Select.Option
                  key={i}
                  value={area}
                >
                  { area }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='district'
        label='Район'
        initialValue={carwash.location?.district}
      >
        <Select showSearch>
          {
            DISTRICTS.map((item, i)=>{
              return (
                <Select.Option
                  key={i}
                  value={item}
                >
                  { item }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='address'
        label='Адрес'
        initialValue={carwash.location?.address}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='subway'
        label='Метро'
        initialValue={carwash.location?.subway}
      >
        <Select showSearch>
          {
            SUBWAYS.map((item, i)=>{
              return (
                <Select.Option
                  key={i}
                  value={item}
                >
                  { item }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='directions'
        label='Как проехать'
        initialValue={carwash.location?.directions}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='latitude'
        label='Широта'
        initialValue={parseFloat(String(carwash.location?.latitude))}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input
          type='number'
          step={0.000001}
        />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='longitude'
        label='Долгота'
        initialValue={parseFloat(String(carwash.location?.longitude))}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input
          type='number'
          step={0.000001}
        />
      </Form.Item>
    </Form>
  )
}