// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--uploading--tdO3E{height:300px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/Bills/YandexProPayments/components/UploadingView/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,YAAA","sourcesContent":[".uploading\n  height: 300px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploading": "styles-module--uploading--tdO3E"
};
export default ___CSS_LOADER_EXPORT___;
