import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, PageHeader, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'

import {
  getYandexProPaymentsReport,
  getYandexProPaymentsReportsList,
  processYandexProPaymentsReport,
  uploadAndProcessYandexProPaymentsFile,
} from 'AC/yandexpro/payments'
import { successNotification } from 'components/modals/OperationNotification'
import { queryClient } from 'index'
import { PaymentsReport, PaymentsReportItem } from 'types/reports'
import { dateFormat } from 'utils/dateUtils'
import { FinalStepView } from './components/FinalStepView'
import { ReportModal } from './components/ReportModal'
import { UnmatchedPaymentsView } from './components/UnmatchedPaymentsView'
import { UploadingView } from './components/UploadingView'


type ViewType = 'fileUploading' | 'unmatchedPayments' | 'successful'
type ViewParams = {
  body: ReactElement
  extra?: ReactElement
}


const columns: ColumnsType<PaymentsReportItem> = [
  {
    title: 'Дата отчета',
    dataIndex: 'upload_date',
    key: 'upload_date',
    render: (date: string) => moment(date).format(dateFormat),
  },
  {
    title: 'Дата загрузки',
    dataIndex: 'processed',
    key: 'processed',
    render: (date: string) => moment(date).format(dateFormat),
  },
]


export const YandexProPayments = () => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState<string>()
  const getYandexProPaymentsReportKey = [
    getYandexProPaymentsReport.name,
    selectedReportId,
  ]

  const onSuccess = (report: PaymentsReport) => {
    if (!selectedReportId) {
      setSelectedReportId(report.id)
    }
    successNotification(report.message)
    queryClient.setQueryData(getYandexProPaymentsReportKey, report)
  }

  const {
    isLoading: isListLoading,
    data: reportsList,
    refetch: refetchList,
  } = useQuery(
    [getYandexProPaymentsReportsList.name],
    getYandexProPaymentsReportsList
  )

  const { isFetching: isReportLoading, data: report } = useQuery(
    getYandexProPaymentsReportKey,
    () => getYandexProPaymentsReport(selectedReportId!),
    { enabled: Boolean(selectedReportId) }
  )

  const { isLoading: isReportUploading, mutate: uploadReport } = useMutation(
    uploadAndProcessYandexProPaymentsFile,
    { onSuccess }
  )

  const { isLoading: isReportProcessing, mutate: processReport } = useMutation(
    processYandexProPaymentsReport,
    { onSuccess }
  )

  const getCurrentStep = (): ViewType => {
    if (!selectedReportId) {
      return 'fileUploading'
    }
    if (report?.unmatched_payments?.length) {
      return 'unmatchedPayments'
    }

    return 'successful'
  }

  const onModalClose = () => {
    setSelectedReportId(undefined)
    setIsReportModalOpen(false)
    refetchList()
  }

  const views: Record<ViewType, ViewParams> = {
    fileUploading: {
      body: (
        <UploadingView
          isUploading={isReportUploading}
          onUpload={uploadReport}
        />
      ),
    },
    unmatchedPayments: {
      body: (
        <UnmatchedPaymentsView
          report={report!}
        />
      ),
      extra: (
        <Button
          type='primary'
          loading={isReportProcessing}
          onClick={() => processReport(report!.id)}
        >
          Повторить попытку
        </Button>
      ),
    },
    successful: {
      body: (
        <FinalStepView
          processedAt={report?.processed}
        />
      ),
      extra: (
        <Button
          type='primary'
          onClick={onModalClose}
        >
          Закрыть
        </Button>
      ),
    },
  }

  return (
    <>
      <PageHeader
        title='Отчеты об оплате Яндекс Про'
        extra={
          [
            <Button
              key='upload'
              type='primary'
              onClick={() => setIsReportModalOpen(true)}
            >
              Загрузить отчет
            </Button>,
          ]
        }
      />
      <Table
        bordered
        sticky
        size='small'
        pagination={false}
        loading={isListLoading}
        rowKey='id'
        columns={columns}
        dataSource={reportsList}
        onRow={
          (item: PaymentsReportItem) => {
            return {
              onClick: () => {
                setSelectedReportId(item.id)
                setIsReportModalOpen(true)
              },
            }
          }
        }
      />

      <ReportModal
        title={selectedReportId ? 'Загруженный отчет' : 'Загрузка отчета'}
        isLoading={isReportLoading}
        isOpen={isReportModalOpen}
        onClose={onModalClose}
        extra={views[getCurrentStep()]?.extra}
      >
        { views[getCurrentStep()]?.body }
      </ReportModal>
    </>
  )
}
