import React, { useEffect, useRef } from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { fetchRoleGroup, fetchRoles } from 'AC/doers/doerRoles'
import { useDebouncedSearch } from 'utils/hooks/useDebouncedSearch'
import { ROUTES } from 'config/routes'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { filterEmptyValues } from 'utils/urlUtils'
import { cityOptions } from 'utils/enumUtils'
import { isObjectEmpty } from 'utils/filterUtils'


const departmentOptions = ['Кикшеринг', 'Каршеринг' ]

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  filter: state.doersReducer.rolesFilter,
  roleGroups: state.doersReducer.roleGroups,
})

export const Filters = ({
  isOpen,
  onClose,
}: Props) => {
  const dispatch = useDispatch()
  const { filter, roleGroups } = useSelector(selector)
  const queryParams = useQueryParams()
  const firstRun = useRef(true)
  const history = useHistory()

  const [name, setName, debouncedName] = useDebouncedSearch(
    { defaultValue: queryParams?.name ? queryParams.name : filter.name },
    () => onFilterChange('name', debouncedName),
  )

  const onFilterChange = (key: string, value: string | undefined | number) => {
    dispatch(action(Actions.SET_ROLES_FILTER, {
      ...filter,
      [key]: value,
    }))
  }

  const filterOption = (
    input: string,
    option?: { label: string, value: string | number }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const resetFilters = () => {
    if (isObjectEmpty(queryParams)) {
      return
    }
    if (name?.length) {
      setName('')
    }
    dispatch(action(Actions.SET_ROLES_FILTER, {}))
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false

      return
    }
    history.push(ROUTES.DOERS.ROLES.PARAMS.createPath(filter))
    const filterValues = filterEmptyValues(filter)

    dispatch(fetchRoles(filterValues))
  }, [filter])

  useEffect(() => {
    if (roleGroups?.length === 0) {
      dispatch(fetchRoleGroup())
    }
  }, [dispatch, roleGroups])

  return (
    <Drawer
      title='Фильтры'
      onClose={onClose}
      open={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Направление'>
          <Select
            allowClear
            placeholder='Введите название направления'
            value={filter.department}
            onChange={(value) => onFilterChange('department', value)}
          >
            {
              departmentOptions?.map((item) => (
                <Select.Option
                  key={item}
                  value={item}
                >
                  { item }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Группа'>
          <Select
            allowClear
            placeholder='Выберите группу'
            value={filter.group_id}
            onChange={(value) => onFilterChange('group_id', value)}
            filterOption={filterOption}
          >
            {
              roleGroups?.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  { item.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Город'>
          <Select
            allowClear
            placeholder='Выберите город'
            value={filter?.city_id ? +filter.city_id : undefined}
            onChange={(value) => onFilterChange('city_id', value)}
            options={cityOptions}
          />
        </Form.Item>

        <Form.Item label='Название'>
          <Input
            allowClear
            placeholder='Введите название роли'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
