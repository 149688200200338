import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from 'utils/dateUtils'
import { getCityName } from 'utils/enumUtils'


export const getColumns = () => ([
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: 'Город',
    dataIndex: 'city_id',
    key: 'city_id',
    align: 'center',
    width: 200,
    map: ({ city_id }: APITYPES.Role) => city_id === null ? 'Все города' : getCityName(city_id),
  },
  {
    title: 'Группа',
    dataIndex: 'group_name',
    key: 'group_name',
    align: 'center',
    width: 200,
  },
  {
    title: 'Направление',
    dataIndex: 'department',
    key: 'department',
    align: 'center',
    width: 200,
  },
  {
    title: 'Создана',
    dataIndex: 'created',
    key: 'created',
    align: 'center',
    width: 200,
    map: (role: APITYPES.Role) => getFormattedDate(role['created']),
  },
])

export const getRows = (roles: APITYPES.Role[]) => {
  return roles.map((role) => {
    const row: any = {}

    getColumns().forEach((column) => {
      row[column.key] = column.map ? column.map(role) : role[column.key as keyof APITYPES.Role]
    })
    row.role = role
    row.key = role.id

    return row
  })
}
