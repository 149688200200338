import React, { FC } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  Button,
  Typography,
} from 'antd'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { deployUrentReportById } from 'AC/urent/reports'
import { dateTimeFormat } from 'utils/dateUtils'

import styles from './styles.module.sass'
import { ShiftReportsType } from '../../../../index'
import { deployMagnitReportById } from '../../../../../../../../AC/magnit/reports'


interface FinalStepProps {
  type: ShiftReportsType
  reportId: string
  onFinish: () => void
}

const selector = (state: State) => ({
  isLoading:  state.requestsReducer.deployUrentReport === StatusRequest.LOADING,
  deployedTime: state.urentReducer.uploadedReport?.deployed,
  uploadedReportId: state.urentReducer.uploadedReport?.id,
})

export const FinalStep: FC<FinalStepProps> = ({ onFinish, reportId, type }) => {
  const dispatch = useDispatch()
  const { isLoading, deployedTime, uploadedReportId } = useSelector(selector)

  const onClickDeploy = () => {
    switch (type) {
      case 'urent':
        dispatch(deployUrentReportById((reportId || uploadedReportId as string), onFinish))
        break
      case 'magnit':
        dispatch(deployMagnitReportById((reportId || uploadedReportId as string), onFinish))
        break
    }
  }

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        {
          isLoading ? <Loader /> : (
            <>

              {
                deployedTime ? (
                  <>
                    <Typography.Text >
                      Отчёт успешно обработан —
                      { ' ' }
                      { moment(deployedTime).format(dateTimeFormat) }
                    </Typography.Text>
                  </>
                ) : (
                  <>
                    <Typography.Text >
                      Все данные успешно загружены
                    </Typography.Text>
                    <Typography.Paragraph >
                      Пожалуйста, нажмите на кнопку
                      { /* eslint-disable-next-line react/no-unescaped-entities */ }
                      <strong>"Обработать"</strong>
                      для завершения процесса
                    </Typography.Paragraph>
                  </>
                )
              }

            </>
          )
        }

      </section>

      <div className={styles.btnWrapper}>
        <Button
          disabled={!!deployedTime}
          onClick={onClickDeploy}
          type='primary'
        >
          Обработать
        </Button>
      </div>
    </div>
  )
}
