import React, { useState, useEffect } from 'react'
import { Button, DatePicker, Drawer, Form, Select } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { ROUTES } from 'config/routes'
import { getUrlSearchParams } from 'utils/urlUtils'
import { isObjectEmpty } from 'utils/filterUtils'
import { getFleetsServices } from 'AC/fleets/fleetServices'
import { cityOptions } from 'utils/enumUtils'
import { backDateFormat } from 'utils/dateUtils'


const { Option } = Select

type M = moment.Moment;

const dateFormat = 'DD.MM.YYYY'

type Props = {
  onClose: () => void
  isOpen: boolean
  isIntersecting?: boolean
}

const selector = (state: State) => ({
  filter: state.fleetsReducer.fleetsServicesFilter,
  fleets: state.fleetsReducer.fleets,
})

export const Filters = ({ onClose, isOpen, isIntersecting }: Props) => {
  const [dates, setDates] = useState<M[] | undefined>(undefined)
  const history = useHistory()
  const dispatch = useDispatch()
  const { filter, fleets } = useSelector(selector)
  const urlParams = getUrlSearchParams()


  useEffect(() => {
    if (!isObjectEmpty(urlParams)) {
      dispatch(action(Actions.SET_FLEET_SERVICES_FILTER, { ...urlParams }))
      dispatch(getFleetsServices(urlParams))
    } else {
      dispatch(action(Actions.CLEAR_FLEETS_SERVICES, []))
      history.push(ROUTES.FLEETS.SERVICES.PARAMS.createPath({ ...filter }))
      dispatch(getFleetsServices(filter))
    }
  }, [])

  useEffect(() => {
    if (isIntersecting) {
      dispatch(getFleetsServices(filter))
    }
  }, [dispatch, filter, isIntersecting])


  const onChangeFilers = (partial: Partial<APITYPES.FleetServicesFilter>) => {
    dispatch(action(Actions.CLEAR_FLEETS_SERVICES, []))

    const newFilter = {
      ...filter,
      ...partial,
    }

    history.push(ROUTES.FLEETS.SERVICES.PARAMS.createPath(newFilter))

    dispatch(action(Actions.SET_FLEET_SERVICES_FILTER, newFilter))
    dispatch(getFleetsServices(newFilter))
  }

  const onResetFilers = () => {
    dispatch(action(Actions.CLEAR_FLEETS_SERVICES, []))
    dispatch(action(Actions.SET_FLEET_SERVICES_FILTER, {}))

    history.push(ROUTES.FLEETS.SERVICES.path)
    dispatch(getFleetsServices())
  }

  const onCalendarChange = (dates: any) => {
    if (!dates) {
      setDates(undefined)
    } else {
      const [beginDate, endDate] = dates
      
      //@ts-ignore
      setDates([moment(beginDate), endDate ? moment(endDate) : null])
      if (dates) {
        onChangeFilers({
          begin: moment(dates[0]).format(backDateFormat),
          end: moment(dates[0]).format(backDateFormat),
        })
      }
    }
  }

  return (
    <Drawer
      title='Фильтры'
      placement='right'
      onClose={onClose}
      open={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Оператор'>
          <Select
            allowClear
            placeholder='Поиск по операторам'
            value={filter?.fleet_id}
            onChange={(value) => onChangeFilers({ fleet_id: value ?? undefined })}
          >
            {
              fleets?.map((fleet) => (
                <Option
                  key={fleet.id}
                  value={fleet.id}
                >
                  { fleet.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          label='Город'
          name='city_id'
        >
          <Select
            allowClear
            placeholder='Выберите город'
            onChange={(value) => onChangeFilers({ city_id: value ?? undefined })}
            options={cityOptions}
          />
        </Form.Item>
        <Form.Item label='Период'>
          <DatePicker.RangePicker
            format={dateFormat}
            //@ts-ignore
            value={dates}
            onCalendarChange={
              (value) => {
                onCalendarChange(value)
              }
            }
          />
        </Form.Item>

        <Button onClick={onResetFilers}>
          Сбросить фильтры
        </Button>
      </Form>
    </Drawer>
  )
}
