import { ROUTES } from 'config/routes'


export const getZeroWidthTriggerStyle = (collapsed: boolean) => ({
  top: 35,
  width: 30,
  height: 30,
  left: collapsed ? 5 : 185,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  transition: 'left 0.2s ease-in-out',
})


export const getOpenNavBlock = (historyPath: string, openKeys: string[]): {
  selectedKeys: string[]
  openKeys: string[]
} => {
  const groupKey = routeGroupMap[historyPath]

  if (groupKey) {
    return {
      selectedKeys: [groupKey, historyPath],
      openKeys: [groupKey, openKeys].flat(),
    }
  }

  return {
    selectedKeys: [historyPath],
    openKeys: openKeys,
  }
}


const routeGroupMap = {
  //partners_group
  [ROUTES.PARTNERS.path]: 'partners_group',
  [ROUTES.PARTNERS.RENT.path]: 'partners_group',
  [ROUTES.CARWASHES.path]: 'partners_group',


  //products_group
  [ROUTES.PRODUCTS.path]: 'products_group',
  [ROUTES.PRODUCTS.GROUPS.path]: 'products_group',
  [ROUTES.PRODUCTS.TARIFFS.path]: 'products_group',


  //doers_group
  [ROUTES.DOERS.SHIFTS.path]: 'doers_group',
  [ROUTES.DOERS.SHIFTS.SETTINGS.path]: 'doers_group',
  [ROUTES.DOERS.INTERNSHIPS.path]: 'doers_group',
  [ROUTES.DOERS.VACANCIES.path]: 'doers_group',
  [ROUTES.DOERS.OFFERS.path]: 'doers_group',
  [ROUTES.DOERS.DOERS.path]: 'doers_group',
  [ROUTES.DOERS.OPERATIONS.path]: 'doers_group',
  [ROUTES.DOERS.MONITORING.path]: 'doers_group',
  [ROUTES.DOERS.ROLES.path]: 'doers_group',
  [ROUTES.DOERS.LOCATIONS.path]: 'doers_group',
  [ROUTES.DOERS.MAILING.path]: 'doers_group',
  [ROUTES.DOERS.MOTIVATIONS.path]: 'doers_group',
  [ROUTES.YANDEXPRO.REPORTS.path]: 'doers_group',
  [ROUTES.YANDEX_LAVKA.REPORTS.path]: 'doers_group',
  [ROUTES.YANDEX_EDA.REPORTS.path]: 'doers_group',
  [ROUTES.MAGNIT.REPORTS.path]: 'doers_group',
  [ROUTES.URENT.REPORTS.path]: 'doers_group',

  //billing_group
  [ROUTES.BILLING.BILLS.path]: 'billing_group',
  [ROUTES.BILLING.DOERS.path]: 'billing_group',
  [ROUTES.BILLING.DOER_RECORDS.path]: 'billing_group',
  [ROUTES.BILLING.CONTRACTORS.path]: 'billing_group',
  [ROUTES.BILLING.YANDEX_PRO.path]: 'billing_group',

  //fleets_group
  [ROUTES.FLEETS.CARS.path]: 'fleets_group',
  [ROUTES.FLEETS.SERVICES.path]: 'fleets_group',

  //directory_group
  [ROUTES.DIRECTORY.CLIENT_TYPES.path]: 'directory_group',
  [ROUTES.DIRECTORY.CAR_CLASSES.path]: 'directory_group',
  [ROUTES.DIRECTORY.CARS.path]: 'directory_group',
  [ROUTES.DIRECTORY.SERVICES.path]: 'directory_group',
}
