import React, {
  FC,
  useEffect,
  useMemo,
} from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { UnmatchedExecutorsTable } from './components/UnmatchedExecutorsTable'

import styles from './styles.module.sass'
import { ShiftReportsType } from '../../../../index'


const selector = (state: State) => ({ report: state.urentReducer.uploadedReport })

interface Props {
  type: ShiftReportsType
  onSubmit: () => void
}
export const MatchingUnmatchedExecutors: FC<Props> = ({ onSubmit, type }) => {
  const { report } = useSelector(selector)
  const unmatchedDoers = useMemo(() => {
    switch (type) {
      case 'urent':
        return report?.unmatched_executors // @deprecated
      case 'magnit':
        return report?.unmatched_external_doers
    }
  }, [report])

  useEffect(() => {
    if (unmatchedDoers?.length === 0) {
      onSubmit()
    }
  }, [report])

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <UnmatchedExecutorsTable
          type={type}
          unmatchedExecutors={unmatchedDoers || []}
        />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!unmatchedDoers?.every((item) => item.isMatched)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}