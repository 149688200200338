/* eslint-disable max-len */
import React, { useState } from 'react'
import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { FormFooter } from 'components/FormFooter'
import { required, searchSelect } from 'utils/formUtils'
import { createFleetService } from 'AC/fleets/fleetServices'
import { cityOptions } from 'utils/enumUtils'
import { apiV1 } from 'network/http'
import { SubTasks, getAllSubtask } from '../utils'


const { Option } = Select

type Props = {
  onClose: () => void
  isOpen: boolean
};

const dateFormat = 'DD.MM.YYYY'
const backFormat = 'YYYY-MM-DD'

const selector = (state: State) => ({
  fleets: state.fleetsReducer.fleets,
  carClasses: state.directoryReducer.carClasses,
  tasks: state.operationsReducer.tasks,
})

const formLayout = { labelCol: { span: 5 } }

export const CreateFleetServiceModal = ({ onClose, isOpen }: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { fleets, carClasses, tasks } = useSelector(selector)
  const [subTasks, setSubTasks] = useState<SubTasks[] | null>(null)

  const onFinish = async (values: any) => {
    try {
      const formattedValues = {
        ...values,
        begin: moment(values.begin).format(backFormat),
        end: values.end ? moment(values.end).format(backFormat) : undefined,
      }

      await dispatch(createFleetService(formattedValues, onClose))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const getSubTasks = async (id: string) => {
    try {
      const res = await apiV1.get(`/v1/tasks/${id}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('TOKEN') } })

      const allSubTasks = getAllSubtask(res.data.subtasks)
      const task = {
        id: res.data.id,
        title: res.data.title,
      }
      const subTasksWithTask = [...allSubTasks, task]

      setSubTasks(subTasksWithTask)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const filteredCarClasses = carClasses?.filter(
    (carClass) => carClass.group === 'fleet_yandex'
  )

  return (
    <Modal
      title='Добавление тарифа'
      open={isOpen}
      onCancel={onClose}
      afterClose={form.resetFields}
      footer={false}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          label='Оператор'
          name='fleet_id'
          rules={required}
        >
          <Select
            allowClear
            placeholder='Выберите оператора'
          >
            {
              fleets?.map((fleet) => (
                <Option
                  key={fleet.id}
                  value={fleet.id}
                >
                  { fleet.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Город'
          name='city_id'
          rules={required}
        >
          <Select
            allowClear
            placeholder='Выберите город'
            options={cityOptions}
          />
        </Form.Item>

        <Form.Item
          label='Корневая'
          name='main_task'
          rules={required}
        >
          <Select
            showSearch
            allowClear
            placeholder='Выберите задачу'
            filterOption={searchSelect}
            onChange={(e: string) => getSubTasks(e)}
          >
            {
              tasks.map((t) => (
                <Option
                  key={t.id}
                  value={t.id}
                >
                  { t.title }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Задача'
          name='task_id'
          rules={required}
        >
          <Select
            showSearch
            allowClear
            placeholder='Выберите задачу'
            filterOption={searchSelect}
          >
            {
              subTasks?.map((t) => (
                <Option
                  key={t.id}
                  value={t.id}
                >
                  { t.title }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Класс авто'
          name='car_class_id'
        >
          <Select
            style={{ width: '100%' }}
            allowClear
            placeholder='Выберите класс'
          >
            {
              filteredCarClasses?.map((car) => (
                <Option key={car.id}>{ car.name }</Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Услуга'
          name='title'
          rules={required}
        >
          <Input placeholder='Введите наименование' />
        </Form.Item>
        <Form.Item
          label='Цена'
          name='price'
          rules={required}
        >
          <Input
            type='number'
            placeholder='Введите цену'
          />
        </Form.Item>

        <Form.Item label='Действует'>
          <Row justify='space-between'>
            <Col span={11}>
              <Form.Item
                name='begin'
                label='с'
                rules={required}
                initialValue={null}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name='end'
                label='по'
                initialValue={null}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <FormFooter onCancel={onClose} />
      </Form>
    </Modal>
  )
}
