import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { required } from 'utils/formUtils'
import { cityOptions } from 'utils/enumUtils'
import { FormFooter } from 'components/FormFooter'
import { addDoerTariff } from 'AC/doers/doerTariffs'
import { ROUTES } from 'config/routes'
import { backDateFormat } from '../../../../../utils/dateUtils'
import { fetchEmployers } from '../../../../../AC/shifts/shifts'


const dateFormat = 'DD.MM.YYYY'

type Props = {
  isOpen: boolean
  onClose: () => void
};

const formLayout = { labelCol: { span: 4 } }

const selector = (state: State) => ({
  roles: state.doersReducer.roles,
  employers: state.shiftsReducer.employers,
  functions: state.doerTariffsReducer.doerTariffFunctions,
  tariffGroups: state.doerTariffsReducer.doerTariffsGroups,
})

export const AddDoerTariffModal = ({ isOpen, onClose }: Props) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const { roles, functions, tariffGroups, employers } = useSelector(selector)
  const [dates, setDates] = useState([])

  const onFinish = (values: any) => {
    values.begin = values.begin.format(backDateFormat)

    if (values.end) {
      values.end = values.end.format(backDateFormat)
    } else {
      delete values.end
    }

    dispatch(
      addDoerTariff(values, (id: string) => {
        history.push(ROUTES.DOERS.TARIFFS.createPath(id))
      })
    )
  }

  useEffect(() => {
    if (!employers.length) {
      dispatch(fetchEmployers())
    }
  }, [])

  return (
    <Modal
      title='Создание тарифа исполнителя'
      open={isOpen}
      onCancel={onClose}
      afterClose={form.resetFields}
      footer={false}
      width={700}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          name='title'
          label='Название'
          rules={required}
        >
          <Input placeholder='Введите название' />
        </Form.Item>

        <Form.Item
          name='employer_id'
          label='Заказчик'
          rules={required}
        >
          <Select
            allowClear
            placeholder='Выберите заказчика'
          >
            {
              employers.map((employer) => (
                <Select.Option
                  key={employer.id}
                  value={employer.id}
                >
                  { employer.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='city_id'
          label='Город'
          rules={required}
        >
          <Select
            allowClear
            placeholder='Выберите город'
            options={cityOptions}
          />
        </Form.Item>

        <Form.Item
          name='role_id'
          label='Роль'
          rules={required}
        >
          <Select
            allowClear
            showSearch
            placeholder='Выберите роль'
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              roles?.map((role) => (
                <Select.Option
                  key={role.id}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='function_name'
          label='Метрика'
          rules={required}
        >
          <Select
            allowClear
            showSearch
            placeholder='Выберите метрику'
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              functions.map((fn) => (
                <Select.Option
                  key={fn.name}
                  value={fn.name}
                >
                  { fn.title }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Группа'
          name='group_id'
        >
          <Select
            allowClear
            placeholder='Выберите группу'
          >
            {
              tariffGroups?.map((g) => (
                <Select.Option
                  key={g.id}
                  value={g.id}
                >
                  { g.name }
                </Select.Option>
              )
              )
            }
          </Select>
        </Form.Item>

        <Form.Item label='Действует'>
          <Row justify='space-between'>
            <Col span={12}>
              <Form.Item
                name='begin'
                label='с'
                rules={required}
              >
                <DatePicker
                  format={dateFormat}
                  //@ts-ignore
                  value={dates[0]}
                  onChange={
                    (value) =>
                      setDates(
                        //@ts-ignore
                        (...prev) => (prev[0] = moment(value))
                      )
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='end'
                label='по'
              >
                <DatePicker
                  format={dateFormat}
                  //@ts-ignore
                  value={dates[1]}
                  //@ts-ignore
                  onChange={
                    (value) =>
                      setDates(
                        //@ts-ignore
                        (...prev) => (prev[1] = moment(value) | null)
                      )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Создать'
        />
      </Form>
    </Modal>
  )
}
