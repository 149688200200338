import React, {
  FC,
  useEffect,
  useState,
} from 'react'
import {
  Button,
  Select,
  Table,
} from 'antd'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { getTableYScroll } from 'utils/scrollUtils'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { useDebounce } from 'utils/debounce'
import {
  fetchStaticLocations,
  fetchStaticLocationsByQuery,
} from 'AC/shifts/locations'
import { updateUrentUnmatchedPlacement } from 'AC/urent/placements'
import { ShiftReportsType } from '../../../../../index'
import { updateMagnitUnmatchedPlacement } from '../../../../../../../../../AC/magnit/placements'


type Placement = APITYPES.Urent.UnmatchedPlacement;


interface Props {
  type: ShiftReportsType
  unmatchedPlacements: Placement[]
}

export const UnmatchedPlacementsTable: FC<Props> = ({ unmatchedPlacements , type }) => {
  const [placements, setPlacements] = useState<Placement[]>(unmatchedPlacements)
  const dispatch = useDispatch()
  const locations = useSelector((state: State) => state.shiftsReducer.allLocations)
  const [debState, setDebState] = useState<string>('')
  const debouncedName = useDebounce(debState, 500)

  useEffect(() => {
    if (!locations.length) {
      dispatch(fetchStaticLocations())
    }
  }, [])


  useEffect(() => {
    onSearchChange(debouncedName)
  }, [debouncedName])


  const onSearchChange = (value: string) => {
    if (value && !locations.find((l) => l.name.includes(value))) {
      dispatch(fetchStaticLocationsByQuery(value))
    }
  }


  const handleSelectChange = (id: string, placement: Placement) => {
    const location = locations.find((l) => l.id === id)

    const updatedPlacement: Placement = {
      ...placement,
      location_id: location?.id,
      location_name: location?.name,
    }
    const updatedReport = placements.map((item: Placement) => {
      switch (type) {
        case 'urent':
          return item.urent_placement_id === updatedPlacement.urent_placement_id
            ? updatedPlacement
            : item
        case 'magnit':
          return item.id === updatedPlacement.id
            ? updatedPlacement
            : item
      }
    })

    setPlacements(updatedReport)
  }

  useEffect(() => {
    setPlacements(unmatchedPlacements)
  }, [unmatchedPlacements])


  const expandedRowRender = (placement: Placement) => {
    return (
      <Select
        disabled={placement.isMatched}
        placeholder='Выберите локацию'
        showSearch
        allowClear
        style={{ width: '100%' }}
        fieldNames={
          {
            value: 'id',
            label: 'name',
          }
        }
        notFoundContent='Локации в данном городе не найдены'
        onSearch={
          (value) =>
            setDebState(value)
        }
        onChange={(value: string) => handleSelectChange(value, placement)}

        options={
          locations.filter((l) => {
            if (placement.city_id) {
              return l.city_id === placement.city_id
            }

            return true
          }).map((l) => ({
            name: l.name,
            id: l.id,
          }))
        }
      />
    )
  }

  return (
    <Table
      bordered
      sticky
      pagination={false}
      size='large'
      style={{ cursor: 'pointer' }}
      scroll={{ y: getTableYScroll(500) }}
      columns={
        [
          type === 'urent' ? {
            title: 'Из Urent',
            dataIndex: 'label',
            key: 'yandex',
          } : {
            title: 'Из Магнит',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Из Базы Данных' ,
            dataIndex: 'location_name',
            key: 'doer',
          },
          {
            title: 'Связать данные',
            dataIndex: 'связать',
            width: '20%',
            key: 'x',
            align: 'center',
            render: (_: any, placement: Placement) => {
              return (
                <>
                  <Button
                    type='primary'
                    disabled={!placement.location_id || placement.isMatched}
                    onClick={
                      () => {
                        if (placement.location_id) {
                          switch (type) {
                            case 'urent':
                              dispatch(updateUrentUnmatchedPlacement(placement.urent_placement_id, placement.location_id))
                              break
                            case 'magnit':
                              dispatch(updateMagnitUnmatchedPlacement(placement.id, placement.location_id))
                              break
                          }
                        }
                      }
                    }
                    style={
                      {
                        width:'fit-content',
                        padding: '0 4px',
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                      }
                    }
                  >
                    { placement.isMatched ? 'Связано' : 'Связать' }
                  </Button>
                </>

              )
            },
          },
        ]
      }
      dataSource={
        placements?.map((p) => ({
          ...p,
          key: p.urent_placement_id || p.id,
          label: type === 'urent' ? `${p.urent_placement_name} (${p.urent_city})` : p.name,
        }))
      }
      expandable={{ expandedRowRender }}
    />
  )
}
