// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--form--UhIlU{display:-webkit-box;display:-ms-flexbox;display:flex;gap:16px}.styles-module--formItem--xzKCo{-webkit-box-flex:100;-ms-flex:100;flex:100}.styles-module--card--gRnuC{height:100%}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Doers/Doer/AmoCrm/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CAEF,gCACE,oBAAA,CAAA,YAAA,CAAA,QAAA,CAEF,4BACE,WAAA","sourcesContent":[".form\n  display: flex\n  gap: 16px\n\n.formItem\n  flex: 100\n\n.card\n  height: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles-module--form--UhIlU",
	"formItem": "styles-module--formItem--xzKCo",
	"card": "styles-module--card--gRnuC"
};
export default ___CSS_LOADER_EXPORT___;
