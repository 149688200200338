import {
  Button,
  Skeleton,
  Table,
} from 'antd'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getUtilityAccruals } from '../../../../../../AC/carwashes/carwashes'
import {
  dateFormat,
  getFormattedDate,
} from '../../../../../../utils/dateUtils'
import { APITYPES } from '../../../../../../types'

import { EditModal } from './EditModal'
import styles from './styles.module.sass'

import UtilityAccrual = APITYPES.UtilityAccrual


type Props = {
  objectId: string
}

export const UtilityAccruals = ({ objectId }: Props) => {
  const [ isCreate, setIsCreate ] = useState(false)
  const [ selectedEntity, setSelectedEntity ] = useState<APITYPES.UtilityAccrual | undefined>(undefined)
  const pageSize = 5
  const { data, isLoading, refetch } = useQuery(
    [getUtilityAccruals.name, objectId],
    () => getUtilityAccruals({
      object_id: objectId,
      limit: pageSize,
      offset: 0,
    })
  )

  const closeModal = () => {
    setIsCreate(false)
    setSelectedEntity(undefined)
  }

  return (isLoading ? (
    <Skeleton active />
  ) : (
    <div>
      <Table<UtilityAccrual>
        columns={
          [
            {
              title: 'Тип',
              dataIndex: ['utility_type'],
              key: 'utility_type',
              width: '40%',
            },
            {
              title: 'Дата',
              dataIndex: ['utility_date'],
              key: 'utility_date',
              width: '30%',
              render: (utility_date) => getFormattedDate(utility_date, dateFormat),
            },
            {
              title: 'Сумма',
              dataIndex: ['cost'],
              key: 'cost',
              width: '30%',
              render: (cost) => Number.parseFloat(cost).toFixed(2),
            },
          ]
        }
        className={styles.table}
        dataSource={data}
        bordered
        size='large'
        pagination={false}
        onRow={
          (entity) => {
            return { onClick: () => setSelectedEntity(entity) }
          }
        }
      />
      <Button
        type='primary'
        className={styles.addButton}
        onClick={() => setIsCreate(true)}
      >
        Добавить
      </Button>
      {
        (isCreate || selectedEntity) && (
          <EditModal
            existEntity={selectedEntity ? selectedEntity : undefined}
            newEntityObjectId={isCreate ? objectId : undefined}
            onCancel={closeModal}
            onSuccess={
              () => {
                closeModal()
                refetch()
              }
            }
          />
        )
      }
    </div>
  ))
}