import React from 'react'
import moment from 'moment'
import { Tag } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'

import { APITYPES } from 'types/apitypes'
import { FilterRangePicker } from 'components/FilterRangePicker'
import { getCityName, getEntries } from 'utils/enumUtils'
import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'


const { ShiftLocationsTypes, ShiftDoerStateTranslations, ShiftDoerStateColors } = APITYPES
const doerStateOptions = getEntries(ShiftDoerStateTranslations)

export const getColumns = () => {
  return [
    {
      title: 'Тип смены',
      dataIndex: 'shift_type',
      key: 'shift_type',
    },
    {
      title: 'Локация',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      width: 145,
      filters: doerStateOptions.map((option) => ({
        text: option[1],
        value: option[0],
      })),
      //@ts-ignore
      render: (state: string) => <Tag color={ShiftDoerStateColors[state]}>{ ShiftDoerStateTranslations[state] }</Tag>,
    },
    {
      title: 'Дата/Расписание',
      dataIndex: 'dates',
      key: 'dates',
      align: 'center',
      width: 300,
      filterDropdown: (props: FilterDropdownProps) => <FilterRangePicker dropdownProps={props} />,
    },
    {
      title: 'Кол-во операций',
      dataIndex: 'operations_count',
      key: 'operations_count',
      width: 100,
      align: 'center',
    },
  ]
}

export const getRows = (shifts: APITYPES.DoerShift[]) => {
  const timezone = useGetTimezoneFromLocalstorage()
  
  return shifts.map((shift) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'shift_type') {
        row[column.dataIndex] = shift.shift_type.type
      } else if (column.dataIndex === 'location') {
        row[column.dataIndex] = //@ts-ignore
          `${getCityName(shift.shift_type.city_id)}, ${shift.location.name} (${ShiftLocationsTypes[shift.location.type]})`
      } else if (column.dataIndex === 'role') {
        row[column.dataIndex] = shift.role.name
      } else if (column.dataIndex === 'dates') {
        const date = moment.utc(shift.date).format('L')
        const endDate = moment.utc(shift.end).format('L')

        const [, beginTime] = shift.begin.split(' ')
        const [, endTimes] = shift.end.split(' ')

        const [start, end] = formatDateTime({
          start: beginTime,
          end: endTimes,
          targetTimezone: timezone,
        })

        row[column.dataIndex] =
          `${date}, ${start} - ${endDate}, ${end}`
      } else {
        //@ts-ignore
        row[column.dataIndex] = shift[column.dataIndex]
      }
    })
    row.key = shift.id

    return row
  })
}
