// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--wrapper--f9q8t{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:1rem;margin-top:1rem;height:300px}.styles-module--wrapper--f9q8t .styles-module--mainSection--SKWeF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;overflow:hidden;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.styles-module--wrapper--f9q8t .styles-module--btnWrapper--RYwa1{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/ShiftReportsImport/components/MatchingModal/steps/FinalStep/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,eAAA,CACA,YAAA,CACA,kEACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,eAAA,CACA,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACF,iEACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".wrapper\n  display: flex\n  flex-direction: column\n  gap: 1rem\n  margin-top: 1rem\n  height: 300px\n  .mainSection\n    display: flex\n    justify-content: center\n    align-items: center\n    flex-direction: column\n    overflow: hidden\n    flex-grow: 1\n  .btnWrapper\n    display: flex\n    justify-content: flex-end\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles-module--wrapper--f9q8t",
	"mainSection": "styles-module--mainSection--SKWeF",
	"btnWrapper": "styles-module--btnWrapper--RYwa1"
};
export default ___CSS_LOADER_EXPORT___;
