import React, { FC, useState } from 'react'
import { Button, Select } from 'antd'

import { APITYPES } from 'types/apitypes'
import { SelectValue } from 'components/DebounceSelectDoer'
import { api } from 'index'
import { useDebouncedSearch } from 'utils/hooks/useDebouncedSearch'
import { AddDoerModal } from '../../../Doers/Doer/AddDoerModal'

import cls from './add-reserve-form.module.sass'


export async function fetchUserList(username: string): Promise<SelectValue[]> {
  const resp = await api.get(APITYPES.Doers.Get.URL,
    {
      params: {
        search: username,
        limit: 40,
      },
    })

  return resp?.map(mapDoerToOption)
}

const mapDoerToOption = ({ id, name, phone_number }: APITYPES.Doer): SelectValue => ({
  label: `${name} (${phone_number ? phone_number : 'без номера телефона'})`,
  value: id,
})

const NO_DATA_OPTION = {
  label: '+ Добавить нового исполнителя',
  value: 'NO_DATA',
  key: 'no_data',
}

type AddReserveFormProps = {
  onSubmit: (doerId: string) => void
  onCancel: () => void
}

export const AddReserveForm: FC<AddReserveFormProps> = ({ onCancel, onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selected, setSelected] = useState<SelectValue | null>(null)
  const [options, setOptions] = useState<SelectValue[]>([])

  const [search, setSearch] = useDebouncedSearch({ defaultValue: '' },
    () => {
      fetchUserList(search).then((newOptions) => {
        setOptions(newOptions.length === 0 ? [NO_DATA_OPTION] : newOptions)
      })
    },
  )
  const onChange = (v: SelectValue) => {
    if (!v) {
      setSelected(null)

      return
    }
    if (v.value === 'NO_DATA') {
      setSelected(null)
      setIsModalOpen(true)
    } else {
      setSelected(v)
    }
  }

  const onAddDoer = (doer: APITYPES.Doer) => {
    const option = mapDoerToOption(doer)

    setOptions([option, ...options])
    setSelected(option)
    setIsModalOpen(false)
  }

  const onFinish = () => {
    if (!selected) {
      return
    }
    onSubmit(selected.value)
  }

  return (
    <div
      className={cls.flexWrapper}
    >
      <Select
        allowClear
        showSearch
        onChange={onChange}
        placeholder='Введите ФИО или телефон исполнителя'
        labelInValue
        filterOption={false}
        notFoundContent={null}
        options={options}
        value={selected}
        searchValue={search}
        onSearch={setSearch}
        className={cls.select}
      />

      <Button
        type='primary'
        disabled={!selected}
        onClick={onFinish}
      >
        Записать исполнителя
      </Button>
      <Button onClick={onCancel}>отмена</Button>

      {
        isModalOpen && (
          <AddDoerModal
            shortForm
            search={search || ''}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSuccess={onAddDoer}
          />
        )
      }

    </div>
  )
}