import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { Button, Drawer, Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { action, Actions } from 'actions'
import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { fetchLocations } from 'AC/shifts/shifts'
import { cityOptions, getEnumKeys } from 'utils/enumUtils'
import { getUrlSearchParams } from 'utils/urlUtils'
import { useDebouncedSearch } from 'utils/hooks/useDebouncedSearch'
import { APITYPES } from 'types/apitypes'

const {ShiftLocationsTypes} = APITYPES
const shiftTypes = getEnumKeys(ShiftLocationsTypes)

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  locFilters: state.shiftsReducer.locationsFilter,
})

export const Filters = ({isOpen, onClose}: Props) => {
  const urlParams = getUrlSearchParams()

  const dispatch = useDispatch()
  const {locFilters} = useSelector(selector)
  const history = useHistory()
  const firstRun = useRef(true)

  const [search, setSearch, debouncedSearch] = useDebouncedSearch(
    {defaultValue: urlParams?.search ? urlParams.search : locFilters.search},
    () => onFilterChange('search', debouncedSearch),
  )

  /** create query when filters changed */
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    history.push(ROUTES.DOERS.LOCATIONS.PARAMS.createPath(locFilters))
    dispatch(action(Actions.SET_SHIFT_LOCATIONS, []))
    dispatch(fetchLocations(locFilters))
  }, [locFilters])

  /** handlers */
  const onFilterChange = (key: string, value: string | undefined | number) => {
    dispatch(action(Actions.SET_LOCATIONS_FILTER, {...locFilters, [key]: value}))
  }

  const resetFilters = () => {
    setSearch(undefined)
    if (search) firstRun.current = true
    dispatch(action(Actions.SET_LOCATIONS_FILTER, {}))
  }

  return (
    <Drawer
      title="Фильтры"
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout="vertical">
        <Form.Item label="Город">
          <Select
            allowClear
            placeholder="Выберите город"
            options={cityOptions}
            value={locFilters.city_id}
            onChange={(city_id) => onFilterChange('city_id', city_id)}
          />
        </Form.Item>

        <Form.Item label="Тип локации">
          <Select
            allowClear
            placeholder="Выберите тип"
            //@ts-ignore
            options={shiftTypes.map((type: any) => ({label: ShiftLocationsTypes[type], value: type}))}
            value={locFilters.type}
            onChange={(type) => onFilterChange('type', type)}
          />
        </Form.Item>

        <Form.Item label="Название">
          <Input
            placeholder="Введите название"
            onChange={(e) => setSearch(e.target.value ? e.target.value : undefined)}
            value={search}
          />
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
