import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Drawer, Form, Input, Select } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { useDebounce } from 'utils/debounce'
import { getCityName } from 'utils/enumUtils'


const { ShiftLocationsTypes } = APITYPES

// TODO: use global constants from utils
const dateFormat = 'DD.MM.YYYY'
const backFormat = 'YYYY-MM-DD'

const statuses = Object.entries(APITYPES.OperationStateOptions).map((option) => ({
  text: option[1],
  value: option[0],
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  operationsFilter: state.operationsReducer.operationsFilter,
  tasks: state.operationsReducer.tasks,
  locations: state.shiftsReducer.allLocations,
})

export const Filters = (props: Props) => {
  const { onClose, isOpen } = props

  const history = useHistory()
  const dispatch = useDispatch()
  const { operationsFilter: filter, tasks, locations } = useSelector(selector)
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())

  /** states */
  const [inputs, setInputs] = useState<{
    doer: string | undefined
    grz: string | undefined
  }>({
    doer: params?.doer_name ? params?.doer_name : filter?.doer_name,
    grz: params?.grz ? params?.grz : filter?.grz,
  })
  const debouncedInputs = useDebounce(inputs, 500)
  const onInputsChange = (e: any, key: 'doer' | 'grz') => setInputs({
    ...inputs,
    [key]: e.target.value,
  })

  /** effects */
  useEffect(() => {
    const parsed = qs.parse(params)

    onFilterChange({
      ...parsed,
      //@ts-ignore
      doer_name: debouncedInputs.doer,
      grz: debouncedInputs.grz,
    })
  }, [debouncedInputs])


  /** handlers */
  const onFilterChange = (partial: Partial<APITYPES.OperationsFilter>) => {
    history.push(ROUTES.DOERS.OPERATIONS.PARAMS.createPath({
      ...filter,
      ...partial,
    }))
    dispatch(action(Actions.CLEAR_OPERATIONS, []))
    dispatch(action(Actions.SET_OPERATIONS_FILTER, {
      ...filter,
      ...partial,
    }))
  }
  const onFilterReset = () => {
    dispatch(action(Actions.CLEAR_OPERATIONS, []))
    dispatch(action(Actions.SET_OPERATIONS_FILTER, {}))
    history.push(ROUTES.DOERS.OPERATIONS.path)
    setInputs({
      doer: undefined,
      grz: undefined,
    })
  }

  return (
    <Drawer
      title='Фильтры'
      placement='right'
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Дата смены'>
          <DatePicker.RangePicker
            allowClear
            format={dateFormat}
            onChange={
              (dates) => onFilterChange(dates
                ? {
                  shift_date_from: dates?.[0]?.format(backFormat),
                  shift_date_to: dates?.[1]?.format(backFormat),
                } : {
                  shift_date_from: undefined,
                  shift_date_to: undefined,
                },
              )
            }
            value={
              (filter?.shift_date_from && filter?.shift_date_to)
                ? [moment(filter.shift_date_from), moment(filter.shift_date_to)]
                : undefined
            }
          />
        </Form.Item>

        <Form.Item label='Период'>
          <DatePicker.RangePicker
            allowClear
            format={dateFormat}
            onChange={
              (dates) => onFilterChange(dates
                ? {
                  begin: dates?.[0]?.format(backFormat),
                  end: dates?.[1]?.format(backFormat),
                } : {
                  begin: undefined,
                  end: undefined,
                },
              )
            }
            value={(filter?.begin && filter?.end) ? [moment(filter.begin), moment(filter.end)] : undefined}
          />
        </Form.Item>

        <Form.Item label='Статус'>
          <Select
            allowClear
            placeholder='Выберите статус'
            onChange={(value: string) => onFilterChange({ state: value ? value : undefined })}
            value={filter?.state ? filter.state : undefined}
          >
            {
              statuses?.map((status) => (
                <Select.Option
                  key={status.value}
                  value={status.value}
                >
                  { status.text }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Операция'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите операцию'
            value={filter?.task_id}
            onChange={(value: string) => onFilterChange({ task_id: value })}
            filterOption={
              (input, option) =>
                (option!.children as unknown as string || '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {
              tasks?.map((task) => (
                <Select.Option
                  key={task.id}
                  value={task.id}
                >
                  { task.title }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Локация'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите локацию'
            value={filter?.location_id}
            onChange={(value: string) => onFilterChange({ location_id: value })}
            filterOption={
              (input, option) =>
                (option!.children as unknown as string || '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {
              locations?.map((location) => (
                <Select.Option
                  key={location.id}
                  value={location.id}
                >
                  { /*@ts-ignore*/ }
                  { `${getCityName(location.city_id)}, ${location.name} (${ShiftLocationsTypes[location.type]})` }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Гос. номер'>
          <Input
            allowClear
            value={inputs.grz}
            placeholder='Поиск по гос. номеру'
            onChange={(e) => onInputsChange(e, 'grz')}
          />
        </Form.Item>

        <Form.Item label='Исполнитель'>
          <Input
            allowClear
            value={inputs.doer}
            placeholder='Поиск по исполнителю'
            onChange={(e) => onInputsChange(e, 'doer')}
          />
        </Form.Item>

        <Button onClick={onFilterReset}>Сбросить фильтры</Button>
      </Form>
    </Drawer>
  )
}