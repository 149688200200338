import React from 'react'
import { Card, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { APITYPES } from 'types/apitypes'
import { updateBill } from 'AC/billing/updateBill'

import styles from './styles.module.sass'
import { billTypesMap } from '../../../../../../utils/enumUtils'


const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

type Props = {
  bill: APITYPES.Bill
}

export const BillInfo = (props: Props) => {
  const dispatch = useDispatch()
  const { bill } = props
  const [form] = useForm()

  const onFinish = async (values: {
    name: string
    description: string
  }) => {
    dispatch(updateBill({
      name: values.name,
      description: values.description,
    }))
  }

  const onBlur = () => {
    onFinish(form.getFieldsValue())
  }

  return (
    <Card>
      <div className={styles.container}>
        <Form
          className={styles.form}
          name='bill_form'
          {...formItemLayout}
          onFinish={onFinish}
          onBlur={onBlur}
          form={form}
        >
          <Form.Item
            className={styles.field}
            label='Тип'
          >
            { billTypesMap[bill.bill_type] }
          </Form.Item>
          <Form.Item
            className={styles.field}
            label='Контрагент'
          >
            { bill.legal_name }
          </Form.Item>
          <Form.Item
            className={styles.field}
            label='Период'
          >
            { bill.period }
          </Form.Item>
          <Form.Item
            className={styles.field}
            name='name'
            label='Название'
            initialValue={bill.name}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.field}
            name='description'
            label='Описание'
            initialValue={bill.description}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.field}
            label='Сумма счета'
          >
            { bill.total_value }
          </Form.Item>
        </Form>
      </div>
    </Card>
  )
}