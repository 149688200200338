import { Button } from 'antd'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addReportFile, getYandexProReportById } from 'AC/yandexpro/reports'
import { UploadFiles } from 'shared/ui/UploadFiles'
import { State } from 'stores/rootReducer'
import { action, Actions } from '../../../../../../../actions'

import styles from './styles.module.sass'


interface Props {
  onSubmit: () => void
  reportStep: () => void
  reportId?: string
  flag?: string
}
const select = (state: State) => state.yandexProReducer.report

export const UploadOperationReportFile: FC<Props> = ({ onSubmit, reportId, reportStep, flag }) => {
  const report = useSelector(select)
  const dispatch = useDispatch()

  useEffect(() => {
    if (reportId) {
      dispatch(getYandexProReportById(reportId))
    }
  },[])


  useEffect(() => {
    if (report && reportId) {
      if (report?.unmatched_executors?.length) {
        dispatch(action(Actions.SET_YANDEXPRO_EXECUTOR, report?.unmatched_executors))

        onSubmit()
      } else {
        reportStep()
      }
    }
  }, [report])

  return (
    <div className={styles.wrapper}>
      <section className={styles.uploading}>
        <UploadFiles
          title='Загрузите файл'
          acceptingFiles='.xlsx'
          onFileUpload={(file) => dispatch(addReportFile(file, flag))}
        />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!report}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
