import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { showError } from '../../components/modals/ErrorModal'
import { errorNotification, openNotification, successNotification } from 'components/modals/OperationNotification'
import { mapStateToStatus } from '../../App/PrivateRoutes/Doers/internships/components/internships-table/table-utils'

import mapStateToUrlStatus = APITYPES.Internships.mapStateToUrlStatus
import STATE_TYPE = APITYPES.Internships.STATE_TYPE


export const fetchInternships = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchInternships', StatusRequest.LOADING))
    try {
      const state = getState()
      const { filters } = state.internshipsReducer
      const params: APITYPES.Internships.GET.Req = {
        understaffed: filters?.understaffed,
        search: filters?.search,
        date_from: filters?.dateFrom,
        date_to: filters?.dateTo,
        city_id: filters?.cityId,
        location_id: filters?.locationId,
        role_id: filters?.roleId,
        offset: ((filters?.page || 1) - 1) * (filters?.limit || APITYPES.Internships.defaultLimit),
        limit: filters?.limit,
      }
      const resp = await api.get(APITYPES.Internships.GET.URL, {
        params,
        needFullAxiosResp: true,
      })

      if (resp.data) {
        dispatch(action(Actions.SET_INTERNSHIPS, {
          internships: resp.data,
          total: Number(resp.headers['x-total-count']),
        }))
        dispatch(changeRequestState('fetchInternships', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchInternships', StatusRequest.ERROR))
      showError(e?.response?.data || 'Ошибка загрузки данных')
    }
  }
}

export const addInternship = (body: APITYPES.Internships.ReqCreate, onSuccess: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      await api.post(APITYPES.Internships.POST.URL, body)
      dispatch(fetchInternships())
      onSuccess()
    } catch (e) {
      showError(e?.response?.data)
    }
  }
}

export const fetchDoersFromInternship = (search: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const doers = await api.get(APITYPES.Doers.Get.URL, {
        params: {
          search,
          limit: 40,
        },
      })

      dispatch(action(Actions.SET_INTERNSHIPS_DOER_SEARCH, Boolean(doers.length)))
    } catch (e) {
      showError(e?.response?.data)
    }
  }
}


export const changeDoerInternshipStatus = (
  internshipId: string,
  doerShiftId: string,
  status: APITYPES.Internships.STATE_TYPE,
): ThAction => {
  return async (dispatch, _, { api }) => {
    const isCommentRequired = [STATE_TYPE.FAILED, STATE_TYPE.ENDED, STATE_TYPE.STARTED].includes(status)

    try {
      await api.put(APITYPES.Shifts.ChangeDoerShiftStatus.URL,
        { comment:  isCommentRequired ? mapStateToStatus[status].label : undefined },
        {
          replaceUrl: {
            doer_shift_id: doerShiftId,
            new_status: mapStateToUrlStatus[status],
          },
        })
      openNotification('Статус исполнителя изменен')
      dispatch(action(Actions.CHANGE_INTERNSHIP_DOERS_STATE, {
        internshipId,
        doerShiftId,
        status,
      }))
    } catch (e) {
      showError(e?.response?.data)
    }
  }
}


export const cancelInternship = (
  offerId: string,
  comment: string,
  onSuccess: () => void,
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('cancelInternship', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Internships.CANCEL.URL,
        { comment:  comment },
        { replaceUrl: { offerId } })

      if (resp) {
        dispatch(action(Actions.CANCEL_INTERNSHIPS, { id: offerId }))
        dispatch(changeRequestState('cancelInternship', StatusRequest.LOADED))
        openNotification('Стажировка отменена')
        onSuccess()
      }
    } catch (e) {
      dispatch(changeRequestState('cancelInternship', StatusRequest.ERROR))
      showError(e?.response?.data)
    }
  }
}


export const changePlaceInternshipsShift = (
  shifts: APITYPES.Internships.InternshipsShift[],
  onSuccess: () => void,
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('editInternshipCount', StatusRequest.LOADING))
    
    const fetches = shifts.map((shift) => {
      return api.put(APITYPES.Internships.PUTPlaces.URL,
        {
          roles: [
            {
              id: shift.role_id,
              places: shift.places,
            },
          ],
        },
        { replaceUrl: { shiftId: shift.id } })
    })

    try {
      const result = await Promise.allSettled(fetches)

      if (result) {
        const group = result.reduce((acc: Record<string, APITYPES.Internships.InternshipsShift[]>, res, currentIndex) => {
          if (res.status === 'rejected') {
            if (acc.rejected) {
              acc.rejected.push(shifts[currentIndex])
            } else {
              acc.rejected = [shifts[currentIndex]]
            }
          } else if (res.status === 'fulfilled') {
            if (acc.fulfilled) {
              acc.fulfilled.push(shifts[currentIndex])
            } else {
              acc.fulfilled = [shifts[currentIndex]]
            }
          }
          
          return acc
        }, {})

        dispatch(changeRequestState('editInternshipCount', StatusRequest.LOADED))

        if (group.fulfilled?.length === shifts.length) {
          successNotification('Количество мест изменено')
          dispatch(fetchInternships())
          onSuccess()
        } else if (group.rejected?.length === shifts.length) {
          errorNotification('Не удалось изменить количество мест для всех смен')
        } else {
          if (group.fulfilled?.length) {
            openNotification('Количество мест изменено для смен: ' + group.fulfilled.map((shift) => shift.location).join(', '))
          }
          if (group.rejected?.length) {
            errorNotification(
              'Не удалось изменить количество мест для смен: ' + group.rejected.map((shift) => shift.location).join(', ')
            )
          }
          dispatch(fetchInternships())
          onSuccess()
        }
      }
    } catch (e) {
      dispatch(changeRequestState('editInternshipCount', StatusRequest.ERROR))
      showError(e?.response?.data)
    }
  }
}
