import React from 'react'
import {
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Select,
} from 'antd'
import { isEmpty } from 'lodash'
import { useMutation } from '@tanstack/react-query'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import {
  createUtilityAccrual,
  deleteUtilityAccrual,
  editUtilityAccrual,
} from 'AC/carwashes/carwashes'
import { FormFooter } from 'components/FormFooter'
import { showSureConfirm } from 'components/modals/SureModal'
import { DeleteButton } from 'components/DeleteButton'
import {
  backDateFormat,
  dateFormat,
  getFormattedDate,
} from '../../../../../../../utils/dateUtils'
import { utilityTypeSelectOptions } from '../../../../../../../utils/enumUtils'

import UtilityAccrual = APITYPES.UtilityAccrual


type Props = {
  newEntityObjectId?: string
  existEntity?: UtilityAccrual
  onSuccess: () => void
  onCancel: () => void
}

export const EditModal = (props: Props) => {
  const {
    newEntityObjectId,
    existEntity,
    onSuccess,
    onCancel,
  } = props
  const isEntityExist = !isEmpty(existEntity)
  const {
    isLoading: isEntityCreating,
    mutate: createEntity,
  } = useMutation(createUtilityAccrual, { onSuccess })
  const {
    isLoading: isEntityUpdating,
    mutate: updateEntity,
  } = useMutation((data: UtilityAccrual) =>
    editUtilityAccrual(existEntity?.id as string, data),
  { onSuccess }
  )
  const {
    isLoading: isEntityDeleting,
    mutate: deleteEntity,
  } = useMutation(deleteUtilityAccrual, { onSuccess })

  const isOperationInProgress = isEntityCreating || isEntityUpdating || isEntityDeleting

  const onDelete = () => {
    showSureConfirm(()=>{
      deleteEntity(existEntity?.id as string)
    },()=>{})
  }

  return (
    <Modal
      open
      title={isEntityExist ? 'Редактирование начисления' : 'Добавление начисления'}
      onCancel={onCancel}
      width={600}
      footer={false}
      cancelButtonProps={{ disabled: isOperationInProgress }}
    >
      <Form<UtilityAccrual>
        disabled={isOperationInProgress}
        onFinish={
          (formEntity) => isEntityExist
            ? updateEntity(formEntity)
            : createEntity({
              ...formEntity,
              object_id: newEntityObjectId as string,
            })
        }
        initialValues={isEntityExist ? existEntity : undefined}
      >
        <Form.Item
          name='utility_type'
          label='Тип начисления'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select
            showSearch
            options={utilityTypeSelectOptions}
          />
        </Form.Item>
        <Form.Item
          name='utility_date'
          label='Дата'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          // TODO: KZ: move to common props util
          getValueProps={(value) => ({ value: value ? moment(value) : value })}
          normalize={(value) => getFormattedDate(value, backDateFormat)}
        >
          <DatePicker
            format={dateFormat}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          name='cost'
          label='Сумма'
        >
          <InputNumber precision={2} />
        </Form.Item>
        <FormFooter
          isLoading={isOperationInProgress}
          onCancel={onCancel}
          okText={isEntityExist ? 'Сохранить' : 'Добавить'}
          leftElement={
            isEntityExist
              ? (
                <DeleteButton
                  disabled={isOperationInProgress}
                  onClick={onDelete}
                />
              )
              : undefined
          }
        />
      </Form>
    </Modal>
  )
}