import React, {
  useEffect,
  useState,
} from 'react'
import { Table } from 'antd'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import {
  action,
  Actions,
} from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import {
  createUtilityAccrual,
  fetchRentalObjectTypes,
  getUtilityAccruals,
} from '../../../../AC/carwashes/carwashes'

import { RentHeader } from './RentHeader'
import {
  getColumns,
  getRows,
} from './utils'
import styles from './styles.module.sass'
import {
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { useQueryParams } from '../../../../utils/hooks/useQueryParams'
import { sendUtilityAccrualsToBilling } from '../../../../AC/rent/rent'
import { openNotification } from '../../../../components/modals/OperationNotification'


const selector = (state: State) => ({
  rent: state.billingReducer.rentData,
  isLoading: state.requestsReducer.fetchRentByPeriod === StatusRequest.LOADING,
  rentalObjectTypes: state.carwashesReducer.rentalObjectTypes,
})

export const Rent = () => {
  const dispatch = useDispatch()
  const {
    rent,
    isLoading,
    rentalObjectTypes,
  } = useSelector(selector)
  const queryParams = useQueryParams()
  const [isUtilityTypeSelected, setIsUtilityTypeSelected] = useState<boolean>(false)

  const {
    data: utilityData,
    isLoading: isUtilityDataLoading,
    refetch: refetchUtilityData,
  } = useQuery(
    [getUtilityAccruals.name, queryParams.object_type_id],
    () => getUtilityAccruals({
      object_type_id: queryParams.object_type_id,
      utility_date_from: queryParams.begin,
      utility_date_to: queryParams.end,
    }),
    { enabled: isUtilityTypeSelected && Boolean(queryParams.begin) }
  )

  useEffect(() => {
    if (isUtilityTypeSelected) {
      refetchUtilityData()
    }
  }, [queryParams])

  useEffect(() => {
    dispatch(fetchRentalObjectTypes())

    // drop data when out
    return () => {
      dispatch(action(Actions.SET_RENT_DATA, null))
      dispatch(action(Actions.SET_RENTAL_OBJECT_TYPES, []))
    }
  }, [dispatch])

  return (
    <div>
      <RentHeader
        hasBillingData={Boolean(rent) || Boolean(utilityData)}
        objectTypes={rentalObjectTypes}
        isUtilityTypeSelected={isUtilityTypeSelected}
        onChangeDataType={setIsUtilityTypeSelected}
      />
      <Table
        sticky
        bordered
        pagination={false}
        className={styles.table}
        loading={isUtilityTypeSelected ? isUtilityDataLoading : isLoading}
        columns={getColumns(isUtilityTypeSelected) as any}
        dataSource={isUtilityTypeSelected ? utilityData : getRows(rent || [])}
        scroll={{y: getTableYScroll(172)}}
        size={'small'}
      />
    </div>
  );
};