import React, { useEffect, useMemo, useState } from 'react'
import type { RadioChangeEvent } from 'antd'
import { Form, Input, Modal, Select, DatePicker, Radio } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import isObject from 'lodash/isObject'
import { useHistory } from 'react-router'

import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'
import { State } from 'stores/rootReducer'
import { getDoers, getDoerShifts } from 'AC/doers/getDoers'
import { DebounceSelect, fetchUserList, SelectValue } from 'components/DebounceSelectDoer'
import { action, Actions } from 'actions'
import { dateTimeFormatWithTimezone } from 'utils/dateUtils'
import { addDoerCompensations } from 'AC/doers/penalties'
import { cityOptions } from 'utils/enumUtils'
import { ROUTES } from '../../../../config/routes'
import { getEmployerOptions } from '../ShiftsPage/components/AddShiftModal/CreateForm/utils'
import { fetchEmployers } from 'AC/shifts/shifts'
import { required } from '../../../../utils/formUtils'

import { useGetRoles } from './hooks'


const { TextArea } = Input

export type Props = {
  isOpen: boolean
  onClose: () => void
}

const formLayout = { labelCol: { span: 4 } }

const selector = (state: State) => ({
  doers: state.doersReducer.doers,
  doerShifts: state.doersReducer.doerShifts,
  employers: state.shiftsReducer.employers,
})

export enum TimeType {
  SELECT = 10,
  INPUT = 20,
}

export const TimeTypeNames: Record<TimeType, string> = {
  [TimeType.SELECT]: 'Выбрать смену',
  [TimeType.INPUT]: 'Указать параметры смены',
}

export const AddCompensationModal = (props: Props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const time =  Form.useWatch('time', form)
  const manualTime = Form.useWatch('manualTime', form)

  const { doers, doerShifts, employers } = useSelector(selector)
  const userValue = useMemo(() => doers?.map(({ id, name, phone_number }) => ({
    label: `${name} (${phone_number})`,
    value: id,
  })) ?? [], [doers])

  const [doer, setDoer] = useState<SelectValue | undefined>()
  const [cityId, setCityId] = useState<string | number>('')
  const [roleId, setRoleId] = useState('')
  const [timeType, setTimeType] = useState(10)
  const [employerId, setEmployerId] = useState('')
  const roles = useGetRoles({
    cityId,
    roleId,
  })

  const shiftOptions = useMemo(() => doerShifts.map(({ id, begin, location, shift_type }) => ({
    label: moment.parseZone(begin).format(dateTimeFormatWithTimezone) + ` ${location.name} ${shift_type.type}`,
    value: id,
  })), [doerShifts])

  const selectedShift = useMemo(() => doerShifts.find((shift) => shift.id === time), [doerShifts, time])

  const onFinish = (values: any) => {
    const { time, description, motivation_name, doer_user, amount } = values
    const result = {
      title: motivation_name,
      value: amount,
      description,
    } as APITYPES.Performers.PostCompensation.Req

    if (time || manualTime) {
      const selectedTime =
        timeType === TimeType.SELECT
          ? doerShifts.find(({ id }) => id === time)?.end
          : moment(manualTime)?.format('YYYY-MM-DD HH:mm:ss Z')

      result['time'] = selectedTime ?? ''
    }
    if (selectedShift) {
      result.doer_shift_id = selectedShift.id
    } else {
      result.employer_id = values.employer_id
    }

    if (isObject(doer_user as SelectValue)) {
      result['doer_user_id'] = doer_user.value
    }

    if (roleId && cityId) {
      result['role_id'] = roleId
      result['city_id'] = +cityId
    }

    history.push(ROUTES.DOERS.MOTIVATIONS.PARAMS.createPath({}))
    dispatch(action(Actions.SET_DOER_MOTIVATIONS_FILTER, {}))

    // @ts-ignore
    dispatch(addDoerCompensations(pickBy(result)))
    onClose()
  }

  const afterClose = () => {
    setDoer(undefined)
    form.resetFields()
  }

  const handleTimeTypeChange = (e: RadioChangeEvent) => {
    setTimeType(e.target.value)
  }

  useEffect(() => {
    dispatch(getDoers())
  }, [cityId, dispatch])

  useEffect(() => {
    if (isObject(doer)) {
      dispatch(action(Actions.CLEAR_DOER_SHIFTS, {}))
      dispatch(getDoerShifts(doer.value, { limit: 10 }))
    }
  }, [dispatch, doer])

  useEffect(() => {
    if (selectedShift) {
      setCityId(selectedShift.shift_type.city_id)
      setRoleId(selectedShift.role.id)
    }
  },[selectedShift])

  useEffect(() => {
    if (!employers.length) {
      dispatch(fetchEmployers())
    }
  }, [])

  return (
    <Modal
      title='Компенсации'
      open={isOpen}
      onCancel={onClose}
      width={840}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        onFinish={onFinish}
        form={form}
        {...formLayout}
      >
        <Form.Item
          label='Исполнитель'
          name='doer_user'
        >
          <DebounceSelect
            value={doer}
            placeholder='Выберите исполнителя'
            fetchOptions={fetchUserList}
            defaultOptions={userValue}
            onChange={
              (newValue) => {
                setDoer(newValue as SelectValue)
              }
            }
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item style={{ 'paddingLeft': '10em' }}>
          <Radio.Group
            onChange={handleTimeTypeChange}
            value={timeType}
          >
            <Radio value={TimeType.SELECT}>{ TimeTypeNames[TimeType.SELECT] }</Radio>
            <Radio value={TimeType.INPUT}>{ TimeTypeNames[TimeType.INPUT] }</Radio>
          </Radio.Group>
        </Form.Item>

        {
          timeType === TimeType.SELECT ? (
            <Form.Item
              name='time'
              label='Смена'
            >
              <Select
                allowClear
                showSearch
                disabled={!doer?.value}
                options={shiftOptions}
              />
            </Form.Item>
          )
            : (
              <>
                <Form.Item
                  label='Смена'
                  name='manualTime'
                  initialValue={moment()}
                >
                  <DatePicker
                    format='LLL'
                    showTime={
                      {
                        showHour: true,
                        showMinute: true,
                      }
                    }
                    style={{ width: '50%' }}
                  />
                </Form.Item>

                <Form.Item
                  name='employer_id'
                  label='Заказчик'
                  rules={required}
                >
                  <Select
                    allowClear
                    showSearch
                    options={getEmployerOptions(employers)}
                    onChange={setEmployerId}
                    filterOption={
                      (input, option) =>
                        (option!.label as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item
                  name='city_id'
                  label='Город'
                >
                  <Select
                    allowClear
                    placeholder='Выберите город'
                    disabled={!doer?.value || !employerId}
                    onChange={(value) => setCityId(value)}
                    options={cityOptions}
                  />
                </Form.Item>

                <Form.Item
                  name='role_id'
                  label='Роль'
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder='Выберите роль'
                    disabled={!cityId || !employerId}
                    filterOption={
                      (input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => setRoleId(value)}
                  >
                    {
                      roles?.map((role) => (
                        <Select.Option
                          key={role.id}
                          value={role.id}
                        >
                          { role.name }
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

              </>
            )
        }

        <Form.Item
          name='motivation_name'
          label='Название'
        >
          <Input placeholder='Введите название' />
        </Form.Item>

        <Form.Item
          name='amount'
          label='Сумма'
          rules={
            [{
              required: true,
              message: 'Поле должно состоять из чисел',
              pattern: /^\d+$/,
            }]
          }
        >
          <Input
            placeholder='Введите сумму (руб.)'
          />
        </Form.Item>

        <Form.Item
          name='description'
          label='Описание'
        >
          <TextArea
            rows={2}
            placeholder='Введите описание'
          />
        </Form.Item>

        <FormFooter
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
