import React, {
  FC,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

import styles from '../MatchingExecutors/styles.module.sass'
import { State } from '../../../../../../../../stores/rootReducer'
import { UnmatchedPlacementsTable } from './components/UnmatchedPlacementsTable'
import { ShiftReportsType } from '../../../../index'


interface Props {
  type: ShiftReportsType
  onSubmit: () => void
}

const selector = (state: State) => ({ report: state.urentReducer.uploadedReport })


export const MatchingPlacements: FC<Props> = ({ onSubmit, type }) => {
  const { report } = useSelector(selector)
  const unmatchedExternalLocations = report?.unmatched_placements || report?.unmatched_external_locations

  useEffect(() => {
    if (unmatchedExternalLocations?.length === 0) {
      onSubmit()
    }
  }, [report])

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <UnmatchedPlacementsTable
          type={type}
          unmatchedPlacements={unmatchedExternalLocations || []}
        />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!unmatchedExternalLocations?.every((item) => item.isMatched)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
