import React from 'react'
import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import 'antd/dist/antd.css'
import ru_RU from 'antd/lib/locale/ru_RU'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

import { ApiClass } from 'network/ApiClass'
import { apiV1 } from 'network/http'
import { rootReducer } from 'stores/rootReducer'
import { App } from 'App'
import { ThunkExtraArgument } from 'actions'

import 'styles/global.sass'
import { loggerService } from './shared/config/sentry'


export const api = new ApiClass(apiV1)
export const queryClient = new QueryClient()

const errorHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.log(error)
  // Sentry.captureException(error)
}

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({ onError: errorHandler })

const composeEnhancers =
  (typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      errorHandlerMiddleware,
      thunk.withExtraArgument<ThunkExtraArgument>({ api })
    )
  )
)

moment.locale('ru')
momentTimezone.locale('ru')

loggerService.captureMessage('User connected to the app')
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ConfigProvider locale={ru_RU}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </ConfigProvider>
)