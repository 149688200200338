// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--gYo-T{width:100%;cursor:pointer}.styles-module--addButton--a3k59{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Premises/Premise/UtilityAccruals/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,UAAA,CACA,cAAA,CAEJ,iCACI,eAAA","sourcesContent":[".table\n    width: 100%\n    cursor: pointer\n\n.addButton\n    margin-top: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--gYo-T",
	"addButton": "styles-module--addButton--a3k59"
};
export default ___CSS_LOADER_EXPORT___;
