import { APITYPES } from 'types/apitypes'

import VacancyPriorityTrans = APITYPES.VacancyPriorityTrans
import DoerVacancy = APITYPES.DoerVacancy
import VacancyStateTrans = APITYPES.VacancyStateTrans


export interface CityOption {
  label: string | number | symbol
  value: number
}

export const getEnumKeys = <T extends object>(enumArg: T): (keyof T)[] => {
  const keys = Object.keys(enumArg)
    .filter((x) => !(parseInt(x) >= 0)) as (keyof T)[]

  return keys
}

export const getCityName = (city_id: number) => {
  return APITYPES.Cities[city_id] ?? ''
}

export const cityOptions = Object.values(APITYPES.Cities)
  .filter((x) => !Number.isNaN(Number(x)))
  .map((cityId) => ({
    label: getCityName(Number(cityId)),
    value: Number(cityId),
  }))

export const getEnumKeysObject = (enumArg: any) => {
  const keys = getEnumKeys(enumArg)
  const obj: any = {}

  keys.forEach((key) => obj[key] = key)
  
  return obj
}

export const getEnumObject = (enumArg: any) => {
  const obj: any = {}

  for (const key in enumArg) {
    obj[key] = enumArg[key]
  }
  
  return obj
}

export const getEntries = (enumArg: any) => Object.entries(enumArg)

export const vacancyPriorities: Record<DoerVacancy['priority'], VacancyPriorityTrans> = {
  1: VacancyPriorityTrans['CRITICAL'],
  2: VacancyPriorityTrans['HIGH'],
  3: VacancyPriorityTrans['MEDIUM'],
  4: VacancyPriorityTrans['LOW'],
}

export const getPrioritiesOptions = () => Object.entries(vacancyPriorities)
  .map(([value, label]) => ({
    value: Number(value),
    label,
  }))

export const getVacancyStates = () => Object.entries(VacancyStateTrans)
  .map(([value, label]) => ({
    value: value as DoerVacancy['state'],
    label,
  }))

export const getTimeOptions = () => {
  const arr = []

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      if (minute === 0 || minute === 30) {
        const hourStr = hour.toString().padStart(2, '0')
        const minuteStr = minute.toString().padStart(2, '0')

        arr.push({
          label: `${ hourStr }:${ minuteStr }`,
          value: `${ hourStr }:${ minuteStr }`,
        })
      }
    }
  }

  return arr
}

export const signerTagSelectOptions = [
  {
    label: 'ООО "Сервис-Про"',
    value: 'servicepro',
  },
  {
    label: 'ООО "ТОПДУЕР"',
    value: 'topdoer',
  },
  {
    label: 'ООО "ТопШеринг"',
    value: 'topsharing',
  },
  {
    label: 'ООО "ТопШеринг КТ"',
    value: 'topsharing_kt',
  },
  {
    label: 'ООО "ТопСкутер"',
    value: 'topscooter',
  },
  {
    label: 'ООО "Энергошкаф"',
    value: 'energoshkaf',
  },
  {
    label: 'ООО "Карфикс Сервис"',
    value: 'carfix_service',
  },
]

export const internalCities = [
  'Москва',
  'Санкт-Петербург',
  'Сочи',
  'Краснодар',
  'Екатеринбург',
  'Калуга',
  'Тула',
  'Нижний Новгород',
  'Тверь',
]

export const internalCitiesSelectOptions = internalCities.map((city) => ({
  label: city,
  value: city,
}))

export const utilityTypes = [
  'Электроэнергия',
  'Холодная вода',
  'Горячая вода',
  'Отопление',
  'Канализация',
  'Прочее',
]

// TODO: KZ: сделать перобразование общей утилитарной функцией
export const utilityTypeSelectOptions = utilityTypes.map((type) => ({
  label: type,
  value: type,
}))

export const billTypesMap: Record<string, string> = {
  carwash: 'Мойка',
  utility: 'Коммунальные услуги',
  space_rent: 'Аренда помещения',
}

export const billTypesSelectOptions = Object.entries(billTypesMap).map(([key, value] ) => ({
  label: value,
  value: key,
}))

export const rentUnitsMap = {
  day: 'Сутки',
  half_month: 'Пол месяца',
  month: 'Месяц',
}

export const rentUnitsSelectOptions = Object.entries(rentUnitsMap).map(([key, value]) => ({
  label: value,
  value: key,
}))