import React, { useEffect, useMemo, useState } from 'react'
import type { RadioChangeEvent } from 'antd'
import { DatePicker, Divider, Form, Input, InputNumber, Modal, Radio, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import isObject from 'lodash/isObject'
import { useHistory } from 'react-router'

import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'
import { State } from 'stores/rootReducer'
import { getDoers, getDoerShifts } from 'AC/doers/getDoers'
import { DebounceSelect, fetchUserList, SelectValue } from 'components/DebounceSelectDoer'
import { action, Actions } from 'actions'
import { dateTimeFormatWithTimezone } from 'utils/dateUtils'
import { cityOptions } from 'utils/enumUtils'
import { ROUTES } from 'config/routes'
import { required, requiredNumber } from 'utils/formUtils'
import { getChargePriorities, OptionsType, PickPenaltyOptions, TimeTypeNames } from './utils/addPenaltyConsts'
import { StatusRequest } from 'stores/requestsReducer'
import { filterEmptyValues } from 'utils/urlUtils'
import { fetchEmployers } from 'AC/shifts/shifts'
import { getEmployerOptions } from '../ShiftsPage/components/AddShiftModal/CreateForm/utils'
import { addDoerPenalties } from 'AC/doers/penalties'

import styles from './styles.module.sass'
import { useGetPenalties, useGetRoles } from './hooks'


const { TextArea } = Input

export type Props = {
  isOpen: boolean
  onClose: () => void
}

const formLayout = { labelCol: { span: 6 } }

const chargePriorityOptions = getChargePriorities()

const selector = (state: State) => ({
  doers: state.doersReducer.doers,
  doerShifts: state.doersReducer.doerShifts,
  penalties: state.motivationsReducer.penalties,
  employers: state.shiftsReducer.employers,
  isSubmitting: state.requestsReducer.addDoerPenalties === StatusRequest.LOADING,
})

export const AddPenaltyModal = (props: Props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const time =  Form.useWatch('time', form)
  const manualTime = Form.useWatch('manualTime', form)

  const { doers, doerShifts, isSubmitting, employers } = useSelector(selector)
  const userValue = useMemo(() => doers?.map(({ id, name, phone_number }) => ({
    label: `${name} (${phone_number})`,
    value: id,
  })) ?? [], [doers])


  const [doer, setDoer] = useState<SelectValue | undefined>()
  const [cityId, setCityId] = useState<string | number>('')
  const [roleId, setRoleId] = useState('')
  const [timeType, setTimeType] = useState(10)
  const [penaltyType, setPenaltyType] = useState(10)

  const roles = useGetRoles({
    cityId,
    roleId,
  })
  const penalties = useGetPenalties({
    cityId,
    roleId,
  })

  const penaltiesOption = useMemo(() => penalties.map(({ id, title }) => ({
    label: title,
    value: id,
  })), [penalties])
  const shiftOptions = useMemo(() => doerShifts.map(({ id, begin, location, shift_type }) => ({
    label: moment.parseZone(begin).format(dateTimeFormatWithTimezone) + ` ${location.name} ${shift_type.type}`,
    value: id,
  })), [doerShifts])

  const selectedShift = useMemo(() => doerShifts.find((shift) => shift.id === time), [doerShifts, time])

  const onFinish = (values: APITYPES.Performers.PostPenalty.Req & {
    doer_user: SelectValue
    manualTime?: Moment
  }) => {
    const {
      time,
      doer_user,
    } = values


    if (time || manualTime) {
      const selectedTime =
        timeType === OptionsType.SELECT
          ? doerShifts.find(({ id }) => id === time)?.begin
          : moment(manualTime)?.format('YYYY-MM-DD HH:mm:ss Z')

      values['time'] = selectedTime ?? ''

      values.manualTime = undefined
    }

    if (selectedShift) {
      values['doer_shift_id'] = selectedShift.id
      values.employer_id = undefined
    }
    if (isObject(doer_user as SelectValue)) {
      values['doer_user_id'] = doer_user.value

      values.doer_user = undefined as any
    }

    if ('value' in values) {
      values.value = values.value.toString()
    }

    values = filterEmptyValues(values) as any

    history.push(ROUTES.DOERS.MOTIVATIONS.PARAMS.createPath({}))
    dispatch(action(Actions.SET_DOER_MOTIVATIONS_FILTER, {}))

    dispatch(addDoerPenalties(values, () => onClose()))
  }

  const afterClose = () => {
    setDoer(undefined)
    form.resetFields()
  }

  const handleTimeTypeChange = (e: RadioChangeEvent) => {
    setTimeType(e.target.value)
  }

  const handlePenaltyOptionsChange = (e: RadioChangeEvent) => {
    setPenaltyType(e.target.value)
  }

  const onPenaltyChange = (penaltyId: string) => {
    const foundPenalty = penalties.find((item) => item.id === penaltyId)

    if (foundPenalty) {
      form.setFieldValue('charge_priority', foundPenalty.charge_priority)
      form.setFieldValue('charge_bill_percent', foundPenalty.charge_bill_percent)
    }
  }

  useEffect(() => {
    dispatch(getDoers())
  }, [cityId, dispatch])

  useEffect(() => {
    if (isObject(doer)) {
      dispatch(action(Actions.CLEAR_DOER_SHIFTS, {}))
      dispatch(getDoerShifts(doer.value, { limit: 10 }))
    }
  }, [dispatch, doer])

  useEffect(() => {
    if (selectedShift) {
      setCityId(selectedShift.shift_type.city_id)
      setRoleId(selectedShift.role.id)
    }
  },[selectedShift])

  useEffect(() => {
    if (!employers.length) {
      dispatch(fetchEmployers())
    }
  }, [])

  return (
    <Modal
      title='Штраф'
      open={isOpen}
      onCancel={onClose}
      width={840}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        onFinish={onFinish}
        form={form}
        {...formLayout}
      >

        <Form.Item
          label='Исполнитель'
          name='doer_user'
          rules={required}
        >
          <DebounceSelect
            value={doer}
            placeholder='Выберите исполнителя'
            fetchOptions={fetchUserList}
            defaultOptions={userValue}
            onChange={
              (newValue) => {
                setDoer(newValue as SelectValue)
              }
            }
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item style={{ 'paddingLeft': '14em' }}>
          <Radio.Group
            onChange={handleTimeTypeChange}
            value={timeType}
          >
            <Radio value={OptionsType.SELECT}>{ TimeTypeNames[OptionsType.SELECT] }</Radio>
            <Radio value={OptionsType.INPUT}>{ TimeTypeNames[OptionsType.INPUT] }</Radio>
          </Radio.Group>
        </Form.Item>

        {
          timeType === OptionsType.SELECT ? (
            <>
              <Form.Item
                name='time'
                label='Смена'
              >
                <Select
                  allowClear
                  showSearch
                  disabled={!doer?.value}
                  options={shiftOptions}
                />
              </Form.Item>
            </>
          )
            : (
              <>
                <Form.Item
                  label='Смена'
                  name='manualTime'
                  initialValue={moment()}
                >
                  <DatePicker
                    format='LLL'
                    showTime={
                      {
                        showHour: true,
                        showMinute: true,
                      }
                    }
                    style={{ width: '50%' }}
                  />
                </Form.Item>

                <Form.Item
                  name='employer_id'
                  label='Заказчик'
                  rules={required}
                >
                  <Select
                    allowClear
                    showSearch
                    options={getEmployerOptions(employers)}
                    filterOption={
                      (input, option) =>
                        (option!.label as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item
                  name='city_id'
                  label='Город'
                >
                  <Select
                    allowClear
                    placeholder='Выберите город'
                    disabled={!doer?.value}
                    onChange={(value) => setCityId(value)}
                    options={cityOptions}
                  />
                </Form.Item>

                <Form.Item
                  name='role_id'
                  label='Роль'
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder='Выберите роль'
                    disabled={!cityId}
                    filterOption={
                      (input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => setRoleId(value)}
                  >
                    {
                      roles?.map((role) => (
                        <Select.Option
                          key={role.id}
                          value={role.id}
                        >
                          { role.name }
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </>
            )
        }

        <Divider className={styles.divider}>Штраф</Divider>

        <Form.Item style={{ 'paddingLeft': '14em' }}>
          <Radio.Group
            onChange={handlePenaltyOptionsChange}
            value={penaltyType}
          >
            <Radio value={OptionsType.SELECT}>{ PickPenaltyOptions[OptionsType.SELECT] }</Radio>
            <Radio value={OptionsType.INPUT}>{ PickPenaltyOptions[OptionsType.INPUT] }</Radio>
          </Radio.Group>
        </Form.Item>

        {
          penaltyType === OptionsType.INPUT ? (
            <Form.Item
              name='title'
              label='Штраф'
              rules={required}
            >
              <Input
                placeholder='Введите название штрафа'
              />
            </Form.Item>
          ) : (
            <Form.Item
              name='penalty_id'
              label='Штраф'
              rules={required}
            >
              <Select
                allowClear
                showSearch
                options={penaltiesOption}
                disabled={!roleId}
                onChange={onPenaltyChange}
              />
            </Form.Item>
          )
        }

        <Form.Item
          required
          label='Количество'
          style={{ marginBottom: 0 }}
        >
          <Row
            justify='space-between'
          >
            <Form.Item
              name='quantity'
              initialValue={1}
              rules={required}
              required
            >
              <InputNumber
                type='number'
                min={1}
                max={99}
                disabled={!roleId}
                style={{ width: 180 }}
              />
            </Form.Item>

            {
              penaltyType === OptionsType.INPUT ? (
                <Form.Item
                  name='value'
                  label='Сумма'
                  required
                  rules={requiredNumber}
                >
                  <InputNumber
                    placeholder='Укажите сумму'
                    type='number'
                    min={1}
                    style={{ width: 180 }}
                  />
                </Form.Item>
              ) : null
            }
          </Row>
        </Form.Item>

        <Form.Item
          name='description'
          label='Описание'
        >
          <TextArea rows={2} />
        </Form.Item>

        <Divider className={styles.divider}>Удержание</Divider>

        <Form.Item
          required
          label='% от счета'
          style={{ marginBottom: 0 }}
        >
          <Row
            justify='space-between'
          >
            <Form.Item
              name='charge_bill_percent'
              rules={required}
              initialValue={100}
            >
              <InputNumber
                placeholder='Укажите процент'
                type='number'
                min={0}
                max={100}
                style={{ width: 180 }}
              />
            </Form.Item>

            <Form.Item
              name='charge_priority'
              label='Приоритет'
              rules={required}
              initialValue={chargePriorityOptions[0].value}
            >
              <Select
                style={{ width: 180 }}
                allowClear
                placeholder='Выберите приоритет'
                options={chargePriorityOptions}
              />
            </Form.Item>
          </Row>
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          isLoading={isSubmitting}
        />
      </Form>
    </Modal>
  )
}
