import { api } from 'index'
import { errorNotification, successNotification } from 'components/modals/OperationNotification'
import { APITYPES } from 'types/apitypes'


export const putReportElement = async (values: string[], report_id: string) => {
  try {
    const resp = await api.put(APITYPES.ReportElement.PUT.URL, { values } , { replaceUrl: { report_id } })

    if (resp) {
      successNotification('Отчет успешно отредактирован')
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    errorNotification('Ошибка')
  }
}